import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NftService } from 'src/app/services/nft.service';
import { MoralisService } from 'src/app/services/moralis.service';
import { WalletService } from 'src/app/services/wallet.service';
import { AuctionService } from 'src/app/services/auctions.service';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { DigiCard } from 'src/app/types/digi-card.types';
import { LoginResponse, User } from '../../models/user';

@Component({
  selector: 'app-auctions',
  templateUrl: './auctions.component.html',
  styleUrls: ['./auctions.component.scss'],
})
export class AuctionsComponent implements OnInit {
  static nftListCached: DigiCard[] = null;
  static cacheUntil: Date = null;
  static lastOffset: number;

  nftList: DigiCard[] = null;
  auctionList;
  showSwitchToMatic = false;
  loading = false;
  currentOffset = 0;
  endReached = false;
  readonly limit = 12;
  nftListFiltered;
  isLoggedIn: boolean;
  isAdmin: boolean;
  currentUser: User;
  typeFilter = 'ALL';
  isVerifiedEmail;
  filterBy = [
    { name: 'All', id: 'ALL' },
    { name: 'Price Ascending', id: 'PRICE UP' },
    { name: 'Price Descending', id: 'PRICE DOWN' },
  ];

  constructor(
    private readonly nft: NftService,
    private readonly wallet: WalletService,
    private readonly cdr: ChangeDetectorRef,
    private readonly moralis: MoralisService,
    private readonly auctions: AuctionService,
    private readonly authenticationService: AuthenticationService
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
  }

  ngOnInit(): void {
    this.loadData();
    this.checkAccount();
  }

  async checkAccount(): Promise<void> {
    const account = await this.wallet.getAccount();
    this.cdr.detectChanges();
  }

  async loadData(): Promise<void> {
    this.auctions.getAuctions().subscribe((res) => {
      console.log(res);
      this.auctionList = res.data
    })
    this.cdr.detectChanges();
  }

  changeFilter(): void {
    this.loading = true;

    setTimeout(async() => {
      if (this.typeFilter === 'ALL') {
        this.nftListFiltered = await this.nft.getLastAuctions(this.limit);
        this.loading = false;
        return;
      }
      if (this.typeFilter === 'PRICE UP') {
        this.nftListFiltered = this.nftList.sort((l, r) => l.price - r.price);
        this.loading = false;  
        return;
      }
      if (this.typeFilter === 'PRICE DOWN') {
        this.nftListFiltered = this.nftList.sort((l, r) => r.price - l.price);      
        this.loading = false;
        return;
      }
    }, 200);
  }

  switchToMatic(): void {
    this.wallet.switchToMatic();
  }

  async loadMore(): Promise<void> {
    this.loading = true;
    this.currentOffset = this.currentOffset + this.limit;
    const newNfts = await this.nft.getLastAuctions(
      this.limit,
      this.currentOffset
    );
  
    if (newNfts.length === 0 || newNfts.length < this.limit) {
      this.endReached = true;
    }
    this.nftList = [...this.nftList, ...newNfts];
    this.nftListFiltered = this.nftList;
    // this.setCache();
    this.loading = false;
  }

  private setCache(): void {
    AuctionsComponent.nftListCached = this.nftList;
    AuctionsComponent.lastOffset = this.currentOffset;
    const date = new Date();
    date.setMinutes(date.getMinutes() + 30);
    AuctionsComponent.cacheUntil = date;
  }
}

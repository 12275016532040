import { Component } from '@angular/core';
import { OffchainService } from '../../services/offchain.service';
import { FormService } from '../../services/form.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/Authentication.service';

@Component({
  selector: 'app-become-seller',
  templateUrl: './become-seller.component.html',
  styleUrls: ['./become-seller.component.scss'],
})
export class becomeASellerComponent {
  creator;
  formData;
  message;
  isVerifiedEmail: boolean = false;
  currentUser: any;
  seller = { username: '' };
  isLoggedIn;
  isAdmin;

  constructor(
    private readonly offchain: OffchainService,
    private authenticationService: AuthenticationService,
    private readonly formService: FormService,
    private readonly router: Router
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
  }

  ngOnInit(): void {
    this.currentUser = this.currentUser?.data;
    this.isVerifiedEmail = this.currentUser?.verified;
    this.creator = [];
  }

  async loadData(): Promise<void> {}

  async SubmitForm() {
    const creator = Object.assign({}, this.creator);
    this.message = `
      <p>You have received a request from ${creator.username} to become a verified seller. Their email is ${creator.email}</p>
      <ul>
        <li>Username: ${creator.username}</li>
        <li>Email: ${creator.email}</li>
        <li>Twitter: ${creator.twitter}</li>
        <li>Instagram: ${creator.instagram}</li>
        <li>Twitch: ${creator.twitch}</li>
        <li>TikTok: ${creator.tiktok}</li>
        <li>Description: ${creator.description}</li>
        <li>I have a physical secure location - You will have custodial responsibilities to store items in secure, confidential, environmentally controlled location(s) for physical collectibles.: ${creator.digisafe_1}</li>
        <li>I have/ can get insurance - Digisafe inventory must be insured. You will be asked later to provide copy of binder and all riders: ${creator.digisafe_2}</li>
        <li>I have researched all the required certificates and licenses required by law if any for the jurisdictions I plan to operate in as a collectibles storage/processing facility: ${creator.digisafe_3}</li>
        <li>I guarantee I will be able to process shipping/processing requests within 48 hours from the time of request: ${creator.digisafe_4}</li>
        <li>I guarantee that I will visually authenticate all items to be processed by my facility + verify authentication by scanning barcode. I will do my utmost best to help ensure that fraudulent / fake items do not get added to the platform: ${creator.digisafe_5}</li>
        <li>I understand checking these boxes does not mean I will be guaranteed an opportunity to participate as a Digisafe Operator: ${creator.digisafe_6}</li>
        <li>I will provide my location for my proposed Digisafe location (city, state/region, country) in the other text box below: ${creator.digisafe_7}</li>
      </ul>
      <p>Other: ${creator.other}</p>
      <p>Apply to become a Digisafe Operator: ${creator.operator}</p>`;

    await this.formService.postForm(this.message).then((res) => {
      this.router.navigate(['/']);
    });
  }
}

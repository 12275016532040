<div class="container page-top">
  <div class="row">
    <div class="col-md-8 col-lg-9 p-t-10 p-b-6 mobile-hidden">
      <div class="section-title">
        <span class="icon binoculars"></span>
        <span>Verify Email</span>
      </div>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>
  <div>
    <div class="row center p-t-10">
      <div class="col-lg-12">
        <h1>Verify your email by clicking below</h1>
        <button *ngIf="loading" class="btn btn-primary mt-4 mb-4">. . .</button>
        <button
          *ngIf="!loading"
          class="btn btn-primary mt-4 mb-4"
          (click)="verifyEmail()"
        >
          Verify Email
        </button>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CartService } from 'src/app/services/cart.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuctionService {
  constructor(private cartService: CartService, private http: HttpClient) {}

  getAuctions() {
    return this.http
      .get<any>(`${environment.systemLabsAPi}/v1/auctions`)
      .pipe(
        map((auctions: any) => {
          console.log(auctions);
          return auctions;
        })
      );
  }
  
  getAuctionById(id: string) {
    return this.http
      .get<any>(`${environment.systemLabsAPi}/v1/auctions/${id}`)
      .pipe(
        map((auctions: any) => {
          console.log(auctions);
          return auctions;
        })
      );
  }

}

<div *ngIf="user" class="c-profile">
  <div class="c-profile__hero">
    <div class="c-profile__hero-overlay"></div>
    <span
      *ngIf="user && user.logo_path"
      class="c-profile__avatar"
      [ngStyle]="{
        'background-image': 'url(' + collectionData.logo_path + ')'
      }"
    ></span>
    <span *ngIf="!user || !user.logo_path" class="c-profile__avatar"></span>
    <div *ngIf="isYourProfile" class="c-profile__edit">
      <button
        id="editDescriptionModal"
        data-toggle="modal"
        data-target="#addDescriptionModal"
        class="btn btn-primary mb-4"
        #editDescriptionModal
      >
        <span>Edit</span>
      </button>
    </div>
  </div>
  <div class="container p-t-40">
    <div class="row p-t-40">
      <div class="col-lg-6 m-auto center">
        <div class="c-profile__title">
          <div
            *ngIf="user && user.data.approved"
            class="c-profile__creator icon-creator"
          ></div>
          <h1 *ngIf="user && user.data.name">{{ user.data.name }}</h1>
        </div>
        <h1 *ngIf="user && user.data.name">{{ user.data.username }}</h1>
      </div>
    </div>
    <div class="row p-t-40">
      <div class="col-12">
        <div class="row" id="list-result" *ngIf="!loading">
          <div
            *ngFor="let collection of user.data.collections.data"
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
          >
            <a href="collection/{{ collection.id }}" class="c-collection">
              <div class="c-collection__image">
                <img
                  *ngIf="collection.imageUrl"
                  width="100%"
                  height="100%"
                  [src]="collection.imageUrl"
                  alt=""
                />
                <img
                  *ngIf="!collection.imageUrl"
                  width="100%"
                  height="100%"
                  src="/assets/images/home/home-1.png"
                  alt=""
                />
              </div>
              <div
                class="c-collection__circle"
                [ngStyle]="{
                  'background-image':
                    collection && collection.logo
                      ? 'url(' + collection.logo + ')'
                      : ''
                }"
              >
                <span
                  *ngIf="collection && !collection.logo_path"
                  class="icon collections"
                ></span>
              </div>
              <div class="c-collection__body">
                <h2 class="c-collection__title mb-2">
                  {{ collection.name }}
                </h2>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

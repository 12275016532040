import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CartService } from 'src/app/services/cart.service';
import { LoginResponse, User } from '../models/user';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<LoginResponse>;
  public currentUser: Observable<LoginResponse>;
  userLoggedIn: boolean = false;

  constructor(private cartService: CartService, private http: HttpClient) {}

  public get currentUserValue(): User {
    if(this.currentUserSubject) {
      return this.currentUserSubject.value?.data;
    }
  }
  
  public get isUserLoggedIn() {
    return this.userLoggedIn;
  }

  login(email, password) {
    return this.http.post<any>(`${environment.systemLabsAPi}/auth/login`, {
      email,
      password,
    });
  }

  getUser() {
    return this.http
      .get<any>(`${environment.systemLabsAPi}/v1/user/details`)
      .pipe(
        map((user: any) => {
          this.currentUserSubject = new BehaviorSubject<LoginResponse>(user);
          this.currentUser = this.currentUserSubject.asObservable();
          return user;
        })
      );
  }

  isLoggedIn() {
    return this.http.get<any>(
      `${environment.systemLabsAPi}/auth/user/confirmed-password-status`
    ).pipe(
      map((user: any) => {
        this.userLoggedIn = true;
      }),
      catchError(async (err) => this.catchAuthError(err)),
    );;
  }

  catchAuthError(error) {
    console.log('::::', error);
    
    if(error === 'Not Authenticated') {
      this.userLoggedIn = false;
    }
  }

  getCsrf() {
    return this.http.get<any>(
      `${environment.systemLabsAPi}/sanctum/csrf-cookie`
    );
  }

  resendVerifyEmail() {
    return this.http.post<any>(
      `${environment.systemLabsAPi}/auth/email/verification-notification`,
      {}
    );
  }

  verifyEmail(url) {
    return this.http.get<any>(`${environment.systemLabsAPi}{url}`);
  }

  logout() {
    return this.http.post<any>(`${environment.systemLabsAPi}/auth/logout`, {});
  }

  forgotPassword(email) {
    return this.http.post<any>(
      `${environment.systemLabsAPi}/auth/forgot-password`,
      { email }
    );
  }

  changePassword(data): Observable<any> {
    return this.http.post<any>(
      `${environment.systemLabsAPi}/auth/reset-password`,
      data
    );
  }
}

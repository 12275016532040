<div class="container p-b-40 collections-page">
  <div class="row my-4 justify-center">
    <div class="col-12">
      <h1 class="font-weight-bold text-center mt-4 p-b-40 text-darkblue">
        Discover Collections
      </h1>
    </div>
    <div class="col-lg-12 text-center">
      <div class="filter-box" [formGroup]="filterForm">
        <div class="c-search mb-4">
          <input
            id="collectionFilter"
            for="collectionFilter"
            (keypress)="collectionFilter($event)"
            class="c-search-bar"
            type="search"
            name="onchainCollectionFilter"
            placeholder="Filter"
            aria-label="Search through collections."
          />
          <div class="c-search-button">
            <span class="icon search c-search__svg"></span>
          </div>
          <button (click)="collectionFilter($event)" class="c-search-submit">
            Go
          </button>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="search"
            formControlName="search_text"
          />
        </div>
        <div class="form-group">
          <select class="form-control" formControlName="search_category">
            <option value="">Select Category</option>
            <option value="{{ item.slug }}" *ngFor="let item of categoryList">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <button class="btn btn-primary" (click)="filter()">Filter</button>
        </div>
        <div class="form-group">
          <button class="btn btn-primary" (click)="filterReset()">
            Reset Filter
          </button>
        </div>
      </div>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>
  <div class="row" *ngIf="!searchReady">
    <div class="col">
      <div class="mx-auto text-center mt-4 mb-4 text-white">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="collections && collections.length === 0">
    <div class="col">
      <div class="mx-auto text-center mt-4 mb-4 py-5">
        <b>No data available</b>
      </div>
    </div>
  </div>
  <div class="row justify-center" id="list-result" *ngIf="searchReady">
    <div
      *ngFor="let collection of collections; let i = index"
      class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
    >
      <a
        [routerLink]="['/collection', collection?.id]"
        [queryParams]="{
          is20Collection: collection?.is20Data
        }"
        class="c-collection"
      >
        <div class="c-collection__image">
          <img
            *ngIf="collection.featured_path"
            width="100%"
            height="100%"
            [src]="collection.featured_path"
            alt=""
          />
          <img
            *ngIf="!collection.imageUrl"
            width="100%"
            height="100%"
            src="/assets/images/home/home-1.png"
            alt=""
          />
        </div>
        <div
          class="c-collection__circle"
          [ngStyle]="{
            'background-image':
            collection && collection.picture ? 'url(' + collection.picture + ')' : ''
          }"
        >
          <span *ngIf="collection && !collection.image" class="icon collections"></span>
        </div>
        <div class="c-collection__body">
          <h2 class="c-collection__title mb-2">
            {{ this.truncate(collection.name, 30, "...") }}
          </h2>
          <h5 class="c-collection__subtitle mb-2">by {{ collection.name }}</h5>
          <h5 class="c-collection__subtitle mb-2 collection-desc">
            {{ collection.description }}
          </h5>
          <!-- <h2 class="c-collection__title mb-2" *ngIf="dos?.is20Data">{{ dos.border}}</h2> -->
        </div>
      </a>
    </div>
  </div>
 <!--  <div class="row center p-t-10">
    <div class="col-lg-12">
      <button *ngIf="isLoading" class="btn btn-primary mt-4 mb-4">. . .</button>
      <button
        *ngIf="!isLoading && collections.length < total"
        class="btn btn-primary mt-4 mb-4"
        (click)="loadMore()"
      >
        Load more
      </button>
    </div>
  </div> -->
</div>

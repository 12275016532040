import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CountdownFormatFn } from 'ngx-countdown';

@Component({
  selector: 'app-auction-card',
  templateUrl: './auction-card.component.html',
  styleUrls: ['./auction-card.component.scss'],
})
export class AuctionCardComponent implements OnInit {
  @Input() data: any = {};
  @Input() auction: boolean;
  @Input() view: string;
  @Input() config: any = {};

  auctionOrSaleData;
  customBorder: string;
  owner: string;
  address: string;
  ownerUsername: string;
  ownerProfile: object;
  ownerImage: any;
  auctionOwner: string;
  image = '/assets/images/cards/loading.png';

  CountdownTimeUnits: Array<[string, number]> = [
    ['Y', 1000 * 60 * 60 * 24 * 365], // years
    ['M', 1000 * 60 * 60 * 24 * 30], // months
    ['D', 1000 * 60 * 60 * 24], // days
    ['H', 1000 * 60 * 60], // hours
    ['m', 1000 * 60], // minutes
    ['s', 1000], // seconds
    ['S', 1], // million seconds
  ];
  formatDate?: CountdownFormatFn = ({ date, formatStr, timezone }) => {
    let duration = Number(date || 0);

    return this.CountdownTimeUnits.reduce((current, [name, unit]) => {
      if (current.indexOf(name) !== -1) {
        const v = Math.floor(duration / unit);
        duration -= v * unit;
        return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
          return v.toString().padStart(match.length, '0');
        });
      }
      return current;
    }, formatStr);
  };
  isBackVideo = false;
  isVideo = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<any> {
    console.log(this.data);
    this.config = {
      stopTime: new Date(this.data.ends ? this.data.ends : this.data.auction.data).getTime(),
      format: 'DDd HHh mm:ss',
      formatDate: this.formatDate,
    };
    this.cdr.detectChanges();
  }
}

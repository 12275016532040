<div class="container page-top">
  <div class="row justify-center">
    <div class="col-lg-9">
      <h1 class="bold">{{ currentUser.name }}</h1>
      <h1>{{ currentUser.email }}</h1>
    </div>
    <div class="col-9 p-t-10 p-b-6">
      <h2 class="bold text-blue mb-4">Seller Collector form</h2>
      <form [formGroup]="verifyForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="firstName">Surname *</label>
          <input type="text" formControlName="surname" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.surname.errors }" />
          <div *ngIf="submitted && f.surname.errors" class="invalid-feedback">
            <div *ngIf="f.surname.errors.required">Surname is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="categories">Seller Type</label>
          <select class="form-control" formControlName="type"
          [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
            <option value="{{seller.type}}" *ngFor="let seller of sellerType">{{seller.type}}</option>
          </select>
          <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
            <div *ngIf="f.type.errors.required">Seller Type is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="wallet_address">Wallet Address *</label>
          <input type="text" formControlName="wallet_address" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.wallet_address.errors }" />
          <div *ngIf="submitted && f.wallet_address.errors" class="invalid-feedback">
            <div *ngIf="f.wallet_address.errors.required">Wallet Address is required</div>
          </div>
          <div class="error" *ngIf="walletVerificationError">Wallet Address is invalid</div>
        </div>
        <div class="form-group">
          <label for="address1">Address One *</label>
          <input type="text" formControlName="address" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
          <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
            <div *ngIf="f.address.errors.required">Address one is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="address2">Address Two *</label>
          <input type="text" formControlName="address2" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" />
          <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
            <div *ngIf="f.address2.errors.required">Address two is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="country">Country *</label>
          <input type="text" formControlName="country" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.country.errors }" />
          <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
            <div *ngIf="f.country.errors.required">Country is required *</div>
          </div>
        </div>
        <div class="form-group">
          <label for="state">State *</label>
          <input type="text" formControlName="state" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.state.errors }" />
          <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
            <div *ngIf="f.state.errors.required">State is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="city">City *</label>
          <input type="text" formControlName="city" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
          <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
            <div *ngIf="f.city.errors.required">City is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="postalcode">Postalcode *</label>
          <input type="text" formControlName="postalcode" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" />
          <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
            <div *ngIf="f.postalcode.errors.required">Postalcode is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="phone_no">Phone Number *</label>
          <input type="text" formControlName="phone_no" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }" />
          <div *ngIf="submitted && f.phone_no.errors" class="invalid-feedback">
            <div *ngIf="f.phone_no.errors.required">Phone Number is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="twitter_link">Twitter Link *</label>
          <input type="text" formControlName="twitter_link" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.twitter_link.errors }" />
          <div *ngIf="submitted && f.twitter_link.errors" class="invalid-feedback">
            <div *ngIf="f.twitter_link.errors.required">Twitter Link is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="insta_link">Instagram Link *</label>
          <input type="text" formControlName="insta_link" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.insta_link.errors }" />
          <div *ngIf="submitted && f.insta_link.errors" class="invalid-feedback">
            <div *ngIf="f.insta_link.errors.required">Instagram Link is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="twitch_link">Twitch Link *</label>
          <input type="text" formControlName="twitch_link" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.insta_link.errors }" />
          <div *ngIf="submitted && f.twitch_link.errors" class="invalid-feedback">
            <div *ngIf="f.twitch_link.errors.required">Twitch Link is required</div>
          </div>
        </div>

        <ng-container *ngIf="verifyUserRequestStatus">

          <div class="form-group">
            <label for="id_image">Id Image *</label>
            <input (change)="handleFileInput($event.target.files, 'id_image')" type="file" formControlName="id_image"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.id_image.errors }" />
            <div *ngIf="submitted && f.id_image.errors" class="invalid-feedback">
              <div *ngIf="f.id_image.errors.required">Id Image is required</div>
            </div>
          </div>

          <div class="form-group">
            <label for="address_image">Address Image *</label>
            <input (change)="handleFileInput($event.target.files, 'address_image')" type="file"
              formControlName="address_image" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.address_image.errors }" />
            <div *ngIf="submitted && f.address_image.errors" class="invalid-feedback">
              <div *ngIf="f.address_image.errors.required">Address Image is required</div>
            </div>
          </div>

          <div class="form-group">
            <label for="insurance_image">Insurance Image *</label>
            <input (change)="handleFileInput($event.target.files, 'insurance_image')" type="file"
              formControlName="insurance_image" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.insurance_image.errors }" />
            <div *ngIf="submitted && f.insurance_image.errors" class="invalid-feedback">
              <div *ngIf="f.insurance_image.errors.required">Insurance Image is required</div>
            </div>
          </div>

          <div class="form-group">
            <label for="code_image">Code Image *</label>
            <input (change)="handleFileInput($event.target.files, 'code_image')" type="file"
              formControlName="code_image" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.code_image.errors }" />
            <div *ngIf="submitted && f.code_image.errors" class="invalid-feedback">
              <div *ngIf="f.code_image.errors.required">Code Image is required</div>
            </div>
          </div>

        </ng-container>

        <div *ngIf="currentUser?.role[1] !== 'seller' && currentUser?.role[0] === 'user'" class="form-group">
          <button [disabled]="loading ||  !isEmailVerified" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Apply
          </button>
        </div>
        <div *ngIf="currentUser?.role[0] === 'seller' || currentUser?.role[1] === 'seller'" class="form-group">
          <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

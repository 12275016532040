import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute
} from '@angular/router';

import { AuthenticationService } from '../services/Authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLoggedIn: any = this.authenticationService.isLoggedIn();
    if (isLoggedIn) {
      // authorised so return true route.data.role !== "ADMIN" &&
      return true;
    }

   /*  if(route.data.role === "ADMIN" && currentUser.data.role[0] === "admin") {
      return true;
    } */

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}

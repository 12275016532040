<div class="container">
  <div class="row" *ngIf="loading">
    <div class="col-lg-12">
      <div class="mx-auto text-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row justify-center p-b-40 mt-4" *ngIf="!loading">
    <h1 class="bold text-blue">Create a Collection</h1>
    <hr />
    <form
      class="row justify-center"
      [formGroup]="collectionForm"
      (ngSubmit)="onCollectionSubmit()"
    >
      <div class="form-group col-lg-6">
        <label for="name">Name *</label>
        <input
          type="text"
          formControlName="name"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
        />
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
          <div *ngIf="f.name.errors.required">Name is required</div>
        </div>
      </div>

      <div class="form-group col-lg-6">
        <label for="private">Private *</label>
        <div class="d-flex">
          <p class="flex-1">Is your collection private?</p>
          <input
            type="checkbox"
            formControlName="private"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.private.errors }"
          />
        </div>
        <div *ngIf="submitted && f.private.errors" class="invalid-feedback">
          <div *ngIf="f.private.errors.required">private is required</div>
        </div>
      </div>

      <div class="form-group col-lg-6">
        <label for="name">Logo *</label>
        <input
          (change)="handleFileInput($event.target.files, 'logo_image')"
          accept=".png, .jpg, .jpeg"
          type="file"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.logo_image.errors }"
        />
        <div *ngIf="submitted && f.logo_image.errors" class="invalid-feedback">
          Logo is required
        </div>
      </div>

      <div class="form-group col-lg-6">
        <label for="banner_image">Banner Image</label>
        <input
          (change)="handleFileInput($event.target.files, 'banner_image')"
          accept=".png, .jpg, .jpeg"
          type="file"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.banner_image.errors }"
        />
      </div>

      <div class="form-group col-lg-6">
        <label for="description">Description *</label>
        <textarea
          class="form-control"
          maxlength="600"
          formControlName="description"
          [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
        ></textarea>
        <div class="mt-1">
          <label class="float-right"
            >Characters Remaining: {{ f.description.value.length }}/600</label
          >
        </div>
        <div
          *ngIf="
            (submitted && f.description.errors) ||
            (f.description.touched && f.description.errors)
          "
          class="invalid-feedback"
        >
          Description is required
        </div>
      </div>

        <!-- <div class="form-group col-lg-6">
          <label for="categories">Categories</label>
          <select
            class="form-control"
            formControlName="categories"
            [ngClass]="{ 'is-invalid': submitted && f.categories.errors }"
          >
            <option value="">Select Catogory</option>
            <option value="{{ item.slug }}" *ngFor="let item of categoryList">
              {{ item.name }}
            </option>
          </select>
        </div>
 -->
      <div class="form-group col-lg-6">
        <label for="external_url">External url</label>
        <input
          type="text"
          formControlName="external_url"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.external_url.errors }"
        />
      </div>

      <div class="form-group col-lg-6">
        <label for="website">Website</label>
        <input
          type="text"
          formControlName="website"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.website.errors }"
        />
      </div>

      <div class="form-group col-lg-6">
        <label for="discord">Discord</label>
        <input
          type="text"
          formControlName="discord"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.discord.errors }"
        />
      </div>

      <div class="form-group col-lg-6">
        <label for="twitter">Twitter</label>
        <input
          type="text"
          formControlName="twitter"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.twitter.errors }"
        />
      </div>

      <div class="form-group col-lg-6">
        <label for="instagram">Instagram</label>
        <input
          type="text"
          formControlName="instagram"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.instagram.errors }"
        />
      </div>

      <div class="form-group col-lg-6">
        <label for="medium">Medium</label>
        <input
          type="text"
          formControlName="medium"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.medium.errors }"
        />
      </div>

      <div class="form-group col-lg-6">
        <label for="telegram">Telegram</label>
        <input
          type="text"
          formControlName="telegram"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.telegram.errors }"
        />
      </div>

      <div class="form-group col-lg-6">
        <button [disabled]="loading" class="btn btn-primary w-100">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Create Collection
        </button>
      </div>
    </form>
  </div>
</div>

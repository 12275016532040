import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from './Authentication.service';
import { AlertService } from './alert.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  currentUser;
  collectionUser: string;
  isLoggedIn;
  isVerifiedEmail;
  isAdmin;
  
  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    
  }
  verifyUser(role: string | number) {
    if (typeof role === 'string') {
      if (this.currentUser?.role[1]) {
        this.collectionUser = this.currentUser?.role[1];
      } else {
        this.collectionUser = this.currentUser?.role[0];
      }
      if (role !== this.collectionUser) {
        this.router.navigate(['']);
        this.alertService.error(
          'You are not authorized to create a collection'
        );
        return;
      }
    } else {
     /*  if (role !== this.currentUser?.id) {
        this.router.navigate(['']);
        this.alertService.error('You are not authorized to view this page');
        return;
      } */
    }
  }

  getAll() {
    return this.http.get<User[]>(`${environment.systemLabsAPi}/users`);
  }

  getUserById(id: number) {
    return this.http.get<User[]>(`${environment.systemLabsAPi}/v1/users/${id}`);
  }

  register(user: User) {
    return this.http.post(`${environment.systemLabsAPi}/auth/register`, user);
  }

  delete(id: number) {
    return this.http.delete(`${environment.systemLabsAPi}/users/${id}`);
  }

  verifySeller(data: any) {
    return this.http.post(
      `${environment.systemLabsAPi}/users/seller-verify-request`,
      data
    );
  }

  updateVerifySeller(data: any, sellerProfile: number) {
    return this.http.put(
      `${environment.systemLabsAPi}/seller-reverify-request/` + sellerProfile,
      data
    );
  }

  getVerifySeller(): Observable<any> {
    return this.http.get(
      `${environment.systemLabsAPi}/get-seller-verify-request`
    );
  }

  getAllAdminUsers(currentPage = 1, userName?: string): Observable<any> {
    const params = new HttpParams()
      .set('page', `${currentPage}`)
      .append('search', userName);
    // .set('limit', `${itemsPerPage}`);
    return this.http.get<User[]>(`${environment.systemLabsAPi}/admin/users`, {
      params,
    });
  }
}

<header class="c-header">
  <div class="c-header__inner">
    <div class="c-header__logo-link d-flex">
      <a [routerLink]="['/']" class="d-flex">
        <span class="icon logo c-header__logo"></span>
      </a>
    </div>
    <!--  <a
      *ngIf="
        currentUser?.role[0] === 'user' &&
        ((currentUser?.role[0] === 'user' &&
          currentUser?.role[1] !== 'seller') ||
          (currentUser?.role[0] === 'user' &&
            currentUser?.role[1] === undefined))
      "
      class="mr-5 text-primary switch-user"
      (click)="onClick()"
    >
      <b>
        Switch to seller {{ currentUser?.role[0]
        }}<i class="fa fa-arrow-right ml-1 pt-1" aria-hidden="true"></i></b
    ></a> -->
    <div class="c-header__search">
      <input
        id="searchInp"
        for="searchInp"
        (keypress)="findResult($event)"
        class="c-header__search-bar"
        type="search"
        name="q"
        placeholder="Search by name or description..."
        aria-label="Search through site content"
      />
      <div class="c-header__search-button">
        <span class="icon search c-header__svg-search"></span>
      </div>
      <button (click)="findResult($event)" class="c-header__search-submit">
        Go
      </button>
    </div>
    <div class="c-header__icons">
      <a
        [routerLink]="['/explore']"
        class="c-header__link mr-4 bold mobile-hidden"
      >
        <span>Explore</span>
      </a>
      <a
        [routerLink]="['/auctions']"
        class="c-header__link mr-4 bold mobile-hidden"
      >
        <span>Auctions</span>
      </a>
      <a
        [routerLink]="['/for-sale']"
        class="c-header__link mr-4 bold mobile-hidden"
      >
        <span>For Sale</span>
      </a>
      <a
        [routerLink]="['/collections']"
        class="c-header__link mr-4 bold mobile-hidden"
      >
        <span>Collections</span>
      </a>
      <a
        [routerLink]="['/stake']"
        class="c-header__link mr-4 bold mobile-hidden"
      >
        <span>Staking</span>
      </a>
      <a
        *ngIf="address"
        [routerLink]="['/create']"
        class="mr-4 bold mobile-hidden"
      >
        <span>Create NFT</span>
      </a>
      <div class="theme-switch-wrapper mr-2 mobile-hidden">
        <label class="theme-switch" for="checkbox">
          <input type="checkbox" id="checkbox" />
          <div class="slider round"></div>
        </label>
      </div>
      <div class="cart-icon icon mx-2">
        <a
          [routerLink]="'/cart'"
          class="bold"
        >
          <i class="fa fa-shopping-cart full-width height-full"></i>
        </a>
        <div class="cart-count-noti">{{ cartCount }}</div>
      </div>
      <a
        (click)="toggleUserMenu()"
        class="c-nav__item mx-2 cursor-pointer mobile-hidden"
      >
        <span class="icon user"></span>
      </a>
      <a
        class="mx-2 items-center justify-center cursor-pointer mobile-hidden"
        (click)="toggleWalletMenu()"
      >
        <span class="icon wallets"></span>
      </a>
      <div (click)="mobileMenuToggle()" class="c-hamburger">
        <div class="c-hamburger__inner">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="c-burger-menu">
  <nav class="c-burger-menu__nav">
    <a
      [routerLink]="['/explore']"
      (click)="mobileMenuToggle()"
      class="c-nav__item mr-4 bold"
    >
      <span class="c-nav__span">Explore</span>
    </a>
    <a
      [routerLink]="['/auctions']"
      (click)="mobileMenuToggle()"
      class="c-nav__item mr-4 bold"
    >
      <span class="c-nav__span">Auctions</span>
    </a>
    <a
      [routerLink]="['/for-sale']"
      (click)="mobileMenuToggle()"
      class="c-nav__item mr-4 bold"
    >
      <span class="c-nav__span">For Sale</span>
    </a>
    <a
      [routerLink]="['/collections']"
      (click)="mobileMenuToggle()"
      class="c-nav__item mr-4 bold"
    >
      <span class="c-nav__span">Collections</span>
    </a>
    <a
      [routerLink]="['/stake']"
      class="c-nav__item mr-4 bold"
      (click)="mobileMenuToggle()"
    >
      <span>Staking</span>
    </a>
    <a *ngIf="address" [routerLink]="['/create']" class="mr-4 bold">
      <span class="c-nav__span">Create NFT</span>
    </a>
    <div class="theme-switch-wrapper mr-2 c-nav__item">
      <span class="c-nav__span mr-4">Night mode</span>
      <label class="theme-switch" for="checkbox">
        <input type="checkbox" id="checkbox" />
        <div class="slider round"></div>
      </label>
    </div>
    <a
      (click)="toggleUserMenu()"
      class="c-nav__item mx-2 d-flex cursor-pointer"
    >
      <span class="icon user"></span>
    </a>
    <a
      class="c-nav__item"
      (click)="mobileMenuToggle()"
      target="_blank"
      href="https://www.youtube.com/watch?v=yWfZnjkhhhg&ab_channel=EveryBitHelps"
    >
      <span class="c-nav__span">How to setup a wallet</span>
    </a>
    <a class="c-nav__item" href="/assets/pdf/Audit.pdf" target="_blank">
      <span class="c-nav__span">Download Audit</span>
    </a>
    <a
      class="c-nav__item items-center justify-center cursor-pointer"
      (click)="toggleWalletMenu()"
    >
      <span class="c-nav__span"
        >Connect wallet<span class="icon wallets bg-white"></span
      ></span>
    </a>
  </nav>
</div>
<div id="js-user-nav" class="c-user-nav">
  <div *ngIf="isLoggedIn && currentUser" class="c-user-nav__header">
    <p class="section-title">{{ currentUser.name }}</p>
  </div>
  <nav class="c-user__nav">
    <a
      *ngIf="!isLoggedIn"
      class="c-nav__item"
      (click)="toggleUserMenu()"
      [routerLink]="['/login']"
    >
      <span class="c-nav__span">Login</span>
    </a>
    <a
      *ngIf="!isLoggedIn"
      class="c-nav__item"
      (click)="toggleUserMenu()"
      [routerLink]="['/register']"
    >
      <span class="c-nav__span">Register</span>
    </a>
    <a
      class="c-nav__item"
      (click)="toggleUserMenu()"
      [routerLink]="['/profile', address]"
    >
      <span class="c-nav__span">On-chain Profile</span>
    </a>
    <a
      class="c-nav__item"
      (click)="toggleUserMenu()"
      [routerLink]="['/messages']"
      *ngIf="isLoggedIn"
    >
      <span class="c-nav__span">Messages</span>
    </a>
    <a
      *ngIf="isLoggedIn && currentUser?.role && currentUser.admin"
      class="c-nav__item"
      (click)="toggleUserMenu()"
      [routerLink]="['/admin']"
    >
      <span class="c-nav__span">Admin</span>
    </a>
    <a
      *ngIf="isLoggedIn && currentUser?.role && currentUser.admin"
      class="c-nav__item"
      [routerLink]="['/admin/collections']"
    >
      <span class="c-nav__span">Collection History</span>
    </a>
    <span
      *ngIf="!address"
      (click)="connectWallet() && toggleUserMenu()"
      class="c-nav__item mr-4 d-flex cursor-pointer"
    >
      <span class="c-nav__span cursor-pointer">Connect Crypto Wallet</span>
    </span>
    <a
      *ngIf="currentUser && !currentUser.admin"
      class="c-nav__item"
      (click)="toggleUserMenu()"
      [routerLink]="['/my-favourites']"
    >
      <span class="c-nav__span">My Favourites</span>
    </a>
    <a
      *ngIf="currentUser && !currentUser.admin"
      class="c-nav__item"
      (click)="toggleUserMenu()"
      [routerLink]="['/my-auctions']"
    >
      <span class="c-nav__span">My Auctions</span>
    </a>
    <a
      *ngIf="isLoggedIn"
      class="c-nav__item"
      (click)="toggleUserMenu()"
      [routerLink]="['/user']"
    >
      <span class="c-nav__span">My Account</span>
    </a>
    <a
      *ngIf="isLoggedIn"
      class="c-nav__item"
      (click)="toggleUserMenu()"
      [routerLink]="['/my-collections']"
    >
      <span class="c-nav__span">My Collections</span>
    </a>
    <a class="c-nav__item" (click)="toggleUserMenu()" [routerLink]="['/faq']">
      <span class="c-nav__span">FAQ</span>
    </a>
    <a
      *ngIf="!address"
      class="c-nav__item"
      (click)="toggleUserMenu()"
      target="_blank"
      href="https://www.youtube.com/watch?v=yWfZnjkhhhg&ab_channel=EveryBitHelps"
    >
      <span class="c-nav__span">How to setup a wallet</span>
    </a>
    <a
      class="c-nav__item"
      [routerLink]="['/education']"
      (click)="toggleUserMenu()"
    >
      <span class="c-nav__span">Learn more</span>
    </a>
    <a
      class="c-nav__item c-user-nav__header"
      href="/assets/pdf/Audit.pdf"
      target="_blank"
    >
      <span class="c-nav__span">Download Audit</span>
    </a>
    <ng-container *ngIf="currentUser">
      <a
        *ngIf="currentUser && currentUser.admin"
        class="c-nav__item"
        (click)="toggleUserMenu()"
        [routerLink]="['/admin/user-list']"
      >
        <span class="c-nav__span">Users</span>
      </a>
      <a
        *ngIf="currentUser && currentUser.admin"
        class="c-nav__item"
        (click)="toggleUserMenu()"
        [routerLink]="['/admin/system-admin']"
      >
        <span class="c-nav__span">System Admin</span>
      </a>
      <a
        *ngIf="currentUser && currentUser.admin"
        class="c-nav__item"
        (click)="toggleUserMenu()"
        [routerLink]="['/admin/category']"
      >
        <span class="c-nav__span">Categories</span>
      </a>
      <a
        *ngIf="currentUser && !currentUser.admin"
        class="c-nav__item"
        (click)="toggleUserMenu()"
        [routerLink]="[
          '/user-order-details/' +
            this.currentUser?.id
        ]"
      >
        <span class="c-nav__span">Order List</span>
      </a>
      <a
        *ngIf="currentUser && currentUser.verified_seller && currentUser.seller"
        class="c-nav__item"
        (click)="toggleUserMenu()"
        [routerLink]="['/seller-item-history/']"
      >
        <span class="c-nav__span">Sale Item History</span>
      </a>
    </ng-container>
    <span
      *ngIf="currentUser && !currentUser.verified_email"
      (click)="resendVerifyEmail()"
      class="c-nav__item cursor-pointer"
    >
      <span class="c-nav__span">Resend email verify</span>
    </span>
    <span
      *ngIf="isLoggedIn"
      (click)="logout()"
      class="c-nav__item cursor-pointer"
    >
      <span class="c-nav__span">Log out</span>
    </span>
  </nav>
</div>
<div class="c-wallet-nav">
  <p>Connect with one of our available wallet providers or create a new one.</p>
  <div *ngIf="address" class="c-user-nav__header">
    <div class="text-center mb-2">
      <!-- <h1 class="font-weight-bold">Enter using your keys below</h1> -->
      <span class="border-style-item mx-auto">
        {{ address | slice: 0:6 }}...{{ address | slice: 36:64 }}</span
      >
    </div>
  </div>
  <nav class="c-user__nav">
    <a
      class="c-nav__item d-flex items-center"
      (click)="connectWallet()"
      [routerLink]="['/login']"
    >
      <div class="icon">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.3589 17.9821L19.0779 18.2952L18.8128 18.4964L7.51215 16.9768H0.827148L3.21415 11.0686L7.09477 11.0918L8.03452 12.7972L11.1346 16.0153L10.733 14.0115L13.4998 14.5034L18.8128 18.4964L14.6136 14.5034L13.9241 12.596L11.4986 6.68861C11.4986 6.68861 9.6349 3.38879 9.5964 3.35181C9.6489 3.35869 13.9241 3.33633 13.9241 3.33633L14.7046 6.50973L16.4258 12.596L16.8956 14.2429L18.5625 16.1718L17.2063 14.101L18.0926 13.7286L18.517 15.606L18.5555 16.1718L20.3589 17.9821Z"
            fill="#F58C25"
          />
          <path
            d="M7.09477 11.0909L3.21415 11.0686L2.28927 10.0925L2.8729 9.70545L1.82727 9.14645L2.72152 8.90823L1.4939 8.14885L2.2289 7.83581L2.4109 6.70319L4.44965 2.96391L7.20852 0.586014L9.58852 3.35005L11.4986 6.67997L7.09477 11.0901V11.0909Z"
            fill="#81491F"
          />
          <path
            d="M0.827148 16.9768H7.51915L9.34615 19.3753C9.34615 19.3753 4.79877 20.7238 2.51677 21.4092C1.93315 19.8819 1.37927 18.4293 0.827148 16.9768Z"
            fill="#E48027"
          />
          <path
            d="M18.8206 6.85205L19.3587 7.46265L18.965 7.92533L19.5101 8.31233L19.1163 8.82661L19.2073 9.63845L18.5327 10.4279L14.7046 6.50977L20.806 1.5407L18.8206 6.85205Z"
            fill="#81491F"
          />
          <path
            d="M18.8137 18.4964L18.8364 19.979L16.6612 21.0592L9.33915 19.3822C9.33915 19.3822 9.28578 19.42 9.33915 19.3822C12.4087 19.0924 18.8137 18.4964 18.8137 18.4964Z"
            fill="#D9C6B7"
          />
          <path
            d="M20.8059 1.5407L14.7046 6.50978L13.9241 3.33638L20.8059 1.5407Z"
            fill="#E48027"
          />
          <path
            d="M14.7046 6.50963L18.5248 10.4278L18.3656 12.4617L16.4257 12.5958L14.7046 6.50963Z"
            fill="#E57F24"
          />
          <path
            d="M9.33911 19.3743L16.6602 21.0513L18.8355 19.9703L20.3291 19.8439L19.1916 21.3042L16.1072 21.5794L9.33911 19.3743Z"
            fill="#C7B7AB"
          />
          <path
            d="M9.33817 19.3753L7.51904 16.9759L11.1345 16.0153L9.33817 19.3753Z"
            fill="#D76F21"
          />
          <path
            d="M20.3291 19.844C19.8286 19.8887 19.336 19.9334 18.8355 19.9713L18.8127 18.4886L19.0779 18.2874L20.3589 17.9821C20.3589 17.9821 20.7911 18.2796 21.1621 18.4886C20.8672 18.9651 20.5934 19.4045 20.3291 19.844Z"
            fill="#2A2626"
          />
          <path
            d="M16.4248 12.596L18.3656 12.4618L18.1004 13.7286L17.3654 12.8643L17.2062 14.101L18.5624 16.1719L16.8956 14.2351L16.4257 12.596H16.4248Z"
            fill="#D66F21"
          />
          <path
            d="M7.09473 11.0908L11.4986 6.68074L13.9241 12.5958L8.03535 12.7971L7.09473 11.0908Z"
            fill="#E47E25"
          />
          <path
            d="M9.33911 19.3753L11.1355 16.0153L18.8136 18.4964L9.33911 19.3753Z"
            fill="#E47F26"
          />
          <path
            d="M8.0354 12.7972L13.9241 12.596L13.4998 14.5035L12.5075 12.9985L10.7339 14.0116L8.0354 12.7972Z"
            fill="#D66F21"
          />
          <path
            d="M13.4998 14.5035L13.9241 12.596L14.6136 14.5035L18.8136 18.4965C18.8136 18.4965 14.7125 15.4194 13.4998 14.5035Z"
            fill="#E47F25"
          />
          <path
            d="M13.4999 14.5034L10.7332 14.0115L12.5068 12.9984L13.4999 14.5034Z"
            fill="#32404E"
          />
          <path
            d="M10.7339 14.0114L11.1355 16.0152L8.0354 12.7979L10.7339 14.0123V14.0114Z"
            fill="#E57F25"
          />
          <path
            d="M17.2063 14.101L17.3655 12.8643L18.1005 13.7278L17.2063 14.101Z"
            fill="#38424D"
          />
          <path
            d="M18.1006 13.7286L18.3657 12.4618L18.6457 13.8103L18.525 15.606L18.1006 13.7286Z"
            fill="#E57F24"
          />
        </svg>
      </div>
      <span class="c-nav__span">Metamask</span>
    </a>
    <a class="c-nav__item" (click)="toggleUserMenu()" [routerLink]="['/login']">
      <span class="c-nav__span">Coinbase Wallet</span>
    </a>
    <span
      *ngIf="currentUser"
      (click)="walletLogout()"
      class="c-nav__item cursor-pointer"
    >
      <span class="c-nav__span">Log out</span>
    </span>
  </nav>
</div>
<div
  class="error-alert my-0 py-3"
  role="alert"
  id="error-alert"
  *ngIf="showAlertMessage"
>
  <div class="d-flex font-weight-bold text-white mb-0">
    <p class="mr-2 mb-0">
      Please verify your email address before continuing, it may be in your
      spam/junk folder.
    </p>
    <div (click)="closeErrorBanner()">
      <i class="fa fa-times-circle cursor-pointer"></i>
    </div>
  </div>
</div>
<section id="wrapper">
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <alert></alert>
      <router-outlet (activate)="changeOfRoutes()"></router-outlet>
    </div>
  </div>
</section>
<div
  class="modal fade"
  id="searchModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="searchModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="searchModalLabel">Search NFT</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 p-t-15">
              <form class="user">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control form-control-user"
                    placeholder="Search by name..."
                    [(ngModel)]="inputNftName"
                    (ngModelChange)="onChangeInput()"
                    name="nftName"
                  />
                </div>
              </form>
            </div>
          </div>
          <div class="row p-t-15 p-b-10 center justify-center">
            <div class="col-lg-12">
              <button type="button" class="btn btn-primary">Search NFT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="c-footer__logos">
  <div class="row container m-auto justify-center">
    <div class="col-lg-2 col-md-4 col-sm-6 d-flex items-center justify-center">
      <img
        style="max-width: 200px; max-height: 50px"
        src="../../../assets/images/logos/sl-logo.png"
        alt=""
      />
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 d-flex items-center justify-center">
      <img
        style="max-width: 150px; max-height: 50px"
        src="../../../assets/images/logos/inc-logo.png"
        alt=""
      />
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 d-flex items-center justify-center">
      <img
        style="max-height: 50px"
        src="../../../assets/images/logos/psalogo.png"
        alt=""
      />
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 d-flex items-center justify-center">
      <img
        style="max-height: 50px"
        src="../../../assets/images/logos/cgc-logo.png"
        alt=""
      />
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 d-flex items-center justify-center">
      <img
        style="max-height: 50px"
        src="../../../assets/images/logos/logo-aoki.png"
        alt=""
      />
    </div>
  </div>
</div>
<footer class="c-footer">
  <div class="c-footer__body container">
    <div class="row pb-4">
      <div class="col-lg-7">
        <div class="mailing">
          <h1>Stay in the loop</h1>
          <p>
            Join our mailing list to stay in the loop with our newest feature
            releases, NFT drops, and tips and tricks for navigating Digible.
          </p>
          <form #subscribeForm="ngForm" (ngSubmit)="subscribe(subscribeForm)">
            <div *ngIf="email.invalid && (email.dirty || email.touched)">
              <div *ngIf="email.errors.required">Email is required.</div>
            </div>
            <input
              pattern="\S+@\S+\.\S+"
              placeholder="Email"
              required
              #email="ngModel"
              name="userName"
              class="required email c-footer__input"
              [(ngModel)]="subscribeData.email"
            />
            <button
              mat-raised-button
              type="submit"
              class="btn btn-secondary c-footer__submit"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="c-footer__community">
          <h3 class="c-footer__title bold">Join our community</h3>
          <div class="social">
            <a
              class="social-item mr-2"
              href="https://twitter.com/digibleio"
              target="_blank"
              ><i class="icon fab fa-twitter"></i
            ></a>
            <a
              class="social-item mr-2"
              href="https://t.me/digiblechat"
              target="_blank"
              ><i class="icon fab fa-telegram"></i
            ></a>
            <a
              class="social-item mr-2"
              href="https://digibleio.medium.com/"
              target="_blank"
              ><i class="icon fab fa-medium"></i
            ></a>
            <a
              class="social-item mr-2"
              href="https://github.com/digible"
              target="_blank"
              ><i class="icon fab fa-github"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="row separate pb-4">
      <div class="col-lg-7">
        <div class="mt-4">
          <div class="digi">
            <div class="d-flex c-header__logo-link mb-4">
              <a [routerLink]="['/']" class="icon logo c-footer__logo"></a>
            </div>
            <p class="c-footer__strapline">
              The world's first NFT marketplace backed by rare physical
              collectibles.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div id="nav" class="c-footer__menu">
          <nav class="c-footer__menu-nav row">
            <a class="c-nav__item col-6" [routerLink]="['/create']">
              <span class="c-nav__span">Create NFT</span>
            </a>
            <a class="c-nav__item col-6" [routerLink]="['/digi-road-map']">
              <span class="c-nav__span">Road Map</span>
            </a>
            <a
              class="c-nav__item col-6"
              href="https://s78dn46r19t.typeform.com/to/KfmYkiGQ"
              target="popup"
            >
              <span class="c-nav__span"> Become a Verified Collector</span>
            </a>

            <a class="c-nav__item col-6" [routerLink]="['/digi-team']">
              <span class="c-nav__span">Team</span>
            </a>
            <a class="c-nav__item col-6" [routerLink]="['/purchase']">
              <span class="c-nav__span">Buy DIGI</span>
            </a>
            <a class="c-nav__item col-6" [routerLink]="['/digi-safe']">
              <span class="c-nav__span">DIGI Safe</span>
            </a>
            <a class="c-nav__item col-6" [routerLink]="['/digi-grade']">
              <span class="c-nav__span">DIGI Grade</span>
            </a>
            <a class="c-nav__item col-6" [routerLink]="['/digi-farm']">
              <span class="c-nav__span">DIGI Trade</span>
            </a>
            <a class="c-nav__item col-6" [routerLink]="['/digi-track']">
              <span class="c-nav__span">DIGI Track</span>
            </a>
            <a class="c-nav__item col-6" [routerLink]="['/digi-duel']">
              <span class="c-nav__span">DIGI Duel</span>
            </a>
            <a class="c-nav__item col-6" [routerLink]="['/faq']">
              <span class="c-nav__span">FAQ</span>
            </a>

            <a
              class="c-nav__item col-6"
              (click)="mobileMenuToggle()"
              target="_blank"
              href="https://www.youtube.com/watch?v=yWfZnjkhhhg&ab_channel=EveryBitHelps"
            >
              <span class="c-nav__span">Set up a wallet</span>
            </a>
            <a
              class="c-nav__item col-6"
              href="/assets/pdf/Audit.pdf"
              target="_blank"
            >
              <span class="c-nav__span">Download Audit</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
    <div class="row separate d-flex">
      <div class="c-footer__company">
        <a href="https://gebo.io/">© Digible LLC 2021</a>
      </div>
      <div class="c-footer__socials">
        <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
        <!-- <span class="pr-2 pl-2">|</span>
        <a [routerLink]="['/terms-and-conditions']">Terms and Conditions</a> -->
      </div>
      <div class="c-network" *ngIf="!hideNetwork">
        <span>Network: {{ network }}</span>
        <br />
        <span class="testnet" *ngIf="testnet">Testnet: Yes</span>
        <br />
        <span>Date: {{ currentTime }}</span>
      </div>
    </div>
  </div>
</footer>

<!-- <div *ngIf="popUp" class="pop-up" [@fade]="popUp">
  <div class="pop-up-overlay"></div>
  <button
    (click)="popUp = false"
    type="button"
    class="pop-up-close"
    data-dismiss="modal"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <img
    class="mb-4 logo"
    width="200"
    src="../../assets/images/logo-link.png"
    alt=""
  />
  <div class="svg-logo">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 74.9 74.9"
      style="enable-background: new 0 0 74.9 74.9"
      xml:space="preserve"
    >
      <path
        style="fill: #ffffff"
        d="M70.7,5.9c0-0.9-0.8-1.7-1.7-1.7H18.8c-0.4,0-0.7,0.1-1,0.3l0,0l0,0c-0.1,0.1-0.2,0.2-0.3,0.3
	C15.5,6.8,5.3,16.8,5,17.1c-0.3,0.3-0.5,0.7-0.5,1.2v50.2c0,0.9,0.8,1.7,1.7,1.7h50.2c0.4,0,0.8-0.2,1.1-0.4
	c0.3-0.3,11.1-10.9,12.6-12.4c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0l0,0c0.2-0.3,0.4-0.7,0.4-1.1V5.9z"
      />
      <path
        style="fill: #2af6ff"
        d="M44.6,16.3h-0.7H31.4c-0.8,0-1.5,0.7-1.5,1.5v27.7c0,0.8,0.7,1.5,1.5,1.5h12.6h0.7
	c8.1,0,14.7-6.6,14.7-14.7V31C59.3,22.9,52.7,16.3,44.6,16.3z M51.4,32c0,4.4-3.1,8-6.9,8h-0.3h-5.9c-0.4,0-0.7-0.4-0.7-0.8V24.1
	c0-0.5,0.3-0.8,0.7-0.8h5.9h0.3c3.8,0,6.9,3.6,6.9,8V32z"
      />
      <path
        style="fill: #1823f1"
        d="M70.7,5.9c0-0.9-0.8-1.7-1.7-1.7H18.8c-0.4,0-0.7,0.1-1,0.3l0,0l0,0c-0.1,0.1-0.2,0.2-0.3,0.3
	C15.5,6.8,5.3,16.8,5,17.1c-0.3,0.3-0.5,0.7-0.5,1.2v50.2c0,0.9,0.8,1.7,1.7,1.7h50.2c0.4,0,0.8-0.2,1.1-0.4
	c0.3-0.3,11.1-10.9,12.6-12.4c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0l0,0c0.2-0.3,0.4-0.7,0.4-1.1V5.9z M19.8,6.9H68v48.2H19.8V6.9z
	 M17.1,9v46.7l-9.9,9.9V18.7C8.1,17.8,13.6,12.4,17.1,9z M56,67.5H9l9.7-9.7c0,0,0,0,0,0h47C62.3,61.3,56.9,66.6,56,67.5z"
      />
      <path
        style="fill: #1823f1"
        d="M44.6,18.9c6.7,0,12.1,5.4,12.1,12.1v1.2c0,6.7-5.4,12.1-12.1,12.1h-0.7H32.5V18.9h11.4H44.6
	 M44.6,16.3L44.6,16.3h-0.7H31.4c-0.8,0-1.5,0.7-1.5,1.5v27.7c0,0.8,0.7,1.5,1.5,1.5h12.6h0.7h0c8.1,0,14.7-6.6,14.7-14.7V31
	C59.3,22.9,52.7,16.3,44.6,16.3L44.6,16.3z"
      />
      <path
        style="fill: #1823f1"
        d="M44.5,25.9c2.3,0,4.2,2.4,4.2,5.4V32c0,3-1.9,5.4-4.2,5.4h-0.3h-3.9V25.9h3.9H44.5 M44.5,23.3
	L44.5,23.3h-0.3h-5.9c-0.4,0-0.7,0.4-0.7,0.8v15.1c0,0.5,0.3,0.8,0.7,0.8h5.9h0.3h0c3.8,0,6.9-3.6,6.9-8v-0.7
	C51.4,26.9,48.3,23.3,44.5,23.3L44.5,23.3z"
      />
      <path
        style="
          fill: none;
          stroke: #1823f1;
          stroke-width: 2.2943;
          stroke-miterlimit: 10;
        "
        d="M29.8,32.3"
      />
    </svg>
  </div>
  <div class="text">
    <h1 class="bold">Welcome to Digible.io.</h1>
    <h3 class="bold">
      V2 is imminent and we are gearing up for the full release of the Digible
      dApp.
    </h3>
    <p class="text-lg">
      This dApp is currently not yet fully functional. We are in the process of
      making the relevant changes to our smart contract in order to facilitate
      the new functionality within this application. The site functionality has
      been locked until we have finalized this blockchain work.
    </p>
  </div>
</div> -->

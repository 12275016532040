import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { MathService } from 'src/app/services/math.service';
import { NftService } from 'src/app/services/nft.service';
import { OffchainService } from 'src/app/services/offchain.service';
import { VerifiedWalletsService } from 'src/app/services/verified-wallets.service';
import { environment } from 'src/environments/environment';
import { WalletService } from 'src/app/services/wallet.service';
import { CountdownFormatFn } from 'ngx-countdown';
import { HelpersService } from '../../services/helpers.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { MoralisService } from '../../services/moralis.service';
import { IntersectionStatus } from '../../from-intersection-observer';

@Component({
  selector: 'app-digi-card',
  templateUrl: './digi-card.component.html',
  styleUrls: ['./digi-card.component.scss'],
})
export class DigiCardComponent implements OnInit {
  @Input() data: any = {};
  @Input() config: any = {};
  @Input() id: number;
  @Input() router;
  @Input() price: number = null;
  @Input() auction: boolean;
  @Input() view: string;
  @Input() backSide = false;
  @Input() collectionItem: any;
  @Output() imageZoom: EventEmitter<boolean> = new EventEmitter();

  digiAddressMatic = environment.maticCoinContractAddresses.digiAddressMatic;
  usdcMaticAddress = environment.maticCoinContractAddresses.usdcAddressMatic;
  usdtMaticAddress = environment.maticCoinContractAddresses.usdtAddressMatic;
  maticCoinAddress = environment.maticCoinContractAddresses.maticCoinAddress;
  linkMaticAddress = environment.maticCoinContractAddresses.linkAddressMatic;
  auctionOrSaleData;
  customBorder: string;
  owner: string;
  address: string;
  ownerUsername: string;
  ownerProfile: object;
  ownerImage: any;
  auctionOwner: string;
  symbol = environment.stableCoinSymbol;
  endDate;
  physical: boolean;
  image = '/assets/images/cards/loading.png';
  backImage = '/assets/images/cards/loading.png';
  description: {
    publisher: string;
    edition: string;
    year: string;
    graded: string;
    population: string;
    backCardImage: string;
    description: string;
  };
  offChainData;
  digibleNftAddress: string;
  name = '...';
  isInEth;
  isInMatic;
  visibilityStatus: { [key: number]: IntersectionStatus } = {};
  intersectionStatus = IntersectionStatus;
  // changeDetection: ChangeDetectionStrategy.OnPush
  CountdownTimeUnits: Array<[string, number]> = [
    ['Y', 1000 * 60 * 60 * 24 * 365], // years
    ['M', 1000 * 60 * 60 * 24 * 30], // months
    ['D', 1000 * 60 * 60 * 24], // days
    ['H', 1000 * 60 * 60], // hours
    ['m', 1000 * 60], // minutes
    ['s', 1000], // seconds
    ['S', 1], // million seconds
  ];
  formatDate?: CountdownFormatFn = ({ date, formatStr, timezone }) => {
    let duration = Number(date || 0);

    return this.CountdownTimeUnits.reduce((current, [name, unit]) => {
      if (current.indexOf(name) !== -1) {
        const v = Math.floor(duration / unit);
        duration -= v * unit;
        return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
          return v.toString().padStart(match.length, '0');
        });
      }
      return current;
    }, formatStr);
  };
  isBackVideo = false;
  isVideo = false;

  constructor(
    private offchain: OffchainService,
    private nft: NftService,
    private math: MathService,
    private cdr: ChangeDetectorRef,
    private market: MarketplaceService,
    private readonly walletService: WalletService,
    private verifiedProfiles: VerifiedWalletsService,
    private helpers: HelpersService,
    private moralis: MoralisService,
    private authenticationService: AuthenticationService,
    private routerLink: Router
  ) {}

  ngOnInit(): void {
    if ((this.data.price as any) === '') {
      this.data.price = null;
    }
    this.loadNFTData();
  }

  calledimageZoomZoom() {
    this.imageZoom.emit(true);
  }

  async loadNFTData(): Promise<void> {
    if (this.data.token_address) {
      this.getAddress();
      this.loadData().then((res) => {
        this.checkIfAuction();
      });
      this.loadSale();
    }
    this.loadData();
  }

  async loadSale(): Promise<void> {
    if (this.data.token_address) {
      const sale = await this.market.getSaleForToken(
        this.data.token_address,
        parseInt(this.data.id + '', undefined),
        this.data.network
      );

      if (sale !== null && sale.available) {
        this.data.auction = false;
        this.data.price = this.math.toHumanValue(sale.price);
        this.endDate = sale.endDate;
      }
      this.cdr.detectChanges();
    }
  }

  async checkIfAuction(): Promise<void> {
    if (this.data.token_address) {
      const auctionId = await this.nft.getAuctionIdByToken(
        parseInt(this.data.id + '', undefined),
        this.data.network,
        this.data.token_address
      );
      if (auctionId !== null && auctionId !== undefined) {
        const auction = await this.nft.getAuctionById(
          auctionId,
          this.data.network
        );
        if (auction.available) {
          this.data.auctionOrSaleData = auction;
          this.data.auctionOwner = auction.owner;
          this.endDate = auction.endDate;
          this.endDate = this.endDate * 1000;
        }
      }
      this.cdr.detectChanges();
    }
  }

  async getAddress(): Promise<void> {
    this.address = await this.walletService.getAccount();
  }
  async loadData(): Promise<any> {
    if(this.data.is20Item && this.data.auction) {
      this.config = {
        stopTime: new Date(
          this.data.is20Item
            ? this.data.auction.data.ends
            : this.data.auctionOrSaleData.endDate * 1000
        ).getTime(),
        format: 'DDd HHh mm:ss',
        formatDate: this.formatDate,
      };
    }
   

    if (this.data && this.data.token_address) {
      const card: any = await this.moralis.getNftDataFromMoralis(
        this.data.token_address,
        this.data.id,
        this.data.network
      );
      if (card.tokenData.attributes) {
        this.physical = card.tokenData.attributes.find(
          (x) => x.trait_type === 'Physically Backed'
        )?.value;
      }
      this.owner = card.owner_of;
      /* if (
        this.data.owner_of.toLowerCase() ===
          environment.auctionAddressMatic.toLowerCase() ||
        this.data.owner_of.toLowerCase() ===
          environment.auctionAddressEth.toLowerCase()
      ) {
        this.owner = this.data.auctionOrSaleData.owner;
      } */
      this.ownerUsername = await this.verifiedProfiles.getVerifiedName(
        this.owner
      );
      const newObj = { ...this.data, ...card };
      this.data = newObj;
    }
  }

  onVisibilityChanged(index: number, status: IntersectionStatus) {
    this.visibilityStatus[index] = status;
  }

  trackByIndex(index: number) {
    return index;
  }

  onItemCardClicked(): void {
    this.routerLink.navigate(['/details', this.data.id], {
      queryParams: {
        is20Collection: this.collectionItem?.is20Item,
        collectionId: this.collectionItem?.collection_id,
      },
      queryParamsHandling: 'merge',
    });
  }

  keepOriginalOrder = (a, b) => a.key;
}

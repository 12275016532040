import { AvailableForSale } from 'src/app/types/collection-item.type';
import { AuthenticationService } from './../../services/Authentication.service';
import { DatePipe, Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { MathService } from 'src/app/services/math.service';
import { MaticService } from 'src/app/services/matic.service';
import { MoralisService } from 'src/app/services/moralis.service';
import { NftService } from 'src/app/services/nft.service';
import { OffchainService } from 'src/app/services/offchain.service';
import { VerifiedWalletsService } from 'src/app/services/verified-wallets.service';
import { WalletService } from 'src/app/services/wallet.service';
import { Network } from 'src/app/types/network.enum';
import { environment } from 'src/environments/environment';
import { HelpersService } from '../../services/helpers.service';
import { DescriptionType } from '../../types/description.type';
import { DOCUMENT } from '@angular/common';
import { CollectionsService } from 'src/app/services/collections.service';
import { CommentType } from 'src/app/types/comments.type';
import { CartService } from 'src/app/services/cart.service';
import moment from 'moment';
import { rightArithShift } from 'mathjs';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  @ViewChild('burnTokenModal') burnTokenModal: ElementRef;
  @ViewChild('editDescriptionModal') editDescriptionModal: ElementRef;
  @ViewChild('imageZoomModal') imageZoomModal: ElementRef;
  data: any;
  preData: any = {};
  attributes;
  readonly availableForSale = AvailableForSale;
  commentType = CommentType.CollectionItem;
  id;
  symbol = environment.stableCoinSymbol;
  name = '...';
  network = '...';
  auctionOrSaleData;
  description: DescriptionType;
  nftData;
  winner = '...';
  isVerifiedEmail: boolean = false;
  showAlertMessage: boolean = false;
  tokenType: string;
  customBorder;
  winnerIsVerified;
  endDate = '...';
  burnDate;
  physical;
  canMintOnMatic;
  auctionId;
  auctionOwner;
  saleId;
  fee;
  highestBid = null;
  address: string;
  fullDescription;
  inputDescription;
  inputPublisher;
  inputEdition;
  inputYear;
  inputGraded;
  inputPopulation;

  networkWherCardIs = '...';
  explorerPrefixOfOwner;
  contractAddress = '...';
  ownerAddress;
  verifiedSeller;
  isYours = false;
  lowBid = true;
  explorerPrefix;
  card: any;
  auction = false;
  buy = false;
  price;
  priceDecimals;
  priceBuyNow;
  priceBuyNowDecimals;
  allowed;
  allowedMarket;
  inputAmount;
  hasRoyalty;
  hasRoyaltyOnAuction;
  royaltyFee;
  royaltyFeeAuction;

  lastBids: { amount: string; wallet: string; created: number }[];

  showAllow = false;
  loading = false;
  loadingLastBids = false;
  loadingLastSells = false;
  showMaticApprove = true;
  isInEth = false;
  canMint = false;
  currentUser: any;
  isAdmin;
  descriptionLoading = false;
  firstSale: boolean;
  firstAuction: boolean;
  nftAddress: string;
  lastSells;
  backSideImageExists = false;
  public imagePath;
  imgURL: any;
  public message: string;

  enableZoom: Boolean = true;
  previewImageSrc: any;
  zoomImageSrc: any;
  is20Collection: boolean = false;
  collectionId;
  itemData;
  collectionStatus;
  itemFavoriteData;
  favorites_count;
  isCategory: number;

  private readonly canGoBack: boolean;

  // no of items that added to cart
  count: number = 0;
  total: number = 0;
  isLoggedIn;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private readonly route: ActivatedRoute,
    readonly router: Router,
    private readonly location: Location,
    private readonly offChain: OffchainService,
    private readonly walletService: WalletService,
    private readonly nft: NftService,
    private readonly cdr: ChangeDetectorRef,
    private readonly math: MathService,
    private readonly market: MarketplaceService,
    private readonly matic: MaticService,
    private readonly verifiedProfiles: VerifiedWalletsService,
    private readonly moralis: MoralisService,
    private readonly helpers: HelpersService,
    public datepipe: DatePipe,
    private readonly collectionService: CollectionsService,
    private cartService: CartService,
    public authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }

    this.canGoBack = !!this.router.getCurrentNavigation()?.previousNavigation;
    this.document.body.classList.add('details');
  }

  ngOnInit(): void {
    this.currentUser = this.currentUser?.data;
    this.isVerifiedEmail = this.currentUser?.verified;
    this.tokenType = this.currentUser?.token_type;
    this.isAdmin = this.currentUser?.role[0];
    this.route.queryParams.subscribe((queryParams) => {
      this.is20Collection = queryParams.is20Collection;
      this.collectionId = queryParams.collectionId;
      this.isCategory = queryParams.isCategory;
    });
    this.route.params.subscribe((queryParams) => {
      this.id = queryParams.id;
      if (
        environment.deletedNfts.indexOf(parseInt(this.id, undefined)) !== -1
      ) {
        this.router.navigate(['/']);
        return;
      }
      this.loadData();
    });

    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        location.reload();
      });
    }
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('details');
    if (this.highestBid) {
      clearInterval(this.highestBid);
    }
  }

  async loadData(): Promise<void> {
    this.name = '...';
    this.network = '...';
    this.description = null;
    this.physical = null;
    this.networkWherCardIs = '...';
    this.explorerPrefixOfOwner = null;
    this.contractAddress = '...';
    this.ownerAddress = null;
    this.verifiedSeller = null;
    this.explorerPrefix = null;
    this.price = null;
    this.auction = false;
    this.buy = false;
    this.priceBuyNow = null;
    this.auctionId = null;
    this.saleId = null;
    this.lastSells = null;
    this.nftAddress = await this.nft.getNftAddress(true);

    if (this.highestBid) {
      clearInterval(this.highestBid);
    }

    if (this.is20Collection) {
      this.get20colectionItemDetail(this.id);
    } else {
      this.getCardDetails();
    }

    this.loadAuction().then(() => {
      this.getOwner();
      this.getLastBids();
    });
    this.checkNetwork();
    this.loadSale();
    this.getAllowed();
    this.checkApproveMatic();
    this.loadLastSells();
    this.getAddress();
    this.matic.connectPOSClient();

    /*     this.nft.canMint().then((canMint) => {
          this.canMint = canMint;
        });

        this.nft.canMint(undefined, true).then((canMint) => {
          this.canMintOnMatic = canMint;
        }); */
  }

  handleImageZoom(event) {
    this.imageZoomModal.nativeElement.click();
  }

  connectEthereum() {
    if (environment.testnet) {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: '0x5',
          },
        ],
      });
    } else {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: '0x1',
          },
        ],
      });
    }
  }

  async getRoyalty(owner: string, network: string): Promise<void> {
    if (!owner) {
      owner = this.ownerAddress;
    }

    try {
      this.hasRoyalty = await this.market.hasRoyalty(
        this.data.tokenAddress,
        this.data.id,
        this.data.network,
        this.data.owner
      );
    } catch (e) {
      console.log('HAS ROYALTY:', e);
    }

    if (this.hasRoyalty) {
      this.market.getRoyaltyFee(this.data.id, this.data.network).then((fee) => {
        this.royaltyFee = fee / 100;
      });
    }

    this.hasRoyaltyOnAuction = await this.nft.hasRoyalty(
      this.data.id,
      this.data.network,
      this.data.tokenAddress,
      this.data.owner
    );

    if (this.hasRoyaltyOnAuction) {
      this.nft.getRoyaltyFee(this.data.id, this.data.network).then((fee) => {
        this.royaltyFeeAuction = fee / 100;
      });
    }
  }

  async enableRoyalty(): Promise<void> {
    this.loading = true;
    this.fee =
      parseInt(prompt('Input your desired fee %', '5'), undefined) * 100;
    try {
      await this.market.applyRoyalty(
        this.data.id,
        this.address,
        this.fee,
        this.data.network,
        this.data.tokenAddress
      );
    } catch (e) {
      console.error(e);
    }
    this.loadData();
    this.loading = false;
  }

  async enableRoyaltyForAuction(): Promise<void> {
    this.loading = true;
    this.fee =
      parseInt(prompt('Input your desired fee %', '5'), undefined) * 100;

    try {
      await this.nft.applyRoyalty(
        this.data.id,
        this.address,
        this.fee,
        this.data.network,
        this.data.tokenAddress
      );
    } catch (e) {
      console.error(e);
    }
    this.loadData();
    this.loading = false;
  }

  async getAddress(): Promise<void> {
    this.address = await this.walletService.getAccount();
  }

  async checkApproveMatic(): Promise<void> {
    this.showMaticApprove = !(await this.nft.isApprovedForAll(
      await this.walletService.getAccount(),
      environment.maticPredicate,
      this.data?.network,
      this.data?.tokenAddress
    ));
    this.cdr.detectChanges();
  }

  async loadLastSells(): Promise<void> {
    this.loadingLastSells = true;
    let lastSells;
    try {
      lastSells = await this.moralis.getWalletTokenIdTransfers(
        this.preData.tokenAddress,
        this.preData.id,
        this.preData.network
      );
      lastSells = lastSells.result;
    } catch (e) {
      console.log(e);
    }
    if (this.lastSells === []) {
      return;
    }

    /*  try {
      lastSells = [
        ...lastSells,
        ...(await this.nft.getLastAuctionPrices(
          this.data.id,
          4,
          this.data.network
        )),
      ];
    } catch (e) {
      console.error(e.message);
    }

    try {
      lastSells = [
        ...lastSells,
        ...(await this.nft.getLastAuctionBuyNows(
          this.data.id,
          4,
          this.data.network
        )),
      ];
    } catch (e) {
      console.error(e);
    } */

    this.lastSells = lastSells?.sort(
      (a, b) => (a.created > b.created && -1) || 1
    );
    this.loadingLastSells = false;
  }

  async approveMatic(): Promise<void> {
    if ((await this.walletService.getNetwork()) !== Network.ETH) {
      alert('Connect to Ethereum network first');
      return;
    }
    this.loading = true;
    try {
      await this.nft.setApprovalForAll(
        environment.maticPredicate,
        this.data.network
      );
      await this.checkApproveMatic();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  async sendToMatic(): Promise<void> {
    if ((await this.walletService.getNetwork()) !== Network.ETH) {
      alert('Connect to Ethereum network first');
      return;
    }
    this.loading = true;
    try {
      await this.matic.sendToMatic(this.id);
      alert(
        'Transfer ordered! You will receive your token on Matic in about 10 minutes.'
      );
      this.loadData();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  async sendToEthereum(): Promise<void> {
    if ((await this.walletService.getNetwork()) !== Network.MATIC) {
      alert('Connect to Matic network first');
      return;
    }
    this.loading = true;
    try {
      await this.matic.sendToEthereum(this.id);
      alert(
        'Transfer ordered! Change to "Ethereum" network on Metamask and go to your "Profile" section in order to claim your token.'
      );
      this.loadData();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  async loadSale(): Promise<void> {
    console.log('LOAD SALE');

    const sale = await this.market.getSaleForToken(
      await this.nft.getNftAddressByNetwork(this.data?.network),
      parseInt(this.data?.id + '', undefined),
      this.data.network
    );

    this.firstSale = sale === null;
    if (sale !== null && sale.available) {
      this.auction = false;
      this.buy = true;
      this.price = this.math.toHumanValue(sale.price);
      this.priceDecimals = parseInt(sale.price, undefined);
      this.saleId = sale.saleId;
      this.endDate = sale.endDate;
      this.data.auctionOrSaleData = sale;
      console.log(this.data);
    }
    this.cdr.detectChanges();
  }

  async loadAuction(): Promise<any> {
    const auctionId = await this.nft.getAuctionIdByToken(
      parseInt(this.data?.id + '', undefined),
      this.data.network,
      this.data.tokenAddress
    );
    this.auctionId = auctionId;
    this.firstAuction = auctionId == null;
    if (auctionId !== null) {
      const auction = await this.nft.getAuctionById(
        auctionId,
        this.data.network
      );
      if (auction) {
        this.auctionOwner = auction.owner;
        if (
          this.data.owner_of.toLowerCase() ===
          environment.auctionAddressMatic.toLowerCase()
        ) {
          this.ownerAddress = auction.owner;
          this.verifiedSeller = await this.verifiedProfiles.getVerifiedName(
            this.auctionOwner
          );
        } else if (
          this.data.owner_of.toLowerCase() === this.address.toLowerCase()
        ) {
          this.ownerAddress = this.data.owner_of;
          this.verifiedSeller = await this.verifiedProfiles.getVerifiedName(
            this.ownerAddress
          );
        }
        this.isYours =
          this.ownerAddress.toLowerCase() === this.address.toLowerCase();
        if (auction.available) {
          this.auction = true;
          this.auctionOrSaleData = auction;
          let price = await this.nft.getAuctionPrice(
            auctionId,
            auction,
            this.data.network
          );
          this.price = this.math.toHumanValue(price.price);
          const winnerName = await (
            await this.verifiedProfiles.getVerifiedName(price.winner)
          ).name;
          if (
            Notification.permission === 'granted' &&
            price.winner === this.address
          ) {
            this.highestBid = setInterval(async () => {
              price = await this.nft.getAuctionPrice(
                auctionId,
                auction,
                this.data.network
              );
              if (price.winner !== this.address) {
                // tslint:disable-next-line: no-unused-expression
                new Notification('You have been outbidded!');
                clearInterval(this.highestBid);
                this.loadData();
              }
            }, 10000);
          }

          if (price.winner === '0x0000000000000000000000000000000000000000') {
            // console.log('NO WINNER');
            this.winner = null;
            this.winnerIsVerified = false;
          } else if (winnerName) {
            // console.log('WINNER', winnerName);
            this.winner = winnerName;
            this.winnerIsVerified = true;
          } else {
            // console.log('WINNER', price.winner);
            this.winner = price.winner;
            this.winnerIsVerified = false;
          }

          this.priceBuyNow = this.math.toHumanValue(auction.fixedPrice);
          this.priceBuyNowDecimals = parseInt(auction.fixedPrice, undefined);
          this.endDate = auction.endDate;
        }
      }
      this.getAllowed();
    }
    this.cdr.detectChanges();
  }

  async getLastBids(): Promise<void> {
    this.loadingLastBids = true;
    this.lastBids = null;
    try {
      const bids = await this.nft.getLastBids(
        this.auctionId,
        4,
        this.data.network
      );
      this.lastBids = bids;
      console.log(this.lastBids);
      this.loadingLastBids = false;
    } catch (e) {
      console.log('getLastBids ERROR ::', e);
      this.loadingLastBids = false;
    }
  }

  async getCardDetails(): Promise<void> {
    let card;
    try {
      card = await this.moralis.getNftDataFromMoralis(
        this.preData.tokenAddress,
        this.preData.id,
        this.preData.network
      );
      this.data = { ...this.preData, ...card };
      console.log(this.data);

      if (card.tokenData) {
        this.name = card.tokenData.name;
        this.data.name = card.tokenData.name;
        this.data.description = card.tokenData.description;
        this.data.image = card.tokenData.image;
        this.attributes = card.tokenData.attributes;
        this.data.attributes = card.tokenData.attributes;
      } else {
        (this.name = `#${this.preData.id.toString()} - UNREADABLE JSON`),
          (this.data.name = `#${this.preData.id.toString()} - UNREADABLE JSON`),
          (this.data.description = 'Cant get token data for this NFT');
        this.data.image = '';
        this.attributes = [];
        this.data.attributes = [];
      }
      this.data.owner = card.owner_of;
      this.ownerAddress = card.owner_of;
      // Gets wallet network
      const network = this.getNetworkData(this.data.network);
      this.data.walletNetwork = network.name.toUpperCase();
      if (this.data.tokenData.attributes) {
        this.physical = this.data.tokenData.attributes.find(
          (x) => x.trait_type === 'Physically Backed'
        )?.value;
      }
      this.getOwner();
      if (this.data.owner_of === '0x000000000000000000000000000000000000dEaD') {
        const tx = await this.nft.getBurnTransaction(this.data.id);

        if (tx[0].blockNumber) {
          const txData = await this.walletService
            .getWeb3()
            .eth.getBlock(tx[0].blockNumber);

          const date = new Date(txData.timestamp * 1000);

          this.burnDate = this.datepipe.transform(date, 'yyyy/MM/dd HH:mm:ss');
        }
      }
      // this.cdr.detectChanges();
      this.loadSale();
      this.getRoyalty(this.address, this.data.network);
      this.loadAuction().then(() => {
        this.checkNetwork();
        this.getLastBids();
        this.getAllowed();
        this.checkApproveMatic();
        // this.checkApproveSell();
        this.loadLastSells();
        this.matic.connectPOSClient();
      });
    } catch (e) {
      console.error(e);
      // await this.router.navigate(['/']);
      return;
    }
  }

  async checkApprove(): Promise<void> {
    this.showMaticApprove = !(await this.nft.isApprovedForAll(
      await this.walletService.getAccount(),
      environment.maticPredicate,
      this.data.network,
      this.data.tokenAddress
    ));
    this.cdr.detectChanges();
  }

  async getOwner(): Promise<void> {
    const ownerAddress = this.data.owner_of;
    // console.log(this.data.owner_of);
    this.address = await this.walletService.getAccount();
    const network = this.getNetworkData(this.data.network);
    this.data.walletNetwork = network.name.toUpperCase();
    this.explorerPrefixOfOwner = network.prefix;
    this.ownerAddress = ownerAddress;

    if (ownerAddress === '0x000000000000000000000000000000000000dEaD') {
      const tx = await this.nft.getBurnTransaction(this.data.id);

      if (tx[0].blockNumber) {
        const txData = await this.walletService
          .getWeb3()
          .eth.getBlock(tx[0].blockNumber);

        const date = new Date(txData.timestamp * 1000);

        this.burnDate = this.datepipe.transform(date, 'yyyy/MM/dd HH:mm:ss');
      }
    }
    this.verifiedSeller = (
      await this.verifiedProfiles.getVerifiedAddress(this.ownerAddress)
    ).username;
    console.log(this.verifiedSeller);

    this.isYours = ownerAddress.toLowerCase() === this.address.toLowerCase();
    this.cdr.detectChanges();
    this.getRoyalty(ownerAddress, this.data.network);
  }

  async checkNetwork(): Promise<void> {
    this.walletService.getNetwork().then((network: Network) => {
      const networkData = this.getNetworkData(network);
      this.data.walletNetwork = networkData?.name;

      if (this.data.walletNetwork !== this.data?.network) {
        alert(
          `Connect to the ${this.data?.network} network to interact with this NFT.`
        );
        return;
      }
      this.explorerPrefix = networkData?.prefix;
      this.cdr.detectChanges();
    });
    this.contractAddress = this.data?.tokenAddress;
    this.cdr.detectChanges();
  }

  switchToMatic(): void {
    this.walletService.switchToMatic();
  }

  onChangeInput(): void {
    this.showAllow = this.inputAmount * 10 ** 18 > this.allowed;
    this.lowBid = this.inputAmount < this.price;

    if (this.winner !== null) {
      this.lowBid = this.inputAmount <= this.price;
    }
  }

  async getAllowed(): Promise<void> {
    let currency;
    if (this.auctionOrSaleData) {
      currency = this.auctionOrSaleData.paymentCurrency;
      try {
        this.allowed = await this.nft.allowedTokenFor(
          this.data.tokenAddress,
          this.data.network,
          currency
        );
      } catch (e) {
        console.log('allowedTokenFor ERROR ::', e);
      }
      this.allowed = parseInt(this.allowed, undefined);
    }

    this.allowedMarket = await this.nft.allowedTokenFor(
      this.market.getMarketplaceAddress(this.data?.network),
      this.data.network
    );

    this.allowedMarket = parseInt(this.allowedMarket, undefined);
  }
  onBlur(evt): void {
    if (evt.target.valueAsNumber) {
      this.inputAmount = evt.target.valueAsNumber.toFixed(2);
    }
  }

  async approveMarket(): Promise<void> {
    this.loading = true;
    let currency;
    if (this.auctionOrSaleData) {
      currency = this.auctionOrSaleData.paymentCurrency;
    }
    try {
      await this.nft.approve(
        this.market.getMarketplaceAddress(this.data.network),
        this.data.network
      );
    } catch (e) {
      console.log(e);
    }
    this.getAllowed();
    this.loading = false;
  }

  async cancelMarket(): Promise<void> {
    this.loading = true;
    try {
      await this.market.cancelSale(this.saleId, this.data.network);
    } catch (e) {}
    this.loading = false;
    this.loadData();
  }

  async cancelAuction(): Promise<void> {
    if ((await this.walletService.getNetwork()) !== Network.MATIC) {
      alert('Connect to Matic network first');
      return;
    }
    this.loading = true;

    try {
      await this.nft.cancel(this.auctionId, this.data.network);
    } catch (e) {}
    this.loading = false;
    this.loadData();
  }

  async buyFromMarket(): Promise<void> {
    /* if ((await this.walletService.getNetwork()) !== Network.ETH) {
      alert('Connect to Ethereum network first');
      return;
    } */
    this.loading = true;
    try {
      await this.market.buy(this.saleId, this.data.network);
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
    this.loadData();
  }

  async approve(): Promise<void> {
    if ((await this.walletService.getNetwork()) !== Network.MATIC) {
      alert('Connect to Matic network first');
      return;
    }
    this.loading = true;
    try {
      await this.nft.approve(
        this.data.tokenAddress,
        this.data.network,
        this.auctionOrSaleData.paymentCurrency
      );
    } catch (e) {
      console.error(e);
    }
    await this.getAllowed();
    this.onChangeInput();
    this.cdr.detectChanges();
    this.loading = false;
  }

  async directBuy(): Promise<void> {
    if ((await this.walletService.getNetwork()) !== Network.MATIC) {
      this.switchToMatic();
      return;
    }
    this.loading = true;
    try {
      await this.nft.directBuy(this.auctionId, this.data.network);
    } catch (e) {}
    this.loading = false;
    this.loadData();
  }

  async bid(): Promise<void> {
    if ((await this.walletService.getAccount()) === null) {
      alert('Connect your wallet first!');
      return;
    }
    this.loading = true;
    try {
      await this.nft.bid(
        this.auctionId,
        this.math.toBlockchainValue(this.inputAmount),
        this.data.network
      );
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
    this.loadData();
  }

  async addDescription(): Promise<void> {
    this.descriptionLoading = true;
    try {
      this.fullDescription = JSON.stringify({
        publisher: this.inputPublisher || '',
        edition: this.inputEdition || '',
        year: this.inputYear || '',
        graded: this.inputGraded || '',
        population: this.inputPopulation || '',
        backCardImage: this.description.backCardImage || '',
        description: this.inputDescription || '',
      });

      await this.offChain.addDescription(
        await this.sign(),
        this.fullDescription,
        this.data.id,
        this.data.network
      );

      window.location.reload();
    } catch (e) {
      alert('Error updating.');
      console.error(e);
    }
    this.descriptionLoading = false;
    await this.getCardDetails();
    this.fillDescriptionFields();
    this.editDescriptionModal.nativeElement.click();
  }

  async sign(): Promise<string> {
    return await this.walletService.signMessage(this.fullDescription);
  }

  private getNetworkData(network: Network): { name: string; prefix: string } {
    if (network === Network.ETH) {
      return {
        name: 'Ethereum',
        prefix: 'https://etherscan.io/address/',
      };
    } else if (network === Network.MATIC) {
      return {
        name: 'Matic',
        prefix: 'https://explorer-mainnet.maticvigil.com/address/',
      };
    } else {
      return {
        name: 'Invalid',
        prefix: '#',
      };
    }
  }

  async burnNFT(id: number): Promise<void> {
    this.loading = true;
    if ((await this.walletService.getNetwork()) !== Network.ETH) {
      alert('Connect to Ethereum network first');
      this.loading = false;
      return;
    }

    this.burnTokenModal.nativeElement.click();
    await this.nft.transfer(id, '0x000000000000000000000000000000000000dEaD');
    this.loadData();
    this.loading = false;
  }

  goBack(): void {
    if (this.canGoBack) {
      // this.location.back();
      this.router.navigate(['/explore'], {
        queryParams: {
          isCategory: this.isCategory,
        },
      });
    } else {
      this.router.navigate(['/for-sale'], { relativeTo: this.route });
    }
  }

  fillDescriptionFields(): void {
    if (typeof this.description === 'object' && this.description !== null) {
      this.inputPublisher = this.description.publisher;
      this.inputEdition = this.description.edition;
      this.inputYear = this.description.year;
      this.inputGraded = this.description.graded;
      this.inputPopulation = this.description.population;
      this.inputDescription = this.description.description;
    }
  }

  get20colectionItemDetail(id: boolean) {
    this.collectionService
      .getCollectionItemById(
        this.collectionId !== undefined ? this.collectionId : null,
        this.id !== undefined ? this.id : null
      )
      .subscribe((itemData) => {
        this.itemData = itemData.data;
        /* let getAvailableDate = moment(
          itemData.data.available_at,
          'Y-MM-DD HH:mm:ss'
        );
        this.itemData.available_at = getAvailableDate.toDate();
        let getStartDate = moment(itemData.data.start_date, 'Y-MM-DD HH:mm:ss');
        this.itemData.start_date = getStartDate.toDate();
        let getEndDate = moment(itemData.data.end_date, 'Y-MM-DD HH:mm:ss');
        this.itemData.end_date = getEndDate.toDate();
        this.collectionStatus = this.itemData?.collection?.status;
        this.favorites_count = this.itemData?.favorites_count; */
      });


    this.collectionService
      .isCollectionItemFavorite(this.id)
      .subscribe((itemData) => {
        this.itemFavoriteData = itemData?.data[0]?.isfavourite;
      });
  }

  setFavorite() {
    if (this.itemData?.collection?.user_id === this.currentUser?.id) {
      this.alertService.error(
        'You can not add your own collection item to your favorites.'
      );
      return;
    }
    if (!this.isVerifiedEmail) {
      this.showAlertMessage = true;
      return;
    } else {
      /* if (
        this.authenticationService?.currentUserValue?.role.includes('admin')
      ) {
        return;
      } */
      let favorite = 'favourite';
      if (this.itemFavoriteData) {
        favorite = 'unfavourite';
      }

      this.collectionService
        .setCollectionItemFavorite(this.id)
        .subscribe(
          (response) => {
            if (this.itemFavoriteData) {
              this.itemFavoriteData = false;
              this.favorites_count -= 1;
            } else {
              this.itemFavoriteData = true;
              this.favorites_count += 1;
            }
          },
          (err) => {}
        );
    }
  }

  keepOriginalOrder = (a, b) => a.key;

  handleClickAddToCart(): void {
    if (this.itemData.collection.user_id === this.currentUser?.id) {
      this.alertService.error(
        'You can not add your own collection item to your cart.'
      );
      return;
    }
    if (this.currentUser?.role[0] === 'admin') {
      this.alertService.error('Admin can not add items to cart.');
      return;
    }
    if (this.isVerifiedEmail === false || this.isVerifiedEmail === undefined) {
      this.showAlertMessage = true;
      return;
    } else {
      this.cartService.addToCart(this.itemData);
      this.count = this.cartService.collectionItemCount;
      this.total = this.cartService.getCartItemTotal(this.itemData);
    }
  }

  handleClickIncreaseBtn(): void {
    this.cartService.addToCart(this.itemData);
    this.count = this.cartService.getCartItemTotalCount(this.itemData);
    this.total = this.cartService.getCartItemTotal(this.itemData);
  }

  handleClickDecreaseBtn(): void {
    this.cartService.removeFromCart(this.itemData);
    this.count = this.cartService.getCartItemTotalCount(this.itemData);
    this.total = this.cartService.getCartItemTotal(this.itemData);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { CartService } from 'src/app/services/cart.service';
import { CollectionsService } from 'src/app/services/collections.service';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
})
export class ItemDetailsComponent implements OnInit {
  collectionId = '';
  itemId = '';
  itemData: any;
  loading;
  currentUser: any;
  isVerifiedEmail: boolean = false;
  // no of items that added to cart
  count: number = 0;
  total: number = 0;
  isAuctionOpen: boolean = true;
  isLoggedIn;
  isAdmin;

  constructor(
    private readonly route: ActivatedRoute,
    private cartService: CartService,
    private collectionService: CollectionsService,
    public authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
    this.route.queryParams.subscribe((queryParams) => {
      this.collectionId = queryParams?.collection;
    });
    this.route.params.subscribe((queryParams) => {
      this.itemId = queryParams?.id;
    });
  }

  ngOnInit(): void {
    this.currentUser = this.currentUser?.data;
    this.isAdmin = this.currentUser?.role[0];
    this.isVerifiedEmail = this.currentUser?.verified;

    this.collectionService
      .getCollectionItemById(this.collectionId, this.itemId)
      .subscribe((itemData) => {
        this.itemData = itemData.data;
        let getAvailableDate = moment(
          itemData.data.available_at,
          'Y-MM-DD HH:mm:ss'
        );
        this.itemData.available_at = getAvailableDate.toDate();
        let getStartDate = moment(itemData.data.start_date, 'Y-MM-DD HH:mm:ss');
        this.itemData.start_date = getStartDate.toDate();
        let getEndDate = moment(itemData.data.end_date, 'Y-MM-DD HH:mm:ss');
        this.itemData.end_date = getEndDate.toDate();
        this.total = this.cartService.getCartItemTotal(this.itemData);
        this.count = this.cartService.getCartItemTotalCount(this.itemData);
        if (moment(this.itemData.end_date).diff(moment(new Date())) <= 0) {
          this.isAuctionOpen = false;
        }
      });
  }

  handleClickAddToCart(): void {
    if (this.itemData.collection.user_id === this.currentUser?.id) {
      this.alertService.error(
        'You can not add your own collection item to your cart.'
      );
      return;
    }
    if (this.currentUser?.role[0] === 'admin') {
      this.alertService.error('Admin can not add items to cart.');
      return;
    }
    if (!this.isVerifiedEmail) {
      this.alertService.error('Please verify your email to add item to cart');
      return;
    }
    this.cartService.addToCart(this.itemData);
    this.count = this.cartService?.collectionItemCount;
    this.total = this.cartService.getCartItemTotal(this.itemData);
  }

  handleClickIncreaseBtn(): void {
    this.cartService.addToCart(this.itemData);
    this.count = this.cartService.getCartItemTotalCount(this.itemData);
    this.total = this.cartService.getCartItemTotal(this.itemData);
  }

  handleClickDecreaseBtn(): void {
    this.cartService.removeFromCart(this.itemData);
    this.count = this.cartService.getCartItemTotalCount(this.itemData);
    this.total = this.cartService.getCartItemTotal(this.itemData);
  }
}

<div class="container p-b-40 page-top">
  <div class="row justify-center items-center">
    <div class="col-lg-6 p-t-10 p-b-6">
      <div class="form-bg">
        <h2 class="font-weight-bold">Login</h2>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              type="password"
              formControlName="password"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">
                The password must be at least
                {{ f.password.errors.minlength.requiredLength }} characters.
              </div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"
              ></span>
              Login
            </button>
            <a routerLink="/register" class="btn btn-link">Register</a>
            <a routerLink="/forgot-password" class="btn btn-link"
              >Forgot Password?</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

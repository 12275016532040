<div class="container">
  <div class="row" *ngIf="loading">
    <div class="col-lg-12">
      <div class="mx-auto text-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row justify-center p-b-40 mt-4" *ngIf="!loading">
    <div class="col-lg-9">
      <h4 *ngIf="isEdit; else add" class="bold text-blue">
        Edit item of
        <span class="font-33">{{ collectionName }}</span> Collection
      </h4>
      <ng-template #add
        ><h4 class="bold text-blue">
          Add item to 
          <span class="font-33">{{ collectionName }}</span> Collection
        </h4></ng-template
      >

      <hr />
      <form
        class="row"
        [formGroup]="collectionItemForm"
        (ngSubmit)="oncollectionItemSubmit()"
      >
        <div class="form-group col-lg-6">
          <label for="name">Name *</label>
          <input
            type="text"
            id="name"
            formControlName="name"
            class="form-control"
            [ngClass]="{
              'is-invalid':
                (submitted && f.name.errors) ||
                (f.name.touched && f.name.errors)
            }"
          />
          <div
            *ngIf="
              (submitted && f.name.errors) || (f.name.touched && f.name.errors)
            "
            class="invalid-feedback"
          >
            <div *ngIf="f.name.errors.required">Name is required</div>
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="name">Image *</label>
          <input
            (change)="handleFileInput($event?.target?.files, 'image')"
            accept=".png, .jpg, .jpeg, .gif"
            type="file"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.image.errors && !isEdit }"
          />
          <div
            *ngIf="submitted && !isEdit && f.image.errors"
            class="invalid-feedback"
          >
            An image is required
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="category">Category *</label>
          <ng-select
            placeholder="Select category"
            [items]="categories"
            bindLabel="name"
            bindValue="slug"
            formControlName="category"
            [ngClass]="{
              'is-invalid': submitted && f.category.errors
            }"
          >
          </ng-select>
          <div
            *ngIf="submitted && f.category.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.category.errors.required">
              The category is required
            </div>
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="description">Description *</label>
          <textarea
            type="text"
            maxlength="600"
            formControlName="description"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
          >
          </textarea>
          <div class="mt-1">
            <label class="float-right"
              >Characters Remaining: {{ f.description.value.length }}/600</label
            >
          </div>
          <div
            *ngIf="
              (submitted && f.description.errors) ||
              (f.description.touched && f.description.errors)
            "
            class="invalid-feedback"
          >
            The description is required.
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="edition">Edition</label>
          <input
            type="text"
            formControlName="edition"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.edition.errors }"
          />
          <!-- <div
            *ngIf="
              (submitted && f.edition.errors) ||
              (f.edition.touched && f.edition.errors)
            "
            class="invalid-feedback"
          >
            The edition must be a string.
          </div> -->
        </div>
        <div class="form-group col-lg-6">
          <label for="graded">Graded</label>
          <input
            type="text"
            formControlName="graded"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.graded.errors }"
          />
          <!-- <div
            *ngIf="
              (submitted && f.graded.errors) ||
              (f.graded.touched && f.graded.errors)
            "
            class="invalid-feedback"
          >
            The graded must be a string.
          </div> -->
        </div>
        <div class="form-group col-lg-6">
          <label for="year">Year</label>
          <select
            formControlName="year"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.year.errors }"
          >
            <option *ngFor="let year of range" value="{{ year }}">
              {{ year }}
            </option>
          </select>
          <!-- <div
            *ngIf="
              (submitted && f.year.errors) || (f.year.touched && f.year.errors)
            "
            class="invalid-feedback"
          >
            <div *ngIf="f.year.errors.required">Year is required</div>
          </div> -->
        </div>
        <div class="form-group col-lg-6">
          <label for="population">Population</label>
          <input
            type="number"
            formControlName="population"
            class="form-control"
            min="0"
            (keypress)="onKeyPress($event)"
            [ngClass]="{ 'is-invalid': submitted && f.population.errors }"
          />
          <!-- <div
            *ngIf="
              (submitted && f.population.errors) ||
              (f.population.touched && f.population.errors)
            "
            class="invalid-feedback"
          >
            The population must be a integer.
          </div> -->
        </div>
        <div class="form-group col-lg-6">
          <label for="population">Publisher</label>
          <input
            type="text"
            formControlName="publisher"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.publisher.errors }"
          />
        <!--   <div
            *ngIf="
              (submitted && f.publisher.errors) ||
              (f.publisher.touched && f.publisher.errors)
            "
            class="invalid-feedback"
          >
            The publisher name must be a string.
          </div> -->
        </div>
        <!-- <div class="form-group col-lg-6">
          <label for="price">Price *</label>
          <input
            type="number"
            formControlName="price"
            id="price"
            class="form-control"
            [ngClass]="{
              'is-invalid':
                (submitted && f.price.errors) ||
                (f.price.touched && f.price.errors)
            }"
            min="1"
            (keypress)="onKeyPress($event)"
          />
          <div
            *ngIf="
              (submitted && f.price.errors) ||
              (f.price.touched && f.price.errors)
            "
            class="invalid-feedback"
          >
            <div *ngIf="f.price.errors.required">Price is required</div>
          </div>
          <div *ngIf="isPriceZero && submitted" class="text-danger mt-1">
            <span>Price must be greater than 0</span>
          </div>
        </div> -->
        <!-- <div
          class="form-group col-lg-6"
          *ngIf="f.available_for_sale.value === AvailableForSale.OnSale"
        >
          <label>Sale start date</label>
          <app-datetime-picker
            formControlName="available_at"
            name="available_at"
            [isError]="submitted && f.available_at.errors"
          >
          </app-datetime-picker>
          <div
            *ngIf="submitted && f.available_at.errors"
            class="invalid-feedback display-block"
          >
            <ng-container *ngIf="f.available_at.errors.required">
              Sale start date is required and should be in 'dd/mm/yyyy hh:mm:ss'
              format
            </ng-container>
          </div>
          <div *ngIf="isAvailAtGreater" class="invalid-feedback display-block">
            The available_at date and time must be greater than current date and
            time .
          </div>
        </div> -->
      <!--   <ng-container
          class="col-lg-6"
          *ngIf="f.available_for_sale.value === AvailableForSale.OnAuction"
        >
          <div class="form-group">
            <label>Auction start date</label>
            <app-datetime-picker
              formControlName="start_date"
              name="start_date"
              [isError]="submitted && f.start_date.errors"
            >
            </app-datetime-picker>
            <div
              *ngIf="submitted && f.start_date.errors"
              class="invalid-feedback display-block"
            >
              <ng-container *ngIf="f.start_date.errors.required">
                Auction start date is required and should be in 'dd/mm/yyyy
                hh:mm:ss' format
              </ng-container>
            </div>
            <div
              *ngIf="isStartDateGreater"
              class="invalid-feedback display-block"
            >
              The Start date and time must be greater than current date and time
              .
            </div>
          </div>
          <div class="form-group">
            <label>Auction end date</label>
            <app-datetime-picker
              formControlName="end_date"
              name="end_date"
              [isError]="submitted && f.end_date.errors"
            >
            </app-datetime-picker>
            <div
              *ngIf="submitted && f.end_date.errors"
              class="invalid-feedback display-block"
            >
              <ng-container *ngIf="f.end_date.errors.required">
                Auction end date is required and should be in 'dd/mm/yyyy
                hh:mm:ss' format
              </ng-container>
            </div>
            <div
              *ngIf="isEndDateGreater"
              class="invalid-feedback display-block"
            >
              The End date and time must be greater than current date and time .
            </div>
          </div>
        </ng-container> -->
        <div class="form-group col-lg-6">
          <button [disabled]="loading" class="btn btn-primary w-100" *ngIf="!isEdit">
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Add Collection item
          </button>
        </div>
        <div class="form-group col-lg-6" *ngIf="isEdit">
          <button
            [disabled]="loading"
            class="btn btn-primary"
            (click)="oncollectionItemUpdate()"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

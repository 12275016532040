import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { CartService } from 'src/app/services/cart.service';
import { CollectionsService } from 'src/app/services/collections.service';
import { CommentType } from 'src/app/types/comments.type';
import { AuctionService } from 'src/app/services/auctions.service';
import { LoginResponse, User } from '../../models/user';

@Component({
  selector: 'app-offchain-details',
  templateUrl: './offchain-details.component.html',
  styleUrls: ['./offchain-details.component.scss'],
})
export class offchainDetailsComponent implements OnInit {
  collectionId = '';
  commentType = CommentType.CollectionItem;
  itemId = '';
  id;
  itemData: any;
  currentUser: User;
  isAdmin;
  isLoggedIn;
  loading;
  isVerifiedEmail: boolean = false;
  count: number = 0;
  total: number = 0;
  isAuctionOpen: boolean = true;
  collectionUserId;

  constructor(
    private readonly route: ActivatedRoute,
    private cartService: CartService,
    private collectionService: CollectionsService,
    public authenticationService: AuthenticationService,
    private alertService: AlertService,
    private readonly auctions: AuctionService
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          console.log(this.currentUser);
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
    this.route.params.subscribe((queryParams) => {
      this.itemId = queryParams?.id;
      this.collectionId = queryParams.collection_id;
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.collectionService
      .getCollectionItemById(this.collectionId, this.itemId)
      .subscribe((itemData) => {
        this.itemData = itemData.data;
        console.log(itemData);
        
        this.collectionUserId = this.itemData.id;
        if (itemData.auction) {
          this.auctions.getAuctionById(itemData.auction.id);
        }
        this.loading = false;
      });
  }

  setFavorite() {
    this.collectionService.setCollectionItemFavorite(this.itemData.id).subscribe(
      (response) => {
        console.log(response);
      },
      (err) => {}
    );
  }

  handleClickAddToCart(): void {
    if (this.itemData.collection.user_id === this.currentUser?.id) {
      this.alertService.error(
        'You can not add your own collection item to your cart.'
      );
      return;
    }
    if (this.currentUser?.role[0] === 'admin') {
      this.alertService.error('Admin can not add items to cart.');
      return;
    }
    if (!this.isVerifiedEmail) {
      this.alertService.error('Please verify your email to add item to cart');
      return;
    }
    this.cartService.addToCart(this.itemData);
    this.count = this.cartService?.collectionItemCount;
    this.total = this.cartService.getCartItemTotal(this.itemData);
  }

  handleClickIncreaseBtn(): void {
    this.cartService.addToCart(this.itemData);
    this.count = this.cartService.getCartItemTotalCount(this.itemData);
    this.total = this.cartService.getCartItemTotal(this.itemData);
  }

  handleClickDecreaseBtn(): void {
    this.cartService.removeFromCart(this.itemData);
    this.count = this.cartService.getCartItemTotalCount(this.itemData);
    this.total = this.cartService.getCartItemTotal(this.itemData);
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/Authentication.service';
import { OrderService } from '../services/order/order.service';

@Component({
  selector: 'app-seller-item-detail',
  templateUrl: './seller-item-detail.component.html',
  styleUrls: ['./seller-item-detail.component.scss']
})
export class SellerItemDetailComponent implements OnInit {

  currentUser: any;
  orders = [];
  ordersDataSize = 0
  currentPage = 1;
  ordersPerPage = 10;
  saleItemNumber: number = 0;
  itemName: string = "";
  isLoggedIn;
  isAdmin;
  isVerifiedEmail;

  constructor(
    private authenticationService: AuthenticationService,
    private orderService: OrderService,
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
   }

  ngOnInit(): void {
    this.currentUser = this.currentUser.data;
    this.getSellerItemHistory(this.currentPage);
  }
  getSellerItemHistory(page: number, itemName: string = '') {
    this.orderService.getSellerItemHistory(this.currentUser.id, page, itemName).subscribe(
      (response:any) => {
        this.orders = response.data;
        this.orders.forEach((element) => {
          element.showDetail = false;
        });
        this.ordersDataSize = response.total;
        this.currentPage = response.current_page;
        this.ordersPerPage = response.per_page;
        this.saleItemNumber = (this.currentPage - 1) * this.ordersPerPage;
      }
    )
  }
  public onPageChange(pageNum: number): void {
    this.getSellerItemHistory(pageNum);
  }
}

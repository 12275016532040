
<div class="container page-top p-b-40">
  <div class="row" *ngIf="loading">
    <div class="col">
      <div class="mx-auto text-center mt-4 mb-4">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-lg-10 mx-auto">
      <div class="row">
        <div class="col-lg-12 col-xs-12 p-b-10">
          <div class="c-details">
            <div class="c-details__inner">
              <div class="d-flex justify-content-between w-100">
                <div class="c-details__inner">
                  <button class="c-details__back" (click)="goBack()">
                    <span class="icon arrow"></span>
                  </button>
                  <h1 class="c-details__title">{{ itemData?.name }}</h1>
                  <span class="icon" *ngIf="verifiedSeller">
                    <div class="icon icon-creator"></div>
                  </span>
                </div>
                <div *ngIf="is20Collection">
                  <span
                    class="mr-3"
                    *ngIf="
                      authenticationService.currentUserValue &&
                      authenticationService?.currentUserValue?.id !==
                        itemData?.collection?.user_id
                    "
                    routerLink="/messages"
                    [queryParams]="{
                      receiver: itemData?.collection?.user_id
                    }"
                    ngbTooltip="Chat"
                    ><i class="far fa-comment-alt heart-icon"></i
                  ></span>
                  <span
                    class="favorite-count"
                    *ngIf="!itemFavoriteData && isAdmin !== 'admin'"
                    (click)="setFavorite()"
                    ngbTooltip="Favorite"
                    >{{ favorites_count }}<i class="far fa-heart heart-icon"></i
                  ></span>
                  <span
                    class="favorite-count"
                    *ngIf="itemFavoriteData"
                    (click)="setFavorite()"
                    ngbTooltip="Un Favorite"
                    >{{ favorites_count }}<i class="fas fa-heart heart-icon"></i
                  ></span>
                </div>
              </div>

              <a
                *ngIf="
                  isYours &&
                  !auction &&
                  !price &&
                  ownerAddress !== '0x000000000000000000000000000000000000dEaD'
                "
                [routerLink]="['/for-sale/create']"
                class="btn btn-primary ml-auto"
              >
                <span class="btn-title">Sell</span>
              </a>
              <!-- <div
                *ngIf="isYours && auction && price"
                class="btn btn-secondary ml-auto"
              >
                <span class="btn-title">Already for sale</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <app-digi-card
            (imageZoom)="handleImageZoom($event)"
            customBorder="{{ customBorder }}"
            view="details"
            [id]="id"
            [router]="this.router"
            [backSide]="backSideImageExists"
            [collectionItem]="itemData"
          >
          </app-digi-card>
          <div class="row p-b-20" *ngIf="!loading">
            <div class="col-md-12 col-xs-12">
              <div class="card min-height shadow">
                <div class="card-header py-3">
                  <h5
                    class="mt-2 font-weight-bold text-primary txt-title mb-0 mb-0"
                  >
                    Owner's Actions
                  </h5>
                </div>
                <div class="card-body">
                  <div *ngIf="isYours" class="c-details__buttons">
                    <button
                      class="btn btn-primary"
                      (click)="approveMatic()"
                      *ngIf="showMaticApprove && isInEth && isYours"
                    >
                      <span>Approve move to Matic</span>
                    </button>
                    <button
                      class="btn btn-primary"
                      (click)="sendToMatic()"
                      *ngIf="!showMaticApprove && isInEth && isYours"
                    >
                      <span>Move to Matic</span>
                    </button>
                    <button
                      class="btn btn-primary"
                      (click)="sendToEthereum()"
                      *ngIf="isYours && !isInEth && !auction"
                    >
                      <span>Move to Ethereum</span>
                    </button>
                    <button
                      class="btn btn-primary"
                      *ngIf="isYours && !isInEth && !auction"
                      [routerLink]="['/auctions/create', this.id]"
                    >
                      <span>Create Auction</span>
                    </button>
                    <button
                      class="btn btn-primary"
                      (click)="enableRoyalty()"
                      *ngIf="
                        canMint &&
                        isYours &&
                        !hasRoyalty &&
                        firstSale &&
                        firstAuction
                      "
                    >
                      <span>Enable Market Royalty</span>
                    </button>
                    <button
                      class="btn btn-primary"
                      (click)="enableRoyaltyForAuction()"
                      *ngIf="
                        canMintOnMatic &&
                        isYours &&
                        !hasRoyaltyOnAuction &&
                        firstAuction &&
                        firstSale
                      "
                    >
                      <span>Enable Auction Royalty</span>
                    </button>
                    <button
                      data-toggle="modal"
                      data-target="#burnTokenModal"
                      class="btn btn-primary warning"
                      *ngIf="isYours && isInEth"
                    >
                      <span>Destroy NFT</span>
                    </button>
                    <button
                      *ngIf="isYours && physical && !auction"
                      [routerLink]="['/claim', id]"
                      class="btn btn-primary mb-0"
                    >
                      <span>Claim Physical Item</span>
                    </button>
                  </div>
                  <div *ngIf="!isYours">
                    <p
                      *ngIf="
                        ownerAddress ===
                        '0x74D83801586E9D3C4dc45FfCD30B54eA9C88cf9b'
                      "
                    >
                      This NFT is being moved chains.
                    </p>
                    <p class="mb-0">
                      You do not own this NFT. Or it is being moved to a
                      different network.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-lg-12">
              <div class="card min-height shadow mb-4">
                <div class="card-header py-3">
                  <h5
                    class="mt-2 font-weight-bold text-primary txt-title mb-0 mb-0"
                  >
                    Sale Status
                  </h5>
                </div>
                <app-item-auction
                  [itemData]="itemData"
                  *ngIf="
                    is20Collection &&
                    collectionStatus === 'approved' &&
                    itemData?.available_for_sale === availableForSale?.OnAuction
                  "
                >
                  <!-- (buyItem)="handleClickAddToCart()" -->
                </app-item-auction>
                <div class="card-body" *ngIf="!buy && !auction">
                  <!-- <div class="row item-detail">
                    <div class="col-12">
                      <div class="container">
                        <div class="field">
                        </div>
                        <div class="available-for-sale-flag" *ngIf="itemData?.available_for_sale">
                          Available for sale
                        </div>
                        <div
                          class="not-available-for-sale-flag"
                          *ngIf="!itemData?.available_for_sale"
                        >
                          Not Available
                        </div>

                        <div *ngIf="itemData?.available_for_sale" class="add-to-cart-container">

                          <div>Current Count: {{ count }}</div>
                          <button
                            *ngIf="!count"
                            (click)="handleClickAddToCart()"
                            class="cart-buttons"
                          >
                            Add to cart
                          </button>
                          <ng-container *ngIf="count">
                            <button (click)="handleClickIncreaseBtn()" class="cart-buttons">
                              +
                            </button>
                            <button
                              (click)="handleClickDecreaseBtn()"
                              class="cart-buttons cart-btn-action"
                            >
                              -
                            </button>
                            <p>Total: {{ total | currency }}</p>
                          </ng-container>
                        </div>

                      </div>
                    </div>

                  </div> -->

                  <div class="row">
                    <div class="col-12">
                      <p
                        *ngIf="
                          itemData?.available_for_sale ===
                          availableForSale?.OnSale
                        "
                        class="bold m-0"
                      >
                        Available for Sale
                      </p>
                      <p
                        *ngIf="
                          itemData?.available_for_sale ===
                          availableForSale?.OnAuction
                        "
                        class="bold m-0"
                      >
                        On Auction
                      </p>
                      <p
                        *ngIf="
                          itemData?.available_for_sale ===
                          availableForSale?.NotForSale
                        "
                        class="bold m-0"
                      >
                        Not for sale right now please check again in future.
                      </p>

                      <div class="row item-detail">
                        <div class="col-12">
                          <div class="container">
                            <div class="field"></div>

                            <div
                              class="not-available-for-sale-flag"
                              *ngIf="
                                itemData?.available_for_sale ===
                                availableForSale?.NotForSale
                              "
                            >
                              Not Available
                            </div>
                            <div
                              class="not-available-for-sale-flag"
                              *ngIf="
                                itemData?.available_for_sale ===
                                availableForSale?.OnSold
                              "
                            >
                              Item has been sold.
                            </div>

                            <div
                              *ngIf="
                                itemData?.available_for_sale ===
                                availableForSale?.OnSale
                              "
                              class="add-to-cart-container"
                            >
                              <div>Current Count: {{ count }}</div>
                              <button
                                *ngIf="!count"
                                (click)="handleClickAddToCart()"
                                class="cart-buttons"
                              >
                                Add to cart
                              </button>
                              <ng-container *ngIf="count">
                                <button
                                  (click)="handleClickDecreaseBtn()"
                                  class="cart-buttons"
                                >
                                  Remove from cart
                                </button>
                                <p>Total: {{ total | currency }}</p>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" *ngIf="buy">
                  <div class="row">
                    <div
                      class="col-12"
                      *ngIf="
                        ownerAddress ===
                          '0x000000000000000000000000000000000000dEaD' &&
                        physical
                      "
                    >
                      <div class="name bold">
                        <span class="icon fire"></span> Claimed -
                        {{ burnDate }} (Local)
                        <span class="icon fire"></span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      price &&
                      ownerAddress !==
                        '0x000000000000000000000000000000000000dEaD'
                    "
                  >
                    <div class="col-md-4 sub-title">Price</div>
                    <div class="col-md-6">
                      <div class="c-details__price">
                        <span class="mr-2">${{ price }} </span>
                        <span *ngIf="symbol == 'USDT'" class="icon usdt"></span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      ownerAddress !==
                      '0x000000000000000000000000000000000000dEaD'
                    "
                  >
                    <div class="col-md-4 sub-title">End Date (Local)</div>
                    <div class="col-md-6">
                      {{ endDate * 1000 | date: "HH:mm dd/MM" }}
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      ownerAddress !==
                      '0x000000000000000000000000000000000000dEaD'
                    "
                  >
                    <div class="col-4 sub-title">Seller status:</div>
                    <div class="col-6">
                      <span *ngIf="!verifiedSeller" class="text-danger"
                        >NOT VERIFIED</span
                      >
                      <span *ngIf="verifiedSeller" class="text-success"
                        >VERIFIED</span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-body" *ngIf="auction">
                  <div class="row p-b-10">
                    <div class="col-4 sub-title" *ngIf="winner">
                      Current bid:
                    </div>
                    <div class="col-4 sub-title" *ngIf="!winner">
                      Starter bid:
                    </div>
                    <div class="col-6 f-s-20">
                      <div class="c-details__price" *ngIf="price">
                        <span class="mr-2">${{ price }} </span>
                        <span *ngIf="symbol == 'USDT'" class="icon usdt"></span>
                      </div>
                    </div>
                  </div>

                  <div class="row p-b-10">
                    <div class="col-4 sub-title">Current Winner:</div>
                    <div class="col-6" *ngIf="!winner">None</div>
                    <div class="col-6" *ngIf="winner">
                      <div *ngIf="winnerIsVerified" class="icon-creator"></div>
                      <a [routerLink]="['/profile', winner]">
                        <span *ngIf="!winnerIsVerified && winner != address"
                          >{{ winner | slice: 0:6 }}...{{
                            winner | slice: 36:64
                          }}</span
                        >
                        <span *ngIf="winnerIsVerified">{{ winner }}</span>
                        <span *ngIf="winner == address">
                          YOU ARE THE HIGHEST BIDDER
                        </span>
                      </a>
                    </div>
                  </div>
                  <div class="row p-b-10">
                    <div class="col-4 sub-title">End Date (Local):</div>
                    <div class="col-6">
                      {{ endDate * 1000 | date: "HH:mm:ss dd/MM/YYYY" }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 sub-title">Last Bids:</div>
                    <div class="col-12" *ngIf="loadingLastBids">
                      <div class="mx-auto text-center">
                        <app-loading></app-loading>
                      </div>
                    </div>
                    <div class="col-12" *ngFor="let bid of lastBids">
                      <div class="row" *ngIf="lastBids.length > 0">
                        <div class="col-md-4">
                          <div class="c-details__price">
                            <span class="mr-2">${{ bid.amount }} </span>
                            <span
                              *ngIf="symbol == 'USDT'"
                              class="icon usdt"
                            ></span>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <a [routerLink]="['/profile', bid.wallet]">
                            {{ bid.wallet | slice: 0:4 }}...{{
                              bid.wallet | slice: 38:64
                            }}
                          </a>
                        </div>
                        <div class="col-lg-4">
                          {{ bid.created | date: "HH:mm dd/MM" }}
                        </div>
                      </div>
                      <div class="row" *ngIf="!lastBids">
                        <div class="col-lg-4">
                          <p>There have been no bids yet.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-header py-3" *ngIf="buy || auction">
                  <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
                    Sale Actions
                  </h5>
                </div>
                <div class="card-body" *ngIf="auction">
                  <div
                    class="row"
                    *ngIf="
                      ownerAddress ===
                      '0x000000000000000000000000000000000000dEaD'
                    "
                  >
                    <div class="col-md-6 p-t-5">
                      <p>None, card claimed.</p>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      !isYours &&
                      ownerAddress !==
                        '0x000000000000000000000000000000000000dEaD'
                    "
                  >
                    <div class="col-md-6 p-t-5">
                      <form class="user">
                        <div class="form-group">
                          <input
                            type="number"
                            step="0.01"
                            class="form-control form-control-user"
                            [placeholder]="'0.00 ' + symbol"
                            (blur)="onBlur($event)"
                            [(ngModel)]="inputAmount"
                            (ngModelChange)="onChangeInput()"
                            name="input"
                          />
                        </div>
                      </form>
                    </div>
                    <div class="col-md-6 p-t-5" *ngIf="showAllow">
                      <div class="btn btn-primary full-width">
                        <span (click)="approve()">1. APPROVE FOR BID</span>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="isYours && !winner">
                    <div class="col-md-6 p-t-5">
                      <button
                        (click)="cancelAuction()"
                        class="btn btn-primary full-width"
                      >
                        <span>CANCEL AUCTION</span>
                      </button>
                    </div>
                  </div>
                  <div class="row" *ngIf="winner && isYours">
                    <div class="col-md-6 p-t-5">
                      <span>CANNOT CANCEL AUCTION</span>
                    </div>
                  </div>
                  <div class="row center">
                    <div class="col-md-6 p-t-5">
                      <button
                        *ngIf="!isYours"
                        class="btn btn-primary full-width"
                        [attr.disabled]="this.lowBid || showAllow ? '' : null"
                        (click)="bid()"
                      >
                        <span *ngIf="showAllow">2. BID NOW </span>
                        <span *ngIf="!showAllow">BID NOW </span>
                      </button>
                      <div
                        *ngIf="!showAllow && !this.lowBid"
                        class="p-t-5 warn-bid"
                      >
                        <i>
                          IMPORTANT: ONLY BID IF YOU ARE WILLING TO ACCEPT THE
                          GAS FEES
                        </i>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row p-t-20"
                    *ngIf="
                      allowed < priceBuyNowDecimals &&
                      priceBuyNowDecimals > 0 &&
                      !isYours
                    "
                  >
                    <div class="col-md-6 p-t-5">
                      <button
                        (click)="approve()"
                        class="btn btn-primary full-width"
                      >
                        <span>{{
                          "1. APPROVE BUY FOR " + priceBuyNow + " " + symbol
                        }}</span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="priceBuyNowDecimals > 0 && allowed && !isYours"
                  >
                    <div class="col-md-6 p-t-5">
                      <button
                        [disabled]="allowed < priceBuyNowDecimals"
                        class="btn btn-primary full-width"
                        (click)="directBuy()"
                      >
                        <span *ngIf="allowed < priceBuyNowDecimals"
                          >2. BUY FOR {{ priceBuyNow }} {{ symbol }}</span
                        >
                        <span *ngIf="allowed >= priceBuyNowDecimals"
                          >BUY FOR {{ priceBuyNow }} {{ symbol }}</span
                        >
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body" *ngIf="buy">
                  <div
                    class="row"
                    *ngIf="
                      ownerAddress ===
                      '0x000000000000000000000000000000000000dEaD'
                    "
                  >
                    <div class="col-md-6 p-t-5">
                      <p class="m-0">None, Card claimed.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-6"
                      *ngIf="allowedMarket < priceDecimals && !isYours"
                    >
                      <button
                        (click)="approveMarket()"
                        class="btn btn-primary full-width"
                      >
                        <span>1. APPROVE BUY</span>
                      </button>
                    </div>
                    <div class="col-md-6 p-t-5">
                      <div class="btn btn-primary full-width">
                        <span (click)="approve()">1. APPROVE FOR BID</span>
                      </div>
                    </div>
                    <!--   <div class="col-md-6 p-t-5" *ngIf="
                        allowedMarket &&
                        !isYours &&
                        ownerAddress !==
                          '0x000000000000000000000000000000000000dEaD'
                      ">
                      <button [disabled]="allowedMarket < priceDecimals" class="btn btn-primary full-width"
                        (click)="buyFromMarket()">
                        <span *ngIf="allowedMarket < priceDecimals">2. BUY NOW</span>
                        <span *ngIf="allowedMarket >= priceDecimals">BUY NOW</span>
                      </button>
                    </div>
                    <div class="col-md-6" *ngIf="isYours">
                      <button (click)="cancelMarket()" class="btn btn-primary full-width">
                        <span>CANCEL SALE</span>
                      </button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="card min-height shadow mb-4">
                <ng-container *ngIf="itemData?.description">
                  <div class="card-header py-3">
                    <h5
                      class="mt-2 font-weight-bold text-primary txt-title mb-0"
                    >
                      Description
                    </h5>
                  </div>
                  <div class="card-body">
                    <div>
                      <p>{{ itemData?.description }}</p>
                    </div>
                  </div>
                </ng-container>
                <div class="card-header py-3">
                  <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
                    Chain Info
                  </h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-4 sub-title">Contract Address:</div>
                    <div class="col-6">
                      <a
                        [href]="explorerPrefix + contractAddress"
                        target="blank"
                        >{{ contractAddress | slice: 0:6 }}...{{
                          contractAddress | slice: 36:64
                        }}</a
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 sub-title">Token ID:</div>
                    <div class="col-6">{{ id }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4 sub-title">Blockchain:</div>
                    <div class="col-6">{{ networkWherCardIs }}</div>
                  </div>
                </div>
                <div class="card-header py-3">
                  <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
                    Token Info
                  </h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-4 sub-title">Name:</div>
                    <div class="col-6">{{ name }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4 sub-title">Physically backed:</div>
                    <div
                      *ngIf="physical"
                      class="col-6"
                      data-tooltip="This NFT is the legal title to the physical underlying card displayed in the Image. It can be redeemed from the DIGISAFE™ for the physical card through digible.io and NFT would be burned. "
                    >
                      Yes
                    </div>
                    <div
                      *ngIf="!physical"
                      class="col-6"
                      data-tooltip="This NFT is NOT the legal title to a physical underlying collectible."
                    >
                      No
                    </div>
                  </div>
                  <div
                    class="row mt-2 p-t-15"
                    *ngIf="
                      ownerAddress ==
                        '0x000000000000000000000000000000000000dEaD' && physical
                    "
                  >
                    <div class="col-lg-12">
                      This token has been claimed. The digital version was
                      burned on the following date:
                      <b>{{ burnDate }}</b> (Local)
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      ownerAddress !=
                        '0x000000000000000000000000000000000000dEaD' ||
                      !physical
                    "
                  >
                    <div class="col-4 sub-title">Owner:</div>
                    <div class="col-6" *ngIf="!verifiedSeller">
                      <a [routerLink]="['/profile', ownerAddress]"
                        >{{ ownerAddress | slice: 0:6 }}...{{
                          ownerAddress | slice: 36:64
                        }}</a
                      >
                    </div>
                    <div class="col-6" *ngIf="verifiedSeller">
                      <a [routerLink]="['/profile', ownerAddress]"
                        >{{ ownerAddress | slice: 0:6 }}...{{
                          ownerAddress | slice: 36:64
                        }}</a
                      >
                    </div>
                  </div>
                </div>
                <div class="card-header py-3">
                  <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
                    Royalty Fees
                  </h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <p class="mb-4">
                        <span class="bold">Buy now fee: </span>
                        <span *ngIf="hasRoyalty"
                          >This item has a {{ royaltyFee }}% creator royalty
                          paid by the <span class="bold">BUYER</span> fee,
                          please note when reselling this item that this fee
                          will be applied on topof the platform's standard
                          fee.</span
                        >
                        <span *ngIf="!hasRoyalty"
                          >This item has no creator royalty.</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <p class="mb-0">
                        <span class="bold">Auction fee: </span>
                        <span *ngIf="hasRoyaltyOnAuction"
                          ><span class="bold">AUCTION</span> This item has a{{
                            royaltyFeeAuction
                          }}% creator royalty fee, please note when auctioning
                          this item that this fee will be applied on top of the
                          platform's standard fee.</span
                        >
                        <span *ngIf="!hasRoyaltyOnAuction"
                          >This item has <span class="bold">NO</span> creator
                          royalty.</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card-header py-3">
                  <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
                    <span>NFT Info </span>
                    <span class="font-weight-normal">(Edit for free)</span>
                  </h5>
                  <button
                    id="editDescriptionModal"
                    data-toggle="modal"
                    data-target="#addDescriptionModal"
                    class="btn btn-primary ml-auto"
                    *ngIf="canMint && isYours && network === 'Ethereum'"
                    #editDescriptionModal
                  >
                    <span>Edit</span>
                  </button>
                  <button
                    (click)="connectEthereum()"
                    class="btn btn-primary ml-auto"
                    *ngIf="canMint && isYours && network !== 'Ethereum'"
                  >
                    <span>Connect to Eth to edit</span>
                  </button>
                </div>
                <div class="card-body">
                  <div
                    *ngIf="
                      network === 'Matic' && description.description !== {}
                    "
                  >
                    <p class="m-0 bold">
                      Please switch to the Ethereum network to edit NFT Info
                    </p>
                  </div>
                  <ng-container
                    *ngFor="
                      let desc_item of description | keyvalue: keepOriginalOrder
                    "
                  >
                    <ul
                      class="c-details__list"
                      *ngIf="desc_item.key !== 'backCardImage'"
                    >
                      <li class="row">
                        <span class="bold text-capitalize col-4">
                          {{ desc_item.key }}:
                        </span>
                        <span class="col-6">{{
                          desc_item.value || "..."
                        }}</span>
                      </li>
                    </ul>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 p-b-40">
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
                Last Sales
              </h5>
            </div>
            <div class="card-body">
              <div>
                <div class="row" *ngIf="loadingLastSells">
                  <div class="col-lg-12">
                    <div class="mx-auto text-center">
                      <app-loading></app-loading>
                    </div>
                  </div>
                </div>
                <div class="c-details__sells-titles">
                  <div class="row items-center" *ngIf="!loadingLastSells">
                    <div class="col-4">
                      <span class="bold">Price</span>
                    </div>
                    <div class="col-4">
                      <span class="bold">Date</span>
                    </div>
                    <div class="col-4">
                      <span class="bold">Buyer</span>
                    </div>
                  </div>
                </div>
                <div class="c-details__sells" *ngFor="let sell of lastSells">
                  <div class="row items-center">
                    <div class="col-4 sub-title">
                      <div class="c-details__price">
                        <span class="mr-2">${{ sell.humanAmount }} </span>
                        <span *ngIf="symbol == 'USDT'" class="icon usdt"></span>
                      </div>
                    </div>
                    <div class="col-4 sub-title">
                      {{ sell.created * 1000 | date: "HH:mm | dd/MM/YYYY" }}
                    </div>
                    <div class="col-4" *ngIf="sell.username">
                      <div class="d-flex">
                        <a
                          class="owner-name"
                          [routerLink]="['/profile', sell.username]"
                          >{{ sell.username }}</a
                        >
                        <div class="icon-creator"></div>
                      </div>
                    </div>
                    <div class="col-4" *ngIf="!sell.username">
                      <a [routerLink]="['/profile', sell.wallet]"
                        >{{ sell.wallet | slice: 0:4 }}...{{
                          sell.wallet | slice: 39:64
                        }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- comment section -->
        <div class="col-lg-12 p-b-40">
          <div class="row">
            <div class="col-12 p-t-10 p-b-6">
              <span class="section-title"
                ><span class="icon light"></span>Comments
              </span>
            </div>
            <div class="solid mt-4 mb-4"></div>
          </div>
          <div class="card-body">
            <app-comment-box [id]="id" [type]="commentType"></app-comment-box>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    id="imageZoomModal"
    data-toggle="modal"
    data-target="#imageZoomModalPopUp"
    class="visually-hidden"
    #imageZoomModal
  >
    <span>Edit</span>
  </button>
</div>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/Authentication.service';
import { WalletService } from '../../services/wallet.service';

interface Seller {
  type: string;
}
@Component({
  selector: 'app-register',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
})
export class UserAccountComponent implements OnInit {
  id: number;
  currentUser: any;
  returnUrl: string;
  verifyForm: FormGroup;
  loading = false;
  submitted = false;
  isEmailVerified: boolean = false;
  walletVerificationError: boolean = false;
  public userRequest;
  public verifyUserRequest;
  public verifyUserRequestAddress
  public verifyUserRequestStatus: boolean = false;
  isAdmin;
  isLoggedIn;
  isVerifiedEmail;

  constructor(
    private formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private readonly wallet: WalletService,
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
  }

  ngOnInit() {
    this.verifyForm = this.formBuilder.group({
      surname: ['', Validators.required],
      type: ['individual', Validators.required],
      wallet_address: ['', Validators.required],
      address: ['', Validators.required],
      address2: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      postalcode: ['', Validators.required],
      phone_no: ['', Validators.required],
      twitter_link: ['', Validators.required],
      insta_link: ['', Validators.required],
      twitch_link: ['', Validators.required],
      id_image: [''],
      address_image: [''],
      insurance_image: [''],
      code_image: [''],
    });
    this.route.params.subscribe((queryParams) => {
      this.id = queryParams.id;
      this.loadData();
    });
    this.currentUser = this.currentUser?.data;
    this.isEmailVerified = this.currentUser?.verified;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/explore';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.verifyForm.controls;
  }

  loadData() {
    this.userService.getUserById(this.id);
    this.getVerifySeller();
  }

  sellerType: Seller[] = [
    {type: 'individual'},
    {type: 'cardhouse'},
    {type: 'digi'},
  ]

  getVerifySeller() {
    this.userService.getVerifySeller().subscribe(
      response => {
        if(response.data[0]) {
          this.verifyUserRequest = response.data[0];
          this.verifyUserRequestAddress = this.verifyUserRequest.addresses.data[0];
          this.verifyUserRequestStatus = true;
          this.verifyForm.get('id_image').setValidators(Validators.required)
          this.verifyForm.get('address_image').setValidators(Validators.required)
          this.verifyForm.get('insurance_image').setValidators(Validators.required)
          this.verifyForm.get('code_image').setValidators(Validators.required)

          this.verifyForm.patchValue(
            {
              surname: this.verifyUserRequest?.surname,
              wallet_address: this.verifyUserRequest?.wallet_address,
              address: this.verifyUserRequestAddress?.address,
              address2: this.verifyUserRequestAddress?.address2,
              country: this.verifyUserRequestAddress?.country,
              state: this.verifyUserRequestAddress?.state,
              city: this.verifyUserRequestAddress?.city,
              postalcode: this.verifyUserRequestAddress?.postalcode,
              phone_no: this.verifyUserRequest?.phone_number,
              twitter_link: this.verifyUserRequest?.twitter_link,
              insta_link: this.verifyUserRequest?.insta_link,
              twitch_link: this.verifyUserRequest?.twitch_link
            }
          );
        }
      },
      error => {

      }
    );
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.verifyForm.invalid) {
      return;
    }

    if (!this.wallet.getWeb3().utils.isAddress(this.f.wallet_address.value)) {
      this.walletVerificationError = true;
      return
    }

    this.loading = true;
    this.walletVerificationError = false;

    if(this.verifyUserRequestStatus) {
      this.userService
      .updateVerifySeller(this.verifyForm.value, this.verifyUserRequest.id)
      .pipe(first())
      .subscribe(
        (data) => {

          this.router.navigate([this.returnUrl]);
          this.alertService.success('Veification form update successfully', true);
        },
        (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
    } else {
      this.userService
      .verifySeller(this.verifyForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.router.navigate([this.returnUrl]);
          this.alertService.success('Veification form sent successfully', true);
        },
        (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
    }

  }

  handleFileInput(files: FileList, name) {
    let me = this;
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {

      this.verifyForm.value.code_image = e?.target?.result;
      this.verifyForm.get(name).setValue(e?.target?.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }
}

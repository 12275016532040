<div class="container p-b-40 page-top">
  <div class="row justify-center items-center">
    <div class="col-lg-6 p-t-10 p-b-6">
      <h2>Change Password</h2>
      <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="text"
            formControlName="email"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
          />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            [type]="passwordFieldTextType ? 'text' : 'password'"
            minlength="6"
            type="password"
            formControlName="password"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.password?.errors }"
          />
          <div
            class="input-group-append"
            (click)="passwordToggleFieldTextType()"
          >
            <span class="input-group-text" *ngIf="!passwordFieldTextType">
              <i class="fa fa-eye-slash"></i>
            </span>
            <span class="input-group-text" *ngIf="passwordFieldTextType">
              <i class="fa fa-eye"></i>
            </span>
          </div>
          <div *ngIf="submitted && f.password?.errors" class="invalid-feedback">
            <div *ngIf="f.password?.errors?.required">Password is required</div>
            <div *ngIf="submitted && f.password.errors?.minlength">
              The password must be at least 6 characters.
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="confirm_password">Confirm Password</label>
          <input
            [type]="fieldTextType ? 'text' : 'password'"
            formControlName="confirm_password"
            class="form-control"
            minlength="6"
            [ngClass]="{
              'is-invalid': submitted && f.confirm_password?.errors
            }"
          />
          <div class="input-group-append" (click)="toggleFieldTextType()">
            <span class="input-group-text" *ngIf="!fieldTextType">
              <i class="fa fa-eye-slash"></i>
            </span>
            <span class="input-group-text" *ngIf="fieldTextType">
              <i class="fa fa-eye"></i>
            </span>
          </div>
          <div
            *ngIf="
              (submitted && f.confirm_password.errors) ||
              (f.confirm_password.touched && f.confirm_password.invalid)
            "
            class="invalid-feedback"
          >
            <div *ngIf="f.confirm_password.errors?.required">
              confirm Password is required
            </div>
            <div *ngIf="f.confirm_password.errors?.minlength">
              The password must be at least 6 characters.
            </div>

            <div *ngIf="f.confirm_password?.errors?.confirmedValidator">
              Password and Confirm Password must be match.
            </div>
          </div>
        </div>

        <div class="form-group">
          <button [disabled]="loading" class="btn btn-primary">
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Change Password
          </button>
          <a routerLink="/login" class="btn btn-link">Login</a>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="container page-top">
  <div class="row">
    <div class="col-md-8 col-lg-9 p-t-10 p-b-6 mobile-hidden">
      <div class="section-title">
        <span class="icon auctions"></span>
        <span>Auctions</span>
      </div>
    </div>
    <div class="col-md-4 col-lg-3 right">
      <ng-select
        placeholder="Filter By"
        [items]="filterBy"
        bindLabel="name"
        bindValue="id"
        (change)="changeFilter()"
        [(ngModel)]="typeFilter">
      </ng-select>
    </div>
    <div class="solid mt-4 mb-4"></div>
  </div>
  <div class="row" *ngIf="nftListFiltered === null">
    <div class="col">
      <div class="mx-auto text-center mt-4 mb-4">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="nftListFiltered && nftListFiltered.length === 0">
    <div class="col">
      <div class="mx-auto text-center mt-4 mb-4 c-auctions__text">
        <b>There are no auctions at this moment. Come back soon!</b>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="auctionList !== null">
    <div
      *ngFor="let auction of auctionList"
      class="col-xl-3 col-lg-4 col-md-6 col-sm-12"
    >
      <app-auction-card
        loading="{{ loading }}"
        view="auctions"
        class="c-card-container"
        [data]="auction"
      ></app-auction-card>
    </div>
  </div>
 <!--  <div class="row center p-t-10">
    <div class="col-lg-12">
      <button *ngIf="loading" class="btn btn-primary mt-4 mb-4">. . .</button>
      <button
        *ngIf="!loading && nftListFiltered !== null && !endReached"
        class="btn btn-primary mt-4 mb-4"
        (click)="loadMore()"
      >
        Load more
      </button>
    </div>
  </div> -->
</div>

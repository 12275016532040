import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NftService } from '../services/nft.service';
import { WalletService } from '../services/wallet.service';
import { MoralisService } from '../services/moralis.service';
import { Network } from '../types/network.enum';
import { DigiCard } from 'src/app/types/digi-card.types';
import { OffchainService } from 'src/app/services/offchain.service';
import { NgForm } from '@angular/forms';
import { SubscribeService } from 'src/app/services/subscribe.service';
import { AuthenticationService } from '../services/Authentication.service';
import { CartService } from '../services/cart.service';
import { filter, pairwise, first } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { LoginResponse, User } from '../models/user';

const innerHeight = require('ios-inner-height');
const emailVerifySuccess = '/email/verify-success';
const emailVerifyFail = '/email/verify-failure';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(10000, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class LayoutComponent implements OnInit {
  subscribeData: any = <any>{};
  nftList: DigiCard[] = null;
  unfilteredNftList: DigiCard[] = null;
  cartAllItems = [];
  address;
  routeName;
  network;
  currentTime;
  inputNftName;
  hideNetwork = false;
  mobileNavIcon = null;
  hideCreateButton = false;
  testnet = environment.testnet;
  isMenuOpened: boolean | null = null;
  newest = false;
  readonly limit = 12;
  popUp = true;
  currentUser: User;
  isLoggedIn: boolean;
  isVerifiedEmail: boolean = false;
  showAlertMessage: boolean = false;
  cartCount = 0;
  currentRouter: string;
  tokenType: string;
  isLoginUser: string;
  userRole: string;
  isAdmin;

  constructor(
    private readonly walletService: WalletService,
    private readonly moralis: MoralisService,
    private readonly cdr: ChangeDetectorRef,
    private readonly nft: NftService,
    public router: Router,
    private readonly offchain: OffchainService,
    private subscribeService: SubscribeService,
    private authenticationService: AuthenticationService,
    private cartService: CartService,
    private alertService: AlertService
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRouter = event.url;
      });

    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        if (events[0].url === '/login') {
          this.isLoginUser = 'login';
          this.ngOnInit();
          this.cartCount = 0;
        }
      });
  }

  ngOnInit(): void {
    this.getUserDetails();
    this.alertValidation();
    this.setAddress();
    this.walletService?.init();
    this.walletService?.getNetwork();
    this.toggleTheme();
    this.cartCount = this.cartService?.itemCount;
    this.cartService.noOfAddedItems.subscribe((count) => {
      this.cartCount = count;
    });
    this.checkWalletNetwork();
    this.currentTime = new Date().toLocaleString();
    if (window.innerWidth < 500) {
      this.isMenuOpened = false;
    }
    const specifiedElement = document.getElementById('js-user-nav');
    const wrapper = document.getElementById('wrapper')
    wrapper.addEventListener('click', (event:any) => {
      var isClickInside = specifiedElement.contains(event.target);
      if (!isClickInside && document.body.classList.contains('c-user-nav--active')) {
        document.body.classList.remove('c-user-nav--active');
      }
    });
  }

  getUserDetails() {
    this.authenticationService.isLoggedIn().subscribe((x) => {
      console.log('IS LOGGED IN', this.authenticationService.isUserLoggedIn);
      this.isLoggedIn = this.authenticationService.isUserLoggedIn;
      this.authenticationService.getUser().subscribe((data) => {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isVerifiedEmail = this.currentUser.verified;
        if (this.currentUser.admin) {
          this.isAdmin = true;
        }
      });
    });
  }
  resendVerifyEmail() {
    console.log('resendEmail');

    this.authenticationService
      .resendVerifyEmail()
      .pipe(first())
      .subscribe(
        (data) => {
          if (data === '') {
            console.log('SUCCESS');
          }
          this.toggleUserMenu();
          this.cdr.detectChanges();
        },
        (error) => {
          console.log(error);
          this.alertService.error(error?.message || error);
        }
      );
  }

  alertValidation() {
    if (
      this.isVerifiedEmail === false &&
      (this.currentRouter !== '/login' || this.isLoginUser === 'login') &&
      this.currentRouter !== '/register' &&
      this.currentRouter !== emailVerifySuccess &&
      this.currentRouter !== emailVerifyFail &&
      this.tokenType === 'Bearer'
    ) {
      this.showAlertMessage = true;
    }
  }

  async setAddress() {
    const account = await window.ethereum?.request({ method: 'eth_accounts' });
    this.address = account[0];
  }

  async getCurrentUser() {
    return await this.moralis.getCurrentUser();
  }

  showPopUp() {
    const hasDisplayed = localStorage.getItem('popUpLoadedBefore');

    if (!hasDisplayed) {
      localStorage.setItem('popUpLoadedBefore', 'true');
      this.popUp = true;
    } else {
      this.popUp = false;
    }
  }

  toggleUserMenu() {
    if (document.body.classList.contains('c-wallet-nav--active')) {
      document.body.classList.remove('c-wallet-nav--active');
    }
    document.body.classList.toggle('c-user-nav--active');
    if (document.body.classList.contains('c-user-nav--active')) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }

  toggleWalletMenu() {
    if (document.body.classList.contains('c-user-nav--active')) {
      document.body.classList.remove('c-user-nav--active');
    }

    document.body.classList.toggle('c-wallet-nav--active');
    if (document.body.classList.contains('c-wallet-nav--active')) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }

  onClick() {
    if (this.currentUser?.role[0] === 'user') {
      this.router.navigate(['/user', this.currentUser?.id]);
    }
  }

  changeOfRoutes() {
    this.routeName = this.router.url
      .replace(/^\/+/g, '')
      .replace(/-/g, ' ')
      .split('/')[0]
      .split('?')[0];
  }

  subscribe(subscribeForm: NgForm) {
    if (subscribeForm.invalid) {
      return;
    }
    this.subscribeService.subscribeToList(this.subscribeData).subscribe(
      (res) => {
        alert('Subscribed!');
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async checkWalletNetwork(): Promise<void> {
    this.walletService.getNetwork().then((network: Network) => {
      if (network === Network.ETH) {
        this.network = 'Ethereum';
      } else if (network === Network.MATIC) {
        this.network = 'Matic';
      } else {
        this.network = 'Invalid';
      }
      this.cdr.detectChanges();
    });
  }

  onChangeInput(): void {
    // ToDo: Do anithing with the input data (this.inputNftName);
  }

  toggleMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
  }

  async connectWallet(): Promise<void> {
    this.address = await this.moralis.connectWallet();
  }
  async disconnectWallet(): Promise<void> {
    this.address = await this.moralis.disconnectWallet();
  }
  walletLogout() {
    console.log('called');
  }

  findResult(e) {
    const inputValue = (
      document.getElementById('searchInp') as HTMLInputElement
    ).value;
    if (inputValue === '') {
      return;
    }
    if (e.code === 'Enter' || e.type == 'click') {
      document.location.href = '/search?search=' + inputValue;
    }
  }

  logout() {
    this.authenticationService.logout().subscribe((res) => {
      this.cartCount = 0;
      this.showAlertMessage = false;
      this.router.navigate(['/login']);
      this.toggleUserMenu();
      this.cdr.detectChanges();
      this.getUserDetails();
    });
  }

  isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  closeErrorBanner() {
    console.log('called');

    // CLose banner here
    this.showAlertMessage = false;
  }

  iosBarNavFix() {
    /* if(('standalone' in window.navigator) && (window.navigator.standalone)) {
      return
    } */
    if (innerHeight() > window.innerHeight) {
      // iOS bottom bar is present
      document.body.classList.add('ios-bar--active');
    } else {
      // iOS bottom bar is NOT present so show footer
      document.body.classList.remove('ios-bar--active');
    }
  }

  truncate(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  }

  mobileMenuToggle() {
    const body = document.body;
    const mq = window.matchMedia('(max-width: 1023px)');

    if (!mq.matches) {
      return;
    }

    if (body.classList.contains('c-mobile__nav--active')) {
      body.classList.remove('c-mobile__nav--active');
      body.classList.add('c-mobile__nav--in-active');
    } else {
      body.classList.add('c-mobile__nav--active');
      body.classList.remove('c-mobile__nav--in-active');
    }
  }

  toggleTheme() {
    const toggleSwitch = document.querySelector(
      '.theme-switch input[type="checkbox"]'
    ) as HTMLInputElement;
    const currentTheme = localStorage.getItem('theme');

    if (currentTheme) {
      document.body.classList.add(`${currentTheme}-mode`);

      if (currentTheme === 'dark') {
        toggleSwitch.checked = true;
      }
    }

    function switchTheme(e) {
      if (e.target.checked) {
        document.body.classList.add('dark-mode');
        localStorage.setItem('theme', 'dark');
      } else {
        document.body.classList.remove('dark-mode');
        localStorage.setItem('theme', 'light');
      }
    }
    toggleSwitch.addEventListener('change', switchTheme, false);
  }
}

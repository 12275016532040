import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuctionService {
  constructor(private http: HttpClient) {}

  public makeOffer(auctionId: string, data: object): Observable<any> {
    return this.http.post(
      `${environment.systemLabsAPi}/v1/auctions/${auctionId}/bid`,
      data
    );
  }

  public getCurrentBid(): Observable<any> {
    return this.http.get(`${environment.systemLabsAPi}/auction/current`);
  }

  wonAuction(id: string): any {
    const data = {
      collection_item_id: id,
    };
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.systemLabsAPi}/auction/check-auction-status`, data)
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getMyAuctions(page: number, item?: string): Observable<any> {
    const data = {
      itemName: item,
    };
    const params = new HttpParams()
      .set('page', `${page}`)
      .append('search', `${item}`);
    return this.http.get(`${environment.systemLabsAPi}/auction/current`, {
      params,
    });
  }
}

<a
  *ngIf="data"
  class="c-card"
  [routerLink]="'/offchain/details/' + data.collectionItem.data.collection.data.id + '/' + data.collectionItem.data.id"
>
  <div
    class="d-flex flex-row items-center p-2"
  >
    <div class="c-collection__circle">
      <img
        *ngIf="data.collectionItem.data.image"
        width="100%"
        height="100%"
        [src]="data.collectionItem.data.image"
        alt=""
      />
      <img
        *ngIf="!data.collectionItem.data.image"
        width="100%"
        height="100%"
        src="/assets/images/home/home-1.png"
        alt=""
      />
    </div>
    <span *ngIf="data.collectionItem.data.name" class="c-card__info">{{
      data.collectionItem.data.name | truncate: [10, "..."]
    }}</span>
  </div>
  <figure
    class="c-card__figure"
  >
    <img
      *ngIf="data.image"
      class="c-card__figure__video"
      [src]="data.image"
      alt="image"
    />
    <img
      *ngIf="!data.image"
      width="100%"
      height="100%"
      src="/assets/images/home/home-1.png"
      alt=""
    />
    <div class="c-card__countdown">
      <countdown #cd [config]="config"></countdown>
      <span class="ml-2">Left</span>
      <span class="icon auctions p-0"></span>
    </div>
  </figure>
  <div
    class="c-card__body"
  >
    <div class="c-card__top">
      <h1 class="c-card__title mt-2">
        <span *ngIf="data.collectionItem.data.name">{{ data.collectionItem.data.name }}</span>
      </h1>
      <div class="c-card__price" *ngIf="data.price">
        <span class="mr-2">${{ data.price }} </span>
      </div>
    </div>
    <div class="c-card__description">
      <p *ngIf="data.collectionItem && data.collectionItem.data.description">
        {{ data.collectionItem.data.description | truncate: [120, "..."] }}
      </p>
    </div>
    <div *ngIf="data.collectionItem && !data.collectionItem.data.description" class="c-card__description">
      <p>No description has been created for this NFT.</p>
    </div>
  </div>
</a>

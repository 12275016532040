import { Router } from '@angular/router';
import { AuthenticationService } from './services/Authentication.service';
import { User } from './models/user';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Moralis } from 'moralis';
import { environment } from 'src/environments/environment';
import { UserComp } from './user.component';
import Web3 from 'web3';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser: User;
  static myapp;
  title = 'Digible dApp';
  user?: UserComp;
  address?: string;
  isLoggedIn;
  isVerifiedEmail;
  isAdmin;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef
  ) {
    this.getUserDetails();
    console.log('APP');
    
  }

  getUserDetails() {    
    this.authenticationService.isLoggedIn().subscribe((x) => {
      console.log(this.authenticationService.isUserLoggedIn);
      this.isLoggedIn = this.authenticationService.isUserLoggedIn;
      this.authenticationService.getUser().subscribe((data) => {
        this.currentUser = this.authenticationService.currentUserValue;
        this.isVerifiedEmail = this.currentUser.verified;
        if (this.currentUser.admin) {
          this.isAdmin = true;
        }
      });
    });
  }

  ngOnInit(): void {
    this.checkIfWalletConnected();
    this.setMoralisWalletEvents();

    const isSupported = () =>
      'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window;

    AppComponent.myapp = this;
    if (isSupported()) {
      const hasPermission = Notification.requestPermission();
    }
  }

  async getCurrentUser(): Promise<any> {
    const user: any = await Moralis.User.current();
    return user;
  }

  setMoralisWalletEvents() {
    Moralis.start({
      appId: environment.moralis.appId,
      serverUrl: environment.moralis.serverUrl,
    })
      .then(() => console.info('Moralis has been initialised.'))
      .finally(() => this.setLoggedInUser(Moralis.User.current()));
    Moralis.Web3.onAccountsChanged(([account]) => {
      this.getCurrentUser().then(async (user) => {
        if (user && account.length === 0) {
          this.walletLogout();
        }
        if (user && user.attributes.accounts.includes(account)) {
          window.location.reload();
          return;
        } else {
          const confirmed = confirm('Link this address to your account?');
          if (confirmed) {
            try {
              const user = await Moralis.Web3.link(account);
              // console.log(user);
            } catch (e) {
              console.log(e);
            }
          }
        }
      });
    });
    Moralis.Web3.onChainChanged((accounts) => {
      window.location.reload();
    });
    Moralis.Web3.onDisconnect((accounts) => {
      this.walletLogout();
    });
  }

  async checkIfWalletConnected() {
    const accounts = await window.ethereum?.request({ method: 'eth_accounts' });
    if (accounts && accounts.length > 0 && !Moralis.User.current()) {
      this.login();
      // console.log('User wallet is connected But no Moralis user need to log in...');
    }
    if (Moralis.User.current() && accounts.length === 0) {
      // this.walletLogout();
      // console.log('User not connected');
    }
  }

  async loadWeb3() {
    await Moralis.Web3.enableWeb3();
    const web3 = new Web3(Moralis.Web3.providers[0]);
  }

  login(provider: 'metamask' | 'walletconnect' = 'metamask') {
    (provider === 'metamask'
      ? Moralis.Web3.authenticate()
      : Moralis.Web3.authenticate({ provider })
    )
      .then((loggedInUser) => {
        this.setLoggedInUser(loggedInUser);
      })
      .then((loggedInUser) => {
        window.location.reload();
      })
      .catch((e) => {
        // SHOW POP UP ERROR HERE
        console.error(`Moralis '${provider}' login error:`, e);
      });
  }

  walletLogout() {
    Moralis.User.logOut()
      .then((loggedOutUser) => console.info('logout', loggedOutUser))
      // Set user to undefined
      .then(() => this.setLoggedInUser(undefined))
      // Disconnect Web3 wallet
      .then(() => Moralis.Web3.cleanup())
      .then((loggedInUser) => {
        window.location.reload();
      })
      .catch((e) => console.error('Moralis logout error:', e));
  }

  private setLoggedInUser(loggedInUser?: UserComp) {
    this.user = loggedInUser;
    if (loggedInUser) {
      this.address = loggedInUser.attributes.accounts[0];
    }
    console.info('Loggedin user:', loggedInUser);
    /**
     * Manual detect changes due to OnPush change detection.
     * This can be eliminated if you use async pipe and Observables
     * (out of scope of this demo)
     */
    this.cdr.detectChanges();
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/Authentication.service';
import { CollectionsService } from '../../services/collections.service';
enum Status {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  SoldOut = 'sold',
}
@Component({
  selector: 'app-collections',
  templateUrl: './my-collections.component.html',
  styleUrls: ['./my-collections.component.scss'],
})
export class MyCollectionsComponent implements OnInit, AfterViewInit {
  id: number;
  currentUser: any;
  collections: any;
  approvedCollections = [];
  rejectedCollections = [];
  pendingCollections = [];
  SoldCollections = [];
  returnUrl: string;
  readonly status = Status;
  collectionForm: FormGroup;
  collectionItemForm: FormGroup;
  loading = false;
  submitted = false;
  public nextPage;
  approvedCollectionName: string;
  approvedCollectionDataSize = 0;
  approveCurrentPage = 1;
  approvedCollectionPerPage = 10;
  rejectedCollectionName: string;
  rejectedCollectionDataSize = 0;
  rejectedCurrentPage = 1;
  rejectedCollectionPerPage = 10;
  pendingCollectionName: string;
  pendingCollectionDataSize = 0;
  pendingCurrentPage = 1;
  pendingCollectionPerPage = 10;
  soldCollectionName: string;
  soldCollectionDataSize = 0;
  soldCurrentPage = 1;
  soldCollectionPerPage = 10;
  isVerifiedEmail: boolean = false;
  isAdmin: boolean = false;
  collectionUser: string;
  isLoggedIn;

  constructor(
    private formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private collectionsService: CollectionsService,
    private userService: UserService,
    private alertService: AlertService
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
  }

  ngAfterViewInit() {
    console.log(this.authenticationService.isUserLoggedIn);
  }

  ngOnInit() {
    this.getMyCollections();
    /* this.getApprovedCollections(this.approveCurrentPage);
    this.getRejectedCollections(this.rejectedCurrentPage);
    this.getPendingCollections(this.pendingCurrentPage);
    this.getSoldCollections(this.soldCurrentPage); */
  }

  getMyCollections() {
    this.collectionsService.getMyCollections().then((res) => {
      console.log(res);
      this.collections = res;
    });
  }

  /*   getApprovedCollections(page: number, approvedCollectionName: string = '') {
    this.collectionsService
      .getApprovedCollections(page, approvedCollectionName)
      .then((res) => {
        const response = res;
        this.approvedCollections = response.data;
        this.nextPage = response.next_page_url;
        this.approveCurrentPage = response.current_page;
        this.approvedCollectionPerPage = res.per_page;
        this.approvedCollectionDataSize = res.total;
      });
  }

  getRejectedCollections(page: number, rejectedCollectionName: string = '') {
    this.collectionsService
      .getRejectedCollections(page, rejectedCollectionName)
      .then((res) => {
        const response = res;
        this.rejectedCollections = response.data;
        this.rejectedCurrentPage = response.current_page;
        this.rejectedCollectionPerPage = res.per_page;
        this.rejectedCollectionDataSize = res.total;
      });
  }

  getPendingCollections(page: number, pendingCollectionName: string = '') {
    this.collectionsService
      .getPendingCollections(page, pendingCollectionName)
      .then((res) => {
        const response = res;
        this.pendingCollections = response.data;
        this.pendingCurrentPage = response.current_page;
        this.pendingCollectionPerPage = res.per_page;
        this.pendingCollectionDataSize = res.total;
      });
  }

  getSoldCollections(page: number, getSoldCollections: string = '') {
    this.collectionsService
      .getSoldCollections(page, getSoldCollections)
      .then((res) => {
        const response = res;
        this.SoldCollections = response.data;
        this.soldCurrentPage = response.current_page;
        this.soldCollectionPerPage = res.per_page;
        this.soldCollectionDataSize = res.total;
      });
  } */

  ; /* ublic onPageChange(pageNum: number): void {
    this.getApprovedCollections(pageNum);
  }

  public onRejectPageChange(pageNum: number): void {
    this.getRejectedCollections(pageNum);
  }

  public onPendingPageChange(pageNum: number): void {
    this.getPendingCollections(pageNum);
  }

  public onSoldPageChange(pageNum: number): void {
    this.getSoldCollections(pageNum);
  } */

 /*  loadData() {
    this.userService.getUserById(this.id);
  } */

  // loadMore() {
  //   const next = this.currentPage + 1
  //   this.collectionsService.getUsercollections('?page='+next).then((res)=> {
  //     const response = res;
  //     this.collections = this.collections.concat(response.data);
  //     this.nextPage = response.next_page_url;
  //     this.currentPage = response.current_page;
  //   })

  // }
}

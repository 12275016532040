import { AuthenticationService } from './../../services/Authentication.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';
import { Conversation, Message, MessagePaginated } from 'src/app/types/message.type';
import { User } from 'src/app/models/user';
import moment from 'moment';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss'],
})
export class MessagingComponent implements OnInit {
  @ViewChild('chatHistory') private chatHistory: ElementRef;
  public isConversationsLoading = true;
  public isMessagesLoading = false;
  public isPreviousMessagesLoading = false;

  public currentUser: User;
  public conversations: Conversation[] = [];
  public messages: Message[];
  public selectedUserId: string = null;
  public selectedConversation: Conversation = null;
  public messageForm: FormGroup;
  public messagePaginatedData: Omit<MessagePaginated, 'data'>;
  isLoggedIn;
  isVerifiedEmail;
  isAdmin;

  public get getSortedConversations(): Conversation[] {
    return this.conversations.sort((conversationA, conversationB) => moment(conversationB.last_message.created_at).diff(conversationA.last_message.created_at))
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private messageService: MessageService
  ) { 
    if (!this.authService.isUserLoggedIn) {
      this.authService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authService.isUserLoggedIn;
        this.authService.getUser().subscribe((data) => {
          this.currentUser = this.authService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
  }

  ngOnInit(): void {
    const receiverId = this.activatedRoute.snapshot.queryParamMap.get('receiver');
    if (receiverId) {
      this.selectedUserId = receiverId;
    }
    this.initForm();
    this.getChats();
  }

  public openConversation(conversation: Conversation): void {
    this.selectedConversation = conversation;
    if (!this.selectedConversation) {
      this.loadUserDetails();
      return;
    }
    this.selectedUserId = conversation.sender ? conversation.sender.id.toString() : conversation.reciever.id.toString();
    this.isConversationsLoading = false;
    this.getMessages();
  }

  public backToChats() {
    this.messages = null;
    this.selectedConversation = null;
    this.selectedUserId = null;
  }

  public getPreviousMessage(): void {
    if (this.messagePaginatedData.current_page === this.messagePaginatedData.last_page) {
      return;
    }


    this.isPreviousMessagesLoading = true;
    this.messageService.getMessages(this.selectedUserId, this.messagePaginatedData.current_page + 1).subscribe(messagesData => {
      let { data: messages, ...paginationData } = messagesData;
      this.isPreviousMessagesLoading = false;
      messages = this.sortMessages(messages);
      if (this.messagePaginatedData.total !== paginationData.total) {
        const newMessagesCount = paginationData.total - this.messagePaginatedData.total;
        messages.splice(0, newMessagesCount);
        if (newMessagesCount - paginationData.per_page > 0) {
          this.messagePaginatedData.total += paginationData.per_page;
          this.messages = [...messages, ...this.messages];
          this.getPreviousMessage();
          return;
        }
      }
      this.messages = [...messages, ...this.messages];
      this.messagePaginatedData = paginationData;
    }, () => {
      this.isPreviousMessagesLoading = false;
    })
  }

  public sendMessage(): void {
    if (this.messageForm.invalid || !this.selectedUserId) {
      return;
    }

    this.messageService.sendMessage({ ...this.messageForm.value, reciever_id: this.selectedUserId })
      .subscribe(res => {
        const sentMessage: Message = { ...this.messageForm.value, reciever_id: this.selectedUserId, created_at: new Date(), sender_id: this.currentUser?.id };
        this.messages.push(sentMessage);
        this.selectedConversation.last_message = sentMessage;
        this.messageForm.get('message').reset(null);
        const selectedConversationIndex = this.conversations.findIndex(conversation => conversation.reciever ? conversation.reciever.id === +this.selectedUserId : conversation.sender.id === +this.selectedUserId);
        if (selectedConversationIndex === -1) {
          this.conversations.push(this.selectedConversation);
          return;
        }

        this.conversations[selectedConversationIndex] = this.selectedConversation;
      });
  }

  private initForm(): void {
    this.messageForm = new FormGroup({
      message: new FormControl(null, [Validators.required]),
    });
  }

  private getChats(): void {
    this.messageService.getChats().subscribe(conversions => {
      this.conversations = conversions;
      if (this.selectedUserId && !this.selectedConversation) {
        this.openConversation(
          conversions.find(conversation => conversation.reciever ? conversation.reciever.id === +this.selectedUserId : conversation.sender.id === +this.selectedUserId)
        );
        return;
      }
      this.isConversationsLoading = false;
    });
  }

  private loadUserDetails(): void {
    this.messageService.getUserDetails(this.selectedUserId).subscribe(user => {
      this.selectedConversation = {
        reciever_id: user.id,
        reciever: user,
        created_at: new Date(),
        updated_at: new Date(),
        conversation_name: user.name,
        sender_id: this.currentUser?.id,
      }
      // this.conversations.push(this.selectedConversation);
      this.getMessages();
      this.isConversationsLoading = false;
    });
  }

  private getMessages(): void {
    this.isMessagesLoading = true;
    this.messages = null;
    this.messageService.getMessages(this.selectedUserId).subscribe(messagesData => {
      const { data: messages, ...paginationData } = messagesData;
      this.isMessagesLoading = false;
      this.messages = this.sortMessages(messages);
      this.messagePaginatedData = paginationData;

      if (!messages.length) {
        return;
      }
      setTimeout(() => {
        this.chatHistory.nativeElement.scrollTop = this.chatHistory.nativeElement.scrollHeight;
      }, 0);
    }, () => {
      this.selectedUserId = null;
      this.isMessagesLoading = false;
    })
  }

  private sortMessages(messages: Message[]): Array<Message> {
    return messages.sort((messageA, messageB) => moment(messageA.created_at).diff(messageB.created_at));
  }


}

<div class="container page-top p-b-40">
  <div class="row" *ngIf="loading">
    <div class="col">
      <div class="mx-auto text-center mt-4 mb-4">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-lg-12 mx-auto">
      <div class="row">
        <div class="col-lg-12 col-xs-12 p-b-10">
          <div class="c-details">
            <div class="c-details__inner">
              <div class="d-flex justify-content-between w-100">
                <div class="c-details__inner">
                  <button class="c-details__back" (click)="goBack()">
                    <span class="icon arrow"></span>
                  </button>
                  <h1 class="c-details__title">{{ itemData?.name }}</h1>
                  <span class="icon" *ngIf="verifiedSeller">
                    <div class="icon icon-creator"></div>
                  </span>
                </div>
                <div>
                  <span
                    class="mr-3"
                    routerLink="/messages"
                    [queryParams]="{
                      receiver: itemData?.collection?.user_id
                    }"
                    ngbTooltip="Chat"
                    ><i class="far fa-comment-alt heart-icon"></i
                  ></span>
                  <span
                    class="favorite-count"
                    *ngIf="!itemFavoriteData && isAdmin !== 'admin'"
                    (click)="setFavorite()"
                    ngbTooltip="Favorite"
                    >{{ favorites_count }}<i class="far fa-heart heart-icon"></i
                  ></span>
                  <span
                    class="favorite-count"
                    *ngIf="itemFavoriteData"
                    (click)="setFavorite()"
                    ngbTooltip="Un Favorite"
                    >{{ favorites_count }}<i class="fas fa-heart heart-icon"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <app-digi-card view="details" [data]="itemData"> </app-digi-card>
          <div class="row p-b-20" *ngIf="currentUser && currentUser.id === itemData.user.data.id">
            <div class="col-md-12 col-xs-12">
              <div class="card min-height shadow">
                <div class="card-header py-3">
                  <h5
                    class="mt-2 font-weight-bold text-primary txt-title mb-0 mb-0"
                  >
                    Owner's Actions
                  </h5>
                </div>
                <div class="card-body">
                  <div class="c-details__buttons">
                    <button
                      *ngIf="!itemData.auction"
                      class="btn btn-primary"
                      [routerLink]="['/auctions/create', this.itemId]"
                    >
                      <span>Create Auction</span>
                    </button>
                    <button
                      *ngIf="currentUser && itemData.auction && currentUser.id === itemData.user.data.id"
                      class="btn btn-primary"
                      [routerLink]="['/auctions/create', this.itemId]"
                    >
                      <span>Cancel Auction</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-lg-12">
              <div class="card min-height shadow mb-4">
                <div class="card-header py-3">
                  <h5
                    class="mt-2 font-weight-bold text-primary txt-title mb-0 mb-0"
                  >
                    Sale Status
                  </h5>
                </div>
                <app-item-auction
                  [itemData]="itemData"
                  *ngIf="itemData?.auction"
                >
                  <!-- (buyItem)="handleClickAddToCart()" -->
                </app-item-auction>
                <div class="card-body" *ngIf="buy"></div>
                <div class="card-body" *ngIf="auction">
                  <div class="row p-b-10">
                    <div class="col-4 sub-title">Current Winner:</div>
                    <div class="col-6" *ngIf="!winner">None</div>
                    <div class="col-6" *ngIf="winner">
                      <div *ngIf="winnerIsVerified" class="icon-creator"></div>
                      <a [routerLink]="['/profile', winner]">
                        <span *ngIf="!winnerIsVerified && winner != address"
                          >{{ winner | slice: 0:6 }}...{{
                            winner | slice: 36:64
                          }}</span
                        >
                        <span *ngIf="winnerIsVerified">{{ winner }}</span>
                        <span *ngIf="winner == address">
                          YOU ARE THE HIGHEST BIDDER
                        </span>
                      </a>
                    </div>
                  </div>
                  <div class="row p-b-10">
                    <div class="col-4 sub-title">End Date (Local):</div>
                    <div class="col-6">
                      {{ endDate * 1000 | date: "HH:mm:ss dd/MM/YYYY" }}
                    </div>
                  </div>
                 <!--  <div class="row">
                    <div class="col-12 sub-title">Last Bids:</div>
                    <div class="col-12" *ngIf="loadingLastBids">
                      <div class="mx-auto text-center">
                        <app-loading></app-loading>
                      </div>
                    </div>
                    <div class="col-12" *ngFor="let bid of lastBids">
                      <div class="row" *ngIf="lastBids.length > 0">
                        <div class="col-md-4">
                          <div class="c-details__price">
                            <span class="mr-2">${{ bid.amount }} </span>
                            <span
                              *ngIf="symbol == 'USDT'"
                              class="icon usdt"
                            ></span>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <a [routerLink]="['/profile', bid.wallet]">
                            {{ bid.wallet | slice: 0:4 }}...{{
                              bid.wallet | slice: 38:64
                            }}
                          </a>
                        </div>
                        <div class="col-lg-4">
                          {{ bid.created | date: "HH:mm dd/MM" }}
                        </div>
                      </div>
                      <div class="row" *ngIf="!lastBids">
                        <div class="col-lg-4">
                          <p>There have been no bids yet.</p>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="card-header py-3" *ngIf="buy || auction">
                  <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
                    Sale Actions
                  </h5>
                </div>
                <div class="card-body" *ngIf="auction">
                  <div class="row" *ngIf="isYours && !winner">
                    <div class="col-md-6 p-t-5">
                      <button
                        (click)="cancelAuction()"
                        class="btn btn-primary full-width"
                      >
                        <span>CANCEL AUCTION</span>
                      </button>
                    </div>
                  </div>
                  <div class="row" *ngIf="winner && isYours">
                    <div class="col-md-6 p-t-5">
                      <span>CANNOT CANCEL AUCTION</span>
                    </div>
                  </div>
                  <div class="row center">
                    <div class="col-md-6 p-t-5">
                      <button
                        *ngIf="!isYours"
                        class="btn btn-primary full-width"
                        [attr.disabled]="this.lowBid || showAllow ? '' : null"
                        (click)="bid()"
                      >
                        <span *ngIf="showAllow">2. BID NOW </span>
                        <span *ngIf="!showAllow">BID NOW </span>
                      </button>
                      <div
                        *ngIf="!showAllow && !this.lowBid"
                        class="p-t-5 warn-bid"
                      >
                        <i>
                          IMPORTANT: ONLY BID IF YOU ARE WILLING TO BUY THE ITEM
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" *ngIf="buy"></div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="card min-height shadow mb-4">
                <ng-container *ngIf="itemData?.description">
                  <div class="card-header py-3">
                    <h5
                      class="mt-2 font-weight-bold text-primary txt-title mb-0"
                    >
                      Item Description
                    </h5>
                  </div>
                  <div class="card-body">
                    <div>
                      <p>{{ itemData?.description }}</p>
                    </div>
                  </div>
                </ng-container>
                <div class="card-header py-3">
                  <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
                    Owner Info
                  </h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-4 sub-title">Owner:</div>
                    <div class="col-6">
                      <a
                        class="d-flex"
                        *ngIf="itemData.is20Item"
                        [routerLink]="['/profile', itemData.user.data.name]"
                        >{{ itemData.user.data.name }}
                        <span
                          *ngIf="itemData.user.data.status === 'approved'"
                          class="icon-creator ml-1"
                        ></span
                      ></a>
                      <a
                        *ngIf="!itemData.is20Item"
                        [routerLink]="['/profile', ownerAddress]"
                        >{{ ownerAddress | slice: 0:6 }}...{{
                          ownerAddress | slice: 36:64
                        }}</a
                      >
                    </div>
                    <div class="col-4 sub-title">Verified Seller:</div>
                    <div class="col-6">
                      <p *ngIf="itemData.user.data.status === 'approved'">
                        true
                      </p>
                    </div>
                    <!--  <div class="col-6" *ngIf="verifiedSeller">
                      <a [routerLink]="['/profile', ownerAddress]"
                        >{{ ownerAddress | slice: 0:6 }}...{{
                          ownerAddress | slice: 36:64
                        }}</a
                      >
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 p-b-40">
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
                Last Sales
              </h5>
            </div>
            <div class="card-body">
              <div>
                <div class="row" *ngIf="loadingLastSells">
                  <div class="col-lg-12">
                    <div class="mx-auto text-center">
                      <app-loading></app-loading>
                    </div>
                  </div>
                </div>
                <div class="c-details__sells-titles">
                  <div class="row items-center" *ngIf="!loadingLastSells">
                    <div class="col-4">
                      <span class="bold">Price</span>
                    </div>
                    <div class="col-4">
                      <span class="bold">Date</span>
                    </div>
                    <div class="col-4">
                      <span class="bold">Buyer</span>
                    </div>
                  </div>
                </div>
                <div class="c-details__sells" *ngFor="let sell of lastSells">
                  <div class="row items-center">
                    <div class="col-4 sub-title">
                      <div class="c-details__price">
                        <span class="mr-2">${{ sell.humanAmount }} </span>
                        <span *ngIf="symbol == 'USDT'" class="icon usdt"></span>
                      </div>
                    </div>
                    <div class="col-4 sub-title">
                      {{ sell.created * 1000 | date: "HH:mm | dd/MM/YYYY" }}
                    </div>
                    <div class="col-4" *ngIf="sell.username">
                      <div class="d-flex">
                        <a
                          class="owner-name"
                          [routerLink]="['/profile', sell.username]"
                          >{{ sell.username }}</a
                        >
                        <div class="icon-creator"></div>
                      </div>
                    </div>
                    <div class="col-4" *ngIf="!sell.username">
                      <a [routerLink]="['/profile', sell.wallet]"
                        >{{ sell.wallet | slice: 0:4 }}...{{
                          sell.wallet | slice: 39:64
                        }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- comment section -->
        <div class="col-lg-12 p-b-40">
          <div class="row">
            <div class="col-12 p-t-10 p-b-6">
              <span class="section-title"
                ><span class="icon light"></span>Comments
              </span>
            </div>
            <div class="solid mt-4 mb-4"></div>
          </div>

          <app-comment-box [id]="itemId" [type]="commentType"></app-comment-box>
        </div>
      </div>
    </div>
  </div>
  <button
    id="imageZoomModal"
    data-toggle="modal"
    data-target="#imageZoomModalPopUp"
    class="visually-hidden"
    #imageZoomModal
  >
    <span>Edit</span>
  </button>
</div>

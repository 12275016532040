import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/Authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isLoggedIn;
  currentUser;
  isVerifiedEmail;
  isAdmin;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    // redirect to home if already logged in
    
    if (this.authenticationService.isUserLoggedIn) {
      // this.router.navigate(['/']);
    }
    this.route.queryParams.subscribe((queryParams) => {
     /*  if (queryParams?.seller) {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
      } */
    });
  }
  ngAfterViewInit() {
    // ...
    console.log(this.authenticationService.isUserLoggedIn);
  }

  ngOnInit() {
    console.log(this.authenticationService.isUserLoggedIn);
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/explore';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    console.log('LOGIN SUBMITTED...');

    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .getCsrf()
      .pipe(first())
      .subscribe(
        (data) => {          
          this.authenticationService
            .login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.router.navigate([this.returnUrl]);
              },
              (error) => {
                if (error?.errors?.email || error?.errors?.password) {
                  error.message = 'Invalid Credentials';
                }
                this.alertService.error(error?.message || error);
                this.loading = false;
              }
            );
        },
        (error) => {
          console.log(error);
          this.alertService.error(error?.message || error);
          this.loading = false;
        }
      );

    /*  this.authenticationService
      .login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.router.navigate([this.returnUrl]);
        },
        (error) => {
          if (error?.errors?.email || error?.errors?.password) {
            error.message = 'Invalid Credentials'
          }
          this.alertService.error(error?.message || error);
          this.loading = false;
        }
      ); */
  }
}

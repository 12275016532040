import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/Authentication.service';
import { ConfirmedValidator } from './confirmed.validator';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  public validationError: any;
  fieldTextType: boolean = false;
  passwordFieldTextType: boolean = false;
  isLoggedIn;
  currentUser;
  isVerifiedEmail;
  isAdmin;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService
  ) {
    // redirect to home if already logged in
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
    console.log(this.isLoggedIn);

    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      // this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group(
      {
        name: [null, Validators.required],
        username: [null, Validators.required],
        email: [null, Validators.required],
        avatar: [null, Validators.required],
        password: [null, [Validators.required, Validators.minLength(6)]],
        confirm_password: [
          null,
          [Validators.required, Validators.minLength(6)],
        ],
      },
      {
        validator: ConfirmedValidator('password', 'confirm_password'),
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    console.log(this.registerForm);
    
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      this.registerForm.errors;
      return;
    }

    var data = this.registerForm.value;
    // data.timezone = this.f.timezone.value.nameValue;
    this.loading = true;
    this.userService
      .register(data)
      .pipe(first())
      .subscribe(
        (data) => {
          this.alertService.success('Registration successful', true);
          this.router.navigate(['/login']);
        },
        (error) => {
          this.loading = false;
          this.validationError = error.errors;
        }
      );
  }

  handleFileInput(files: FileList) {
    let file = files[0];
    console.log(file);
    
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      this.registerForm.get('avatar').setValue(e.target.result);
      console.log(this.registerForm);
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  }

/*   handleFileInput(
    files: NgxFileDropEntry[],
  ): Promise<void> {
    if (files.length === 0) {
      return;
    }

    const droppedFile = files[0];
    console.log(droppedFile);
    const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: any) => {
      const toBase64 = (someFile) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(someFile);
          reader.onerror = (error) => reject(error);
          reader.onload = () => {
            // this.imageUrlPreviewHero = reader.result;
            resolve(reader.result);
          };
        });
      const baseString: any = toBase64(file);
    });
    // this.loadFiles.push({ hero_picture: this.heroPicture });
  } */

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  passwordToggleFieldTextType() {
    this.passwordFieldTextType = !this.passwordFieldTextType;
  }
}

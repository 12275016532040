import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OffchainService } from 'src/app/services/offchain.service';
import { VerifiedWalletsService } from 'src/app/services/verified-wallets.service';
import { CollectionsService } from 'src/app/services/collections.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../services/category/category.service';
import { Categories, Category } from 'src/app/models/category';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-search',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss'],
})
export class CollectionsComponent implements OnInit {
  searchReady = false;
  currentOffset = 0;
  endReached = false;
  leaders = [];
  collections: any;
  isLoading = true;
  currentPage = 1;
  total: number;
  perPage = 10;

  readonly limit = 10005;

  categoryList: Category[];
  filterForm: FormGroup;
  initData = [];
  filterData;

  constructor(
    private readonly offchain: OffchainService,
    private readonly httpclient: HttpClient,
    private collectionService: CollectionsService,
    private wallet: WalletService,
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.filterForm = this.formBuilder.group({
      search_text: [''],
      search_category: [''],
    });
  }

  async loadData(): Promise<void> {
    this.currentOffset = 0;
    this.endReached = false;
    this.getCollections();
    this.getCategoryList();
  }

  async getCategoryList() {
    this.categoryList = await this.collectionService.getAllCollectionCategories();
  }

  truncate(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  }

  collectionFilter(e) {
    // Do something
  }

  async getCollections() {
    this.searchReady = false;
    this.collections = await this.collectionService.getAllCollections();
    console.log(this.collections);
    this.searchReady = true;
    /*  const wallets = new VerifiedWalletsService(this.offchain, this.wallet);
    const allVerifiedWalletAddresses = await wallets.getAllVerifiedWalletAddresses();
    const verifiedCollections = [];
    allVerifiedWalletAddresses.forEach((data) => {
      wallets.getFullProfile(`${data.address}`).then((res) => {
        verifiedCollections.push(res);
      });
    });

    setTimeout(() => {
      this.leaders = verifiedCollections;
    }, 100);
    this.loading();
    this.leaders = [...this.leaders, ...this.collections];
    this.initData = this.leaders; */
    // this.loadPublicCollection(this.currentPage);
  }

  loadPublicCollection(page: number): void {
    this.collectionService.getAllCollections().then((col:any) => {
      // this.currentPage = col.current_page;
      // this.total = col.total;
      // this.perPage = col.per_page;
      col.forEach((item) => {
        const data = {
          username: item?.name,
          id: item?.id,
          instagram: item?.instagram,
          status: item?.status,
          twitch: item?.twitch,
          twitter: item?.twitter,
          name: item?.user?.name,
          description: item?.description,
          categories: item?.categories,
          featured_path: item?.featured_path,
          picture: item?.logo_path,
          is20Data: true,
        };
        this.collections.push(data);
      });
      this.leaders = [...this.leaders, ...this.collections].sort(
        () => Math.random() - 0.5
      );
      this.initData = this.leaders;
    });
  }

  loading() {
    setTimeout(async () => {
      this.searchReady = true;
      this.isLoading = false;
    }, 1500);
  }

  loadMore(): void {
    this.isLoading = true;
    this.currentPage += 1;
    this.loadPublicCollection(this.currentPage);
  }

  filter() {
    this.filterData = this.initData;
    let search = this.filterForm.get('search_text').value;
    if (search != '') {
      this.filterData = this.leaders.filter(function (f) {
        return f.username.includes(search);
      });
    }

    search = this.filterForm.get('search_category').value;
    if (search != '') {
      this.filterData = this.filterData.filter(function (f) {
        if (f.categories != undefined) {
          return f.categories.includes(search);
        }
      });
    }

    this.leaders = this.filterData;
  }

  filterReset() {
    this.leaders = this.initData;
    this.filterForm.patchValue({
      search_text: [''],
      search_category: [''],
    });
  }
}

<ng-container *ngIf="auctionStarted">
  <div class="card-body">
    <div class="row p-b-10">
      <ng-container>
        <div class="col-4 sub-title">Current Highest bid:</div>
        <div class="col-6 f-s-20">
          <div class="c-details__price d-flex align-items-center">
            <span class="mr-2">${{ itemData.auction.data.topBid.price }} </span>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div class="col-4 sub-title">Current Highest bidder:</div>
        <div class="col-6 f-s-20">
          <div class="c-details__price d-flex align-items-center">
            <a [routerLink]="'/user-profile/' + itemData.auction.data.topBid.bidder.id" class="mr-2">{{ itemData.auction.data.topBid.bidder.username }} </a>
          </div>
        </div>
      </ng-container>
      <!--   <ng-container *ngIf="lastBidData">
        <div class="col-4 sub-title">
          Highest bid:
        </div>
        <div class="col-6 f-s-20">
          <div class="c-details__price d-flex align-items-center">
            <span class="mr-2">${{ lastPrice }} </span>
          </div>
        </div>
      </ng-container> -->
    </div>

    <div class="row p-b-10" *ngIf="auctionEnded">
      <div class="col-4 sub-title">Winner:</div>
      <div class="col-6" *ngIf="!lastBidData">None</div>
      <div
        class="col-6"
        *ngIf="currentUser && lastBidData?.buyer_id === currentUser?.id"
      >
        <div class="icon-creator"></div>
        <a>
          <span> YOU ARE THE HIGHEST BIDDER </span>
        </a>
      </div>
      <div
        class="col-6"
        *ngIf="lastBidData && lastBidData?.buyer_id !== currentUser?.id"
      >
        <div class="icon-creator"></div>
        <a>
          <span>{{ lastBidData?.buyer?.data?.name }}</span>
        </a>
      </div>
    </div>
    <div class="row p-b-10">
      <div class="col-4 sub-title">End Date (Local):</div>
      <div class="col-6">
        <date-countdown
          [showZeros]="true"
          [end]="itemData.auction.data.ends"
          units="Year | Month | Days | Hours | Minutes | Seconds"
        >
        </date-countdown>
      </div>
    </div>
    <!--  <div *ngIf="auctionEnded" class="text-center text-info mt-3">
      <h5><b>The auction has been finished!</b></h5>
    </div> -->

    <!-- <div class="row" *ngIf="currentUser && auctionEnded && lastBidData?.buyer_id === currentUser?.id">
      <div class="col-12">
        <div class="row item-detail">
          <div class="col-12">
            <div class="container">
              <div class="field"></div>
              <div class="add-to-cart-container">
                <button class="cart-buttons" (click)="buyItemAction()"> Buy item </button>
              </div>
              <div class="text-success mt-2" *ngIf="isAddToCart"><b>You has successfully add to cart please order now
                  from add to cart.</b></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <ng-container
    *ngIf="
      currentUser && !auctionEnded && currentUser.id !== itemData.user.data.id
    "
  >
    <div class="card-header py-3">
      <h5 class="mt-2 font-weight-bold text-primary txt-title mb-0">
        Sale Actions
      </h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 p-t-5">
          <form class="user" [formGroup]="bidForm">
            <div class="form-group m-0 d-flex w-100">
              <input
                type="text"
                step="0.01"
                [ngClass]="{
                  'is-invalid':
                    bidForm?.touched && bidForm?.controls.last_price?.errors
                }"
                class="form-control form-control-user mr-2"
                formControlName="price"
                [placeholder]="'0.00 ' + symbol"
                name="price"
              />
              <button
                class="btn btn-primary full-width"
                [disabled]="bidForm?.invalid"
                (click)="makeOffer()"
              >
                BID NOW
              </button>
              <div
                *ngIf="bidForm?.touched && bidForm?.controls.last_price?.errors"
                class="invalid-feedback"
              >
                <div *ngIf="bidForm?.controls.last_price.errors.required">
                  Bid amount is required to make offer
                </div>
                <div *ngIf="bidForm?.controls.last_price.errors.min">
                  Entered amount should be equal or greater than
                  {{ bidForm?.controls.last_price.errors.min.min }} {{ symbol }}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!auctionStarted">
  <div class="card-body">
    <div class="row p-b-10">
      <div class="col-4 sub-title">Auction Start Date (Local):</div>
      <div class="col-6">
        {{ itemData.start_date | date: "MMM dd, YYYY, h:mm:ss a" }}
      </div>
    </div>
    <div class="row p-b-10">
      <div class="col-4 sub-title">Auction End Date (Local):</div>
      <div class="col-6">
        {{ itemData.end_date | date: "MMM dd, YYYY, h:mm:ss a" }}
      </div>
    </div>
  </div>
</ng-container>

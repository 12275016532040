import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from '../../services/Authentication.service';
import { first } from 'rxjs/operators';
import { AlertService } from '../../services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  params;
  loading;
  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.loadData();
  }

  loadData(): void {
    this.route.queryParams.subscribe((params) => {
      console.log(`/auth${params['verify-url'].split('/auth')[1]}`);
      this.params = params;
      this.loading = false;
    });
  }

  verifyEmail() {
    this.loading = true;
    this.authenticationService
      .verifyEmail(`/auth${this.params['verify-url'].split('/auth')[1]}`)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if ((data = 'null')) {
            this.loading = false;
            this.router.navigate(['/']);
          }
        },
        (error) => {
          console.log(error);
          this.alertService.error(error?.message || error);
          this.loading = false;
        }
      );
  }
}

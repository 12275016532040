import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AvailableForSale } from './../../types/collection-item.type';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import moment from 'moment';
import { AuctionService } from 'src/app/services/auction.service';
import { User } from 'src/app/models/user';
import { CartService } from 'src/app/services/cart.service';
import { interval, Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/services/Authentication.service';

let isAuctionStatus: any;
const INTERVAL = 60000;
@Component({
  selector: 'app-item-auction',
  templateUrl: './item-auction.component.html',
  styleUrls: ['./item-auction.component.scss'],
})
export class ItemAuctionComponent implements OnInit {
  @Input() public itemData = null;
  // @Output() public buyItem: EventEmitter<void> = new EventEmitter();
  public readonly symbol = environment.stableCoinSymbol;
  public readonly AvailableForSale = AvailableForSale;
  public loadingLastBids = false;
  public bids = [];
  isAddToCart: boolean = false;
  cartCollectionItems = [];
  public currentUser: User;
  isAuctionEnded: boolean = true;
  subscription: Subscription;
  public bidForm: FormGroup;
  isLoggedIn;
  isVerifiedEmail;
  isAdmin;

  public constructor(
    private cartService: CartService,
    private readonly authService: AuthenticationService,
    private alertService: AlertService,
    private readonly auctionService: AuctionService,
    private readonly authenticationService: AuthenticationService
  ) {    
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
  }

  public get auctionStarted(): boolean {
    return (
      moment(this.itemData.auction.data.starts).diff(moment(new Date())) <= 0
    );
  }

  public get auctionEnded(): boolean {
    return (
      moment(this.itemData.auction.data.ends).diff(moment(new Date())) <= 0
    );
  }

  public get lastBidData(): number {
    return this.itemData?.lastbet?.data || this.bids[this.bids.length - 1];
  }

  public get lastPrice(): number {
    return (
      this.itemData?.lastbet?.data.last_price ||
      this.bids[this.bids.length - 1]?.last_price
    );
  }

  public ngOnInit(): void {
    this.bids = this.itemData?.auction.data;
    this.bidForm = new FormGroup({
      price: new FormControl(this.lastPrice || this.itemData.price || 0, [
        Validators.required,
        Validators.min(this.lastPrice + 0.01 || this.itemData.price),
      ]),
    });

    if (moment(this.itemData.start_date).diff(moment(new Date())) <= 0) {
      const source = interval(INTERVAL);
      // isAuctionStatus = source.subscribe((val) => this.wonAuction());
    }
  }
  stopChangeAuctionStatus() {
    this.isAuctionEnded = false;
    clearInterval(isAuctionStatus);
  }

  wonAuction() {
    this.auctionService.wonAuction(this.itemData?.id).then((res) => {
      if (res.data) {
        this.alertService.success(res.message, true);
        this.stopChangeAuctionStatus();
      }
      // this.addAuction(res.data);
    });
  }

  public makeOffer(): void {
    if (this.bidForm.invalid) {
      return;
    }
    this.auctionService.makeOffer(this.itemData.auction.data.id, this.bidForm.value).subscribe((res) => {
      console.log(res);
      if(res === {}) {
        // SHOW success here 
      }
      // this.addAuction(res.data);
     /*  this.bidForm
        .get('last_price')
        .setValidators([
          Validators.required,
          Validators.min(+this.bidForm.value.last_price + 0.01),
        ]); */
      // this.bidForm.get('last_price').setValue(null);
      // this.bidForm.get('last_price').markAsUntouched();
    });
  }

  // public buyItemAction(): void {
  //   this.cartCollectionItems = this.cartService.cartItems;
  //   const index = this.cartCollectionItems.findIndex(item => item.id === this.itemData.id);
  //   if(index === -1){
  //     this.buyItem.emit()
  //   }else{
  //     this.isAddToCart = true;
  //   }

  // }

 /*  private addAuction(auction): void {
    auction = {
      ...auction,
      buyer: { data: this.currentUser },
    };

    this.bids.push(auction);

    this.itemData = {
      ...this.itemData,
      lastbet: { data: auction },
    };
  } */
}

<div class="favouriteItem m-5">
  <div class="main-body">
    <div class="pl-4 pr-4">
      <div class="input-group col-12 mb-5">
        <div class="form-outline col-5">
          <input id="search-focus" placeholder="Search by item name" [(ngModel)]="itemName" type="search" class="form-control" />
        </div>
        <button type="button" class="btn btn-primary col-1" (click)="loadData(itemName)">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <!-- <div class="row" *ngIf="favouriteItem === null">
        <div class="col">
          <div class="mx-auto text-center mt-4 mb-4">
            <app-loading></app-loading>
          </div>
        </div>
      </div> -->
      <div class="empty-card text-center py-5" *ngIf="!favouriteItem?.length && !loading">
        <h3><b> You have no favorite item. </b></h3>
      </div>
      <div class="row" *ngIf="isVerifiedEmail; else notVerified">

        <div *ngFor="let nft of favouriteItem" class="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
          <app-digi-card class="c-card-container" [data]="nft"></app-digi-card>
        </div>
      </div>
      <ng-template #notVerified>
        <div class="row">
          <div class="col-lg-12">
            <div class="mx-auto text-center">
              <h1 class="mt-1">Please verify your email address before continuing, <b>it may be in your spam/junk folder.</b></h1>
            </div>
          </div>
        </div>
    </ng-template>
    </div>

    <div class="row center p-t-10">
      <div class="col-lg-12">
        <!-- <div class="d-flex justify-content-between p-2" *ngIf="favoriteDataSize >= 10">
          <ngb-pagination class="mx-auto" [collectionSize]="favoriteDataSize" [pageSize]="favoritePerPage"
            [(page)]="currentPage" (pageChange)="onPageChange(currentPage)"></ngb-pagination>
        </div> -->
        <button *ngIf="loading" class="btn btn-primary mt-4 mb-4">. . .</button>
        <button *ngIf="!loading && nextPage" class="btn btn-primary mt-4 mb-4" (click)="loadMore()">
          Load more
        </button>
      </div>

    </div>
  </div>
</div>

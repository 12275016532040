import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(10000, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class UserProfileComponent implements OnInit {
  isLoggedIn;
  currentUser;
  isVerifiedEmail;
  isAdmin;
  userId;
  isYourProfile;
  user;
  loading: boolean = true;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private readonly route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          if(this.currentUser && this.userId === this.currentUser.id) {
            this.isYourProfile = true;
          }
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
    this.route.params.subscribe((queryParams) => {
      this.userId = queryParams.id;
    });
    this.loadData();
  }
  loadData() {
    this.userService.getUserById(this.userId).subscribe((res) => {
      console.log(res);
      this.user = res;
      this.loading = false;
    });
  }
}

<a *ngIf="data" class="c-card">
  <div class="d-flex flex-row items-center p-2">
    <div class="c-collection__circle">
      <img
        *ngIf="data.image"
        width="100%"
        height="100%"
        [src]="data.image"
        alt=""
      />
      <img
        *ngIf="!data.image"
        width="100%"
        height="100%"
        src="/assets/images/home/home-1.png"
        alt=""
      />
    </div>
    <span *ngIf="data.name" class="c-card__info">{{
      data.name | truncate: [10, "..."]
    }}</span>
  </div>
  <figure class="c-card__figure">
    <img
      *ngIf="data.image"
      class="c-card__figure__video"
      [src]="data.image"
      alt="image"
    />
    <img
      *ngIf="!data.image"
      width="100%"
      height="100%"
      src="/assets/images/home/home-1.png"
      alt=""
    />
  </figure>
  <div class="c-card__body">
    <div class="c-card__top">
      <h1 class="c-card__title">
        <span>{{ data.name }}</span>
      </h1>
      <div class="c-card__price" *ngIf="data.price">
        <span class="mr-2">${{ data.price }} </span>
      </div>
    </div>
    <div class="c-card__description">
      <p *ngIf="data && data.description">
        {{ data.description | truncate: [120, "..."] }}
      </p>
    </div>
    <div *ngIf="data && !data.description" class="c-card__description">
      <p>No description has been created for this NFT.</p>
    </div>
  </div>
</a>

<div class="container p-b-40 page-top">
  <div class="row justify-center">
    <div class="col-9 p-t-10 p-b-6">
      <div class="form-bg">
        <h2 class="bold text-blue mb-4">Register</h2>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="firstName">Name *</label>
            <input
              type="text"
              formControlName="name"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
            />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
            <div class="error-msg">
              {{ validationError?.name ? validationError?.name[0] : "" }}
            </div>
          </div>
          <div class="form-group">
            <label for="firstName">Username *</label>
            <input
              type="text"
              formControlName="username"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
            />
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="username.errors.required">Username is required</div>
            </div>
            <div class="error-msg">
              {{ validationError?.username ? validationError?.username[0] : "" }}
            </div>
          </div>
          <div class="form-group">
            <label for="avatar">Avatar *</label>
            <input
              (change)="handleFileInput($event.target.files)"
              accept=".png, .jpg, .jpeg"
              type="file"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.avatar.errors }"
            />
            <div *ngIf="submitted && f.avatar.errors" class="invalid-feedback">
              <div *ngIf="f.avatar.errors.required">Avatar is required</div>
            </div>
            <div class="error-msg">
              {{ validationError?.avatar ? validationError?.avatar[0] : "" }}
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email *</label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">email is required</div>
            </div>
            <div class="error-msg">
              {{ validationError?.email ? validationError?.email[0] : "" }}
            </div>
          </div>
          <div class="form-group">
            <label for="password">Password *</label>
            <input
              [type]="passwordFieldTextType ? 'text' : 'password'"
              formControlName="password"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <div
              class="input-group-append"
              (click)="passwordToggleFieldTextType()"
            >
              <span class="input-group-text" *ngIf="!passwordFieldTextType">
                <i class="fa fa-eye-slash"></i>
              </span>
              <span class="input-group-text" *ngIf="passwordFieldTextType">
                <i class="fa fa-eye"></i>
              </span>
            </div>
            <div
              *ngIf="
                (submitted && f.password.errors) ||
                (f.password.touched && f.password.errors)
              "
              class="invalid-feedback"
            >
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">
                Password must be at least 6 characters
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="confirm_password">Confirm Password *</label>
            <input
              [type]="fieldTextType ? 'text' : 'password'"
              formControlName="confirm_password"
              class="form-control"
              [ngClass]="{
                'is-invalid': submitted && f.confirm_password.errors
              }"
            />
            <div class="input-group-append" (click)="toggleFieldTextType()">
              <span class="input-group-text" *ngIf="!fieldTextType">
                <i class="fa fa-eye-slash"></i>
              </span>
              <span class="input-group-text" *ngIf="fieldTextType">
                <i class="fa fa-eye"></i>
              </span>
            </div>
            <div
              *ngIf="
                (submitted && f.confirm_password.errors) ||
                (f.confirm_password.touched && f.confirm_password.invalid)
              "
              class="invalid-feedback"
            >
              <div *ngIf="f.confirm_password.errors.required">
                Password is required
              </div>
              <div *ngIf="f.confirm_password.errors.confirmedValidator">
                Password and Confirm Password must be match.
              </div>
            </div>
          </div>

         <!--  <div class="form-group">
            <label for="timezone">Time Zone * </label>
            <ng-moment-timezone-picker
              formControlName="timezone"
              [customPlaceholderText]="
                f.timezone.value === null ? 'Select Time Zone' : ''
              "
              [ngClass]="{ 'is-invalid': submitted && f.timezone.errors }"
            >
            </ng-moment-timezone-picker>
            <div
              *ngIf="submitted && f.timezone.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.timezone.errors.required">timezone is required</div>
            </div>
            <div class="error-msg">
              {{
                validationError?.timezone ? validationError?.timezone[0] : ""
              }}
            </div>
          </div> -->
          <div class="form-group">
            <button
              [disabled]="loading"
              class="btn btn-primary"
              [ngClass]="{ 'form-validate': !registerForm.valid }"
            >
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"
              ></span>
              Register
            </button>
            <a routerLink="/login" class="btn btn-link">Cancel</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  headerName = 'X-XSRF-TOKEN';
  constructor(private tokenService: HttpXsrfTokenExtractor) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

     request = request.clone({
      withCredentials: true
    });
    
    if (request.method === 'GET' || request.method === 'HEAD') {
      return next.handle(request);
    }

    const token = this.tokenService.getToken() as string;
    console.log(token);
    
    
    // Be careful not to overwrite an existing header of the same name.
    if (token !== null && !request.headers.has(this.headerName)) {
      request = request.clone({headers: request.headers.set(this.headerName, token)});
    }
  
//    request = request.clone({
//      withCredentials: true
//    });

    return next.handle(request);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../services/alert.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/Authentication.service';
import { CollectionsService } from '../../services/collections.service';
import { CategoryService } from '../../services/category/category.service';
import { Category } from '../../models/category';
import { LoginResponse, User } from '../../models/user';

@Component({
  selector: 'app-collections',
  templateUrl: './create-collection.component.html',
  styleUrls: ['./create-collection.component.scss'],
})
export class CreateCollectionComponent implements OnInit {
  id: number;
  currentUser: User;
  returnUrl: string;
  collectionForm: FormGroup;
  loading = false;
  submitted: boolean = false;
  categoryList: Category[];
  collectionUser: string;
  isLoggedIn;
  isVerifiedEmail;
  isAdmin;

  constructor(
    private formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private collectionsService: CollectionsService,
    private userService: UserService,
    private categoryService: CategoryService,
    private alertService: AlertService
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
  }

  ngOnInit() {
    this.collectionForm = this.formBuilder.group({
      name: [null, Validators.required],
      logo_image: ['', Validators.required],
      banner_image: ['', Validators.required],
      description: ['', Validators.required],
      private: ['', Validators.required],
      external_url: [''],
      // categories: [''],
      website: [''],
      discord: [''],
      twitter: [''],
      instagram: [''],
      medium: [''],
      telegram: [''],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/my-collections';
    this.getCategoryList();
  }

  async getCategoryList() {
    this.categoryList = await this.collectionsService.getAllCollectionCategories();
  }

  get f() {
    return this.collectionForm.controls;
  }

  loadData() {
    this.userService.getUserById(this.id);
  }

  onCollectionSubmit() {
    this.submitted = true;
    this.alertService.clear();
    if (this.collectionForm.invalid) {
      return;
    }

    this.loading = true;
    this.collectionsService
      .createCollection(this.collectionForm.value)
      .then((res) => {
        console.log(res);
        this.router.navigate(['/my-collections']);
        this.alertService.success('Collection has been created successfully', true);
      }).catch((error) => {
        console.log(error);
        this.alertService.error(error?.errors?.name[0]);
        this.loading = false;
      })
  }


  handleFileInput(files: FileList, name: string) {
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.collectionForm.get(name).setValue(e.target.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
}

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: 'app-off-chain-card',
  templateUrl: './off-chain-card.component.html',
  styleUrls: ['./off-chain-card.component.scss'],
})
export class OffChainCardComponent implements OnInit {
  @Input() data: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private routerLink: Router,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    console.log(this.data);
  }
}

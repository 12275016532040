<div class="c-profile">
  <div
    class="c-profile__hero"
    [ngStyle]="{
      'background-image':
        collectionData && collectionData.banner_path
          ? 'url(' + collectionData.banner_path + ')'
          : ''
    }"
  >
    <div class="c-profile__hero-overlay"></div>
    <span
      *ngIf="collectionData && collectionData.logo_path"
      class="c-profile__avatar"
      [ngStyle]="{
        'background-image': 'url(' + collectionData.logo_path + ')'
      }"
    ></span>
    <span
      *ngIf="!collectionData || !collectionData.logo_path"
      class="c-profile__avatar"
    ></span>
    <div *ngIf="isYourProfile" class="c-profile__edit">
      <button
        id="editDescriptionModal"
        data-toggle="modal"
        data-target="#addDescriptionModal"
        class="btn btn-primary mb-4"
        #editDescriptionModal
      >
        <span>Edit</span>
      </button>
    </div>
  </div>
  <div class="row p-t-40">
    <div class="container p-t-40 p-b-40">
      <div class="col-lg-6 m-auto center">
        <div class="c-profile__title" *ngIf="isYourProfile">
          <div
            *ngIf="verifiedAddress"
            class="c-profile__creator icon-creator"
          ></div>
          <h1>My Profile</h1>
        </div>
        <div class="c-profile__address">
          <div>
            <span *ngIf="!is20Collection">{{ displayAddress }}</span>
            <span *ngIf="is20Collection">{{ collectionData?.name }}</span>
          </div>
        </div>
        <div class="c-profile__title" *ngIf="collectionData && !isYourProfile">
          <div
            *ngIf="verifiedAddress"
            class="c-profile__creator icon-creator"
          ></div>
          <h2>{{ "by " + collectionData.user.data.name }}</h2>
        </div>
        <br />
        <div>
          <div class="profile-description" *ngIf="collectionData && !isYourProfile"
            [ngClass]="{'expand-description': isArrowClose}">
          <div *ngIf="verifiedAddress" class="c-profile__creator icon-creator"></div>
          <p>{{collectionData.description}}</p>
          <div [ngClass]="{'description-shadow': !isArrowClose && showToggleArrow}"></div>
        </div>
          <div class="toggle-icon" *ngIf="isArrowClose" (click) = "onClickArrow()">
            <i class="fa fa-caret-down"></i>
          </div>
          <div
            class="toggle-icon"
            *ngIf="!isArrowClose && showToggleArrow"
            (click)="onClickArrow()"
          >
            <i class="fa fa-caret-up"></i>
          </div>
        </div>
        <div class="c-profile__social">
          <div class="social" *ngIf="profile">
            <a
              [href]="'https://twitter.com/' + profile.twitter"
              *ngIf="profile.twitter"
              target="_blank"
              ><i class="icon fab fa-twitter"></i
            ></a>

            <a
              [href]="'https://twitch.tv/' + profile.twitch"
              *ngIf="profile.twitch"
              target="_blank"
              ><i class="icon fab fa-twitch"></i
            ></a>

            <a
              [href]="'https://www.tiktok.com/' + profile.tiktok"
              *ngIf="profile.tiktok"
              target="_blank"
              ><i class="icon fab fa-tiktok"></i
            ></a>

            <a
              [href]="'https://instagram.com/' + profile.instagram"
              *ngIf="profile.instagram"
              target="_blank"
              ><i class="icon fab fa-instagram"></i
            ></a>

            <a [href]="profile.website" *ngIf="profile.website" target="_blank"
              ><i class="icon fa fa-desktop"></i
            ></a>
          </div>
        </div>
        <div
          *ngIf="profile && profile.description"
          class="c-profile__description"
        >
          <p>
            {{ profile.description }}
          </p>
        </div>
        <div *ngIf="!is20Collection" class="c-profile__details">
          <div class="c-profile__detail-item">
            <span *ngIf="myCardsFiltered && myCardsFiltered.length >= 1">{{
              myCardsFiltered.length
            }}</span>
            <span *ngIf="myCardsFiltered && myCardsFiltered.length < 1">0</span>
            <span *ngIf="!myCardsFiltered">...</span>
            <span>Items</span>
          </div>
        </div>
        <div *ngIf="is20Collection" class="c-profile__details">
          <div class="c-profile__detail-item">
            <span>{{ collectionItems?.length }}</span>
            <span>Items</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-profile-container">
    <div class="app-filter">
      <div
        class="app-filter-inner filter-scroll"
        [ngClass]="{
          'alert-msg-show': showAlertMessage,
          'filter-sidebar-close': isFilterSidebarClose
        }"
      >
        <div class="d-flex c-filter__item">
          <div
            style="display: flex; justify-content: center;"
            [ngClass]="{ 'd-none': isFilterSidebarClose }"
          >
            <div class="icon filter-icon">
              <i class="fas fa-filter"></i>
            </div>
          </div>
          <h3
            [ngClass]="{ 'd-none': isFilterSidebarClose }"
            class="c-filter__title section-title bold mb-0 mt-2 flex-1-class"
          >
            Filter Collectibles
          </h3>
          <div
            [ngClass]="{ 'arrow-closed': isFilterSidebarClose }"
            class="filter-arrow"
            *ngIf="isFilterSidebarClose"
            (click)="onClickFilterSidebarArrow()"
          >
            <i class="fas fa-arrow-right"></i>
          </div>
          <div
            class="filter-arrow"
            *ngIf="!isFilterSidebarClose"
            (click)="onClickFilterSidebarArrow()"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
          <!-- <div [ngClass]="{'d-none': isFilterSidebarClose}" (click)="clearAllFiltersClick()" class="clear-all-filters mt-4">
            Clear Filters
          </div> -->
        </div>
        <ul [ngClass]="{ 'd-none': isFilterSidebarClose }" class="c-filter__list">
          <li class="c-filter__item">
            <div
              class="c-filter__title"
              (click)="toggleMenu($event, filterenum.Status)"
            >
              <span #status class="title-span">Status</span>
              <span (click)="arrowToggleMenu(statusArrow)" class="chevron">
                <div *ngIf="!isOpen(filterenum.Status)">
                  <i class="fas fa-chevron-down"></i>
                </div>
                <div *ngIf="isOpen(filterenum.Status)">
                  <i class="fas fa-chevron-up"></i>
                </div>
              </span>
            </div>
            <div class="c-filters__list" #statusArrow>
              <div
                #allData
                id="allData"
                (click)="clickAllDataFilter(allData)"
                class="btn btn-primary mb-2 w-100"
              >
                All Data
              </div>
              <div
                #onChain
                id="onChain"
                (click)="clickOnChainStatusFilter(onChain)"
                class="btn btn-primary mb-2 w-100"
              >
                On-chain
              </div>
              <div
                #offChain
                id="offChain"
                (click)="clickOffChainStatusFilter(offChain)"
                class="btn btn-primary mb-2 w-100"
              >
                Off-chain
              </div>
              <!-- <div
                (click)="clickApprovedStatusFilter()"
                class="btn btn-primary mb-2 w-100"
              >
                Approved
              </div> -->
              <div
                #auction
                id="auction"
                (click)="clickAuctionStatusFilter(auction)"
                class="btn btn-primary mb-2 w-100"
              >
                Auction
              </div>
              <div
                #buyNow
                id="buyNow"
                (click)="clickBuyNowStatusFilter(buyNow)"
                class="btn btn-primary mb-2 w-100"
              >
                Buy Now
              </div>
              <div
                #latest
                id="latest"
                (click)="applyLatestFilter(latest)"
                class="btn btn-primary w-100"
              >
                Latest
              </div>
            </div>
          </li>
          <li class="c-filter__item">
            <div
              class="c-filter__title"
              (click)="toggleMenu($event, filterenum.Price)"
            >
              <span class="title-span">Price</span>
              <span (click)="arrowToggleMenu(priceArrow)" class="chevron">
                <div *ngIf="!isOpen(filterenum.Price)">
                  <i class="fas fa-chevron-down"></i>
                </div>
                <div *ngIf="isOpen(filterenum.Price)">
                  <i class="fas fa-chevron-up"></i>
                </div>
              </span>
            </div>
            <div class="c-filters__list price" #priceArrow>
              <p class="ml-auto mr-2">Currency: $/USD</p>
              <p class="text-center">Please select a price range below.</p>
              <div class="d-flex flex-row items-center mb-4">
                <input
                  [formControl]="priceFilter.get('minPrice')"
                  class="c-filters__input"
                  type="number"
                  placeholder="Min"
                  min="0"
                  (keypress)="onKeyPress($event)"
                />
                <p class="mb-0 ml-2 mr-2">to</p>
                <input
                  [formControl]="priceFilter.get('maxPrice')"
                  class="c-filters__input"
                  type="number"
                  placeholder="Max"
                  min="0"
                  (keypress)="onKeyPress($event)"
                />
              </div>
              <div
                class="text-center text-danger"
                style="margin-top: -15px;"
                *ngIf="value"
              >
                <h6 class="pt-0">
                  <small>Minimum must be less than the maximum!</small>
                </h6>
              </div>
              <button [disabled]="!price" class="btn btn-primary" [ngClass]="{'btnPrice' : price}" (click)="applyPriceFilter()">
                Apply
              </button>
            </div>
          </li>
          <li class="c-filter__item">
            <div
              class="c-filter__title"
              (click)="toggleMenu($event, filterenum.Category)"
            >
              <span class="title-span">Categories</span>
    
              <span (click)="arrowToggleMenu(categoryArrow)" class="chevron">
                <div *ngIf="!isOpen(filterenum.Category)">
                  <i class="fas fa-chevron-down"></i>
                </div>
                <div *ngIf="isOpen(filterenum.Category)">
                  <i class="fas fa-chevron-up"></i>
                </div>
              </span>
            </div>
            <ul class="c-filters__list" style="font-weight: 100;" #categoryArrow>
              <div class="c-search mb-4">
                <input
                  id="categorySearchFilter"
                  for="categorySearchFilter"
                  (keypress)="categorySearchFilter($event)"
                  class="c-search-bar"
                  type="search"
                  name="categorySearchFilter"
                  placeholder="Filter"
                  aria-label="Search through collections."
                />
                <div class="c-search-button">
                  <span class="icon search c-search__svg"></span>
                </div>
                <button
                  (click)="categorySearchFilter($event)"
                  class="c-search-submit"
                >
                  Go
                </button>
              </div>
              <!--  <span
                (click)="clearCategoryFilter()"
                class="c-filter-item-title category-clear-filter-btn"
                >Clear Filter</span
              > -->
              <li
                class="c-filter__item-inner"
                [ngClass]="{ 'select-item': category?.selected }"
                *ngFor="let category of collectionsCategories; let i = index"
                (click)="applyCategoryFilter(category)"
              >
                <div class="d-flex">
                  <div class="c-filter__circle">
                    <img
                      *ngIf="!category?.picture"
                      width="100%"
                      height="100%"
                      src="/assets/images/home/home-1.png"
                      alt=""
                    />
                  </div>
                  <span class="c-filter-item-title">{{ category.name }}</span>
                </div>
              </li>
            </ul>
          </li>
          <li class="c-filter__item">
            <div
              class="c-filter__title"
              (click)="toggleMenu($event, filterenum.Blockchain)"
            >
              <span class="title-span">Blockchains</span>
              <span (click)="arrowToggleMenu(blockChainArrow)" class="chevron">
                <div *ngIf="!isOpen(filterenum.Blockchain)">
                  <i class="fas fa-chevron-down"></i>
                </div>
                <div *ngIf="isOpen(filterenum.Blockchain)">
                  <i class="fas fa-chevron-up"></i>
                </div>
              </span>
            </div>
            <ul class="c-filters__list" #blockChainArrow>
              <li (click)="changeFilter('MATIC')" class="c-filter__item-inner">
                <div class="c-filter__circle blockchains">
                  <span class="c-card__safe icon polygon"></span>
                </div>
                <span>Polygon</span>
              </li>
              <li (click)="changeFilter('ETH')" class="c-filter__item-inner">
                <div class="c-filter__circle blockchains">
                  <span class="c-card__safe icon ethereum"></span>
                </div>
                <span>Ethereum</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-body">
      <div class="pl-4 pr-4">
        <div class="loading-component" *ngIf="collectionItems === null">
          <app-loading></app-loading>
        </div>
        <div class="row">
          <div class="col-sm-12 filter-alerts">
            <div class="alert-box-item" *ngFor="let alert of alerts" >
              <ngb-alert [type]="alert.type"  (closed)="close(alert)">{{ alert.message }}</ngb-alert>
            </div>
            <div class="alert-box-item" *ngIf="alerts.length != 0">
              <ngb-alert type="light" (closed)="clearAllFiltersClick()">
                Clear All
              </ngb-alert>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="collectionItems !== null">
          <div class="col-sm-12">
            <p>{{ collectionItems?.length }} results</p>
          </div>
        </div>
        <div
          class="row row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6"
          *ngIf="collectionItems !== null"
        >
          <div
            class="alert alert-primary w-100 text-center"
            role="alert"
            *ngIf="!collectionItems?.length"
          >
            No item to display
          </div>
          <div *ngFor="let item of collectionItems" class="col">
            <app-digi-card class="c-card-container" [data]="item"></app-digi-card>
          </div>
        </div>
      </div>
      <button (click)="toggleFilter()" class="c-filter-button">Filter</button>
    </div>
  </div>

  <div class="container mt-4 container-profile-fluid" *ngIf="isYourProfile">
    <div class="row">
      <div class="col-12 p-t-10 p-b-6">
        <span class="section-title"
          ><span class="icon fire"></span>Auctions Pending
        </span>
      </div>
      <div class="solid mt-4 mb-4"></div>
    </div>
    <div class="p-b-40">
      <div class="row" *ngIf="pendingAuctions == null">
        <div class="col">
          <div class="mx-auto text-center mt-4 mb-4">
            <app-loading></app-loading>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="pendingAuctions && pendingAuctions.length == 0">
        <div class="col">
          <div class="mx-auto text-center mt-4 mb-4">
            <b>No pending auctions to claim</b>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="pendingAuctions">
        <div
          *ngFor="let nft of pendingAuctions"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
        >
          <app-moralis-card [data]="nft"></app-moralis-card>
          <button
            (click)="claim(nft.auctionId, nft.network)"
            class="c-card__button btn btn-primary full-width mt-2"
            *ngIf="nft.sold"
          >
            <span *ngIf="nft.seller">{{
              loading ? "..." : "Claim " + stableSymbol
            }}</span>
            <span *ngIf="!nft.seller">{{ loading ? "..." : "Claim NFT" }}</span>
          </button>
          <button
            (click)="cancel(nft.auctionId, nft.network)"
            class="c-card__button btn btn-primary full-width mt-2"
            *ngIf="!nft.sold"
          >
            <span>{{ loading ? "..." : "Cancel" }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="container mt-4 container-profile-fluid"
    *ngIf="
      pendingTransfersFromMatic &&
      isYourProfile &&
      pendingTransfersFromMatic.length > 0
    "
  >
    <div class="row">
      <div class="col-12 p-t-10 p-b-6">
        <span class="section-title"
          ><span class="icon fire"></span>Claim transferred from Matic</span
        >
      </div>
      <div class="solid mt-4 mb-4"></div>
    </div>
    <div class="p-b-40">
      <div class="row">
        <div
          *ngFor="let nft of pendingTransfersFromMatic"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-12 p-t-40"
        >
          <app-moralis-card [data]="nft"></app-moralis-card>
          <button
            (click)="completeTransferFromMatic(nft.hash)"
            class="btn btn-primary mt-4"
          >
            <span>{{ loading ? "..." : "Claim token " + nft.tokenId }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="addTokenModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="searchModalLabel"
    aria-hidden="true"
    #addTokenModal
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="searchModalLabel">NFT Address</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 p-t-15">
                <form class="user">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control form-control-user"
                      placeholder="0x0000000000000000"
                      [(ngModel)]="inputAddress"
                      (ngModelChange)="onChangeInputAddress()"
                      name="nftName"
                    />
                  </div>
                </form>
              </div>
              <div class="col-lg-12 center p-t-15 p-b-10">
                <span
                  >Add the NFTs that you have purchased using other platforms
                  such as OpenSea</span
                >
              </div>
            </div>
            <div class="row p-t-15 p-b-10 center justify-center">
              <div class="col-lg-12">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="addToken()"
                >
                  Add {{ tokenName }} Token
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addDescriptionModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addDescription"
  aria-hidden="true"
  #addTokenModal
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="searchModalLabel">Edit profile</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <form class="user row">
              <div *ngIf="profile" class="col-lg-12 form-group">
                <label for="profile_name" class="block">Profile Username</label>
                <input
                  [(ngModel)]="profile.username"
                  name="profile_name"
                  id=""
                  cols="30"
                  rows="10"
                />
              </div>
              <div *ngIf="profile" class="col-lg-12 form-group">
                <label for="profile_description">Description</label>
                <textarea
                  [(ngModel)]="profile.description"
                  name="profile_description"
                  id="description"
                ></textarea>
              </div>
              <div *ngIf="profile" class="col-lg-6 form-group">
                <label for="profile_email" class="block">Email</label>
                <input
                  [(ngModel)]="profile.email"
                  name="profile_email"
                  id="profile_email"
                  cols="30"
                  rows="10"
                />
              </div>
              <div *ngIf="profile" class="col-lg-6 form-group">
                <label for="profile_twitter" class="block">Twitter</label>
                <input
                  [(ngModel)]="profile.twitter"
                  name="profile_twitter"
                  id="profile_twitter"
                  cols="30"
                  rows="10"
                />
              </div>
              <div *ngIf="profile" class="col-lg-6 form-group">
                <label for="profile_instagram" class="block">Instagram</label>
                <input
                  [(ngModel)]="profile.instagram"
                  name="profile_instagram"
                  id="profile_instagram"
                  cols="30"
                  rows="10"
                />
              </div>
              <div *ngIf="profile" class="col-lg-6 form-group">
                <label for="profile_twitch" class="block">Twitch</label>
                <input
                  [(ngModel)]="profile.twitch"
                  name="profile_twitch"
                  id="profile_twitch"
                  cols="30"
                  rows="10"
                />
              </div>
              <div *ngIf="profile" class="col-lg-6 form-group">
                <label for="profile_tiktok" class="block">TikTok</label>
                <input
                  [(ngModel)]="profile.tiktok"
                  name="profile_tiktok"
                  id="profile_tiktok"
                  cols="30"
                  rows="10"
                />
              </div>
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-6">
                    <div *ngIf="profile" class="form-group text-center">
                      <label for="">Profile Hero Image</label>
                      <ngx-file-drop
                        accept=".png,.jpg,.jpeg,.mp4"
                        multiple="false"
                        dropZoneLabel="Drop files here"
                        (onFileDrop)="dropped($event, 'hero_picture')"
                      >
                        <ng-template
                          ngx-file-drop-content-tmp
                          let-openFileSelector="openFileSelector"
                        >
                          <div class="row">
                            <div class="col-lg-12 p-t-10 text-center">
                              <img
                                *ngIf="!imageUrlPreviewHero"
                                class="avatar-img"
                                [src]="
                                  !this.loadFiles.hero_picture
                                    ? profile.hero_picture
                                    : this.loadFiles.hero_picture
                                "
                                alt=""
                              />
                              <img
                                *ngIf="imageUrlPreviewHero"
                                class="avatar-img"
                                [src]="imageUrlPreviewHero"
                                alt=""
                              />
                            </div>
                            <div class="col-lg-12 p-t-10">
                              <button
                                id="hero_picture"
                                type="button"
                                class="btn btn-primary"
                                (click)="openFileSelector()"
                              >
                                Browse Files
                              </button>
                            </div>
                          </div>
                        </ng-template>
                      </ngx-file-drop>
                    </div>
                  </div>
                  <div class="col-6">
                    <div *ngIf="profile" class="form-group text-center">
                      <label for="">Profile Avatar</label>
                      <ngx-file-drop
                        accept=".png,.jpg,.jpeg,.mp4"
                        multiple="false"
                        dropZoneLabel="Drop files here"
                        (onFileDrop)="dropped($event, 'picture')"
                      >
                        <ng-template
                          ngx-file-drop-content-tmp
                          let-openFileSelector="openFileSelector"
                        >
                          <div class="row">
                            <div class="col-lg-12 p-t-10 text-center">
                              <img
                                *ngIf="!imageUrlPreviewIcon"
                                class="avatar-img"
                                [src]="
                                  !this.loadFiles.picture
                                    ? profile.picture
                                    : this.loadFiles.picture
                                "
                                alt=""
                              />
                              <img
                                *ngIf="imageUrlPreviewIcon"
                                class="avatar-img"
                                [src]="imageUrlPreviewIcon"
                                alt=""
                              />
                            </div>
                            <div class="col-lg-12 p-t-10">
                              <button
                                id="picture"
                                type="button"
                                class="btn btn-primary"
                                (click)="openFileSelector()"
                              >
                                Browse Files
                              </button>
                            </div>
                          </div>
                        </ng-template>
                      </ngx-file-drop>
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      class="btn btn-primary w-100"
                      (click)="updateProfile()"
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--
      <div class="row p-t-15 p-b-10 center justify-center">
        <div class="col-lg-12">
          <button
            type="button"
            class="btn btn-primary"
            (click)="updateProfile()"
          >
            {{ descriptionLoading ? ". . ." : "Save" }}
          </button>
        </div>
      </div>
        -->
      </div>
    </div>
  </div>
</div>

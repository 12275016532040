import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionsService } from 'src/app/services/collections.service';
import { VerifiedWalletsService } from 'src/app/services/verified-wallets.service';
import { OffchainService } from 'src/app/services/offchain.service';
import { Collection } from 'src/app/types/collection.type';
import { FormControl, FormGroup } from '@angular/forms';
import { DigiCard } from 'src/app/types/digi-card.types';
import { Alert } from '../explorer/Alert';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { AlertService } from 'src/app/services/alert.service';
import { WalletService } from 'src/app/services/wallet.service';
import { UserService } from 'src/app/services/user.service';

interface Filter {
  isOpen: boolean;
  name: string;
}

enum FilterEnum {
  Status = 'Status',
  Price = 'Price',
  OffChain = 'Off-chain Collections',
  OnChain = 'On-chain Collections',
  Category = 'Categories',
  Blockchain = 'Blockchains',
}
@Component({
  selector: 'app-collection',
  templateUrl: './my-collection.component.html',
  styleUrls: ['./my-collection.component.scss'],
})
export class MyCollectionComponent implements OnInit {
  id: number;
  loading = false;
  currentOffset = 0;
  endReached = false;
  collection: Collection;
  nftListMatic: any;
  nftListEth: any;
  maticNfts = [];
  ethNFTs = [];
  value: boolean = false;
  isCategoryFilter: boolean = false;
  nftArray = [];
  unfilteredNftList: DigiCard[] = null;
  currentUser: any;
  nftListFiltered: DigiCard[] = null;
  mixedCollectionItems: DigiCard[] = null;
  isFilterSidebarClose: boolean = false;
  filterenum = FilterEnum;
  all20CollectionItems = [];
  collectionsCategories: any = [];
  offChainCollections: any = [];
  onChainCollections: any = [];
  priceFilter = new FormGroup({
    minPrice: new FormControl(),
    maxPrice: new FormControl(),
  });
  categoryFilter = '';
  offChainCollectionFilter = '';
  offChainFilter = false;
  approvedStatusFilter = false;
  auctionStatusFilter = false;
  buyNowStatusFilter = false;
  onChainFilter = false;
  collectionItems: any = [];
  showAlertMessage: boolean = false;
  onChainCollectionItems: any = [];
  filterCollectionItems: any = [];
  isCategoryFilterSearched: boolean = false;
  public nextPage;
  public currentPage: number = 0;
  alerts: Alert[] = [];
  isArrowClose: boolean = false;
  // userId: number;
  showToggleArrow: boolean = false;
  isLoggedIn;
  isVerifiedEmail;
  isAdmin;
  collectionUserId;

  readonly limit = 10005;

  public get price(): boolean {
    return (
      this.priceFilter.value.minPrice !== null &&
      this.priceFilter.value.maxPrice !== null
    );
  }

  constructor(
    private readonly httpclient: HttpClient,
    private readonly wallet: WalletService,
    private collectionsService: CollectionsService,
    private readonly route: ActivatedRoute,
    private readonly verifieds: VerifiedWalletsService,
    private readonly offchain: OffchainService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private userService: UserService
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
    this.route.params.subscribe((queryParams) => {
      // this.userId = parseInt(queryParams.userId);
      //  this.userService.verifyUser(this.userId);
      this.id = queryParams.id;
    });
  }

  ngOnInit(): void {
    this.loadData();
    this.getAllCollectionCategories();
    // this.getOnChainCollections();
    this.getOffChainCollections();
    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        this.loadData();
      });
    }
  }

  async loadData(): Promise<void> {
    console.log('Loading data....');
    this.currentOffset = 0;
    this.endReached = false;
    this.getCollection();
    this.getCollectionItems();
  }

  async categorySearchFilter(e) {
    const inputValue = (
      document.getElementById('categorySearchFilter') as HTMLInputElement
    ).value;
    console.log(inputValue);
    if (inputValue === '' && !this.isCategoryFilterSearched) {
      return;
    } else if (inputValue !== '') {
      this.isCategoryFilterSearched = true;
    } else if (inputValue === '' && this.isCategoryFilterSearched) {
      this.collectionsCategories = [];
      this.getAllCollectionCategories();
      return;
    }
    if (e.code === 'Enter' || e.type == 'click') {
      const searchResult = this.collectionsCategories.filter((category) => {
        return category.name.toLowerCase().match(inputValue.toLowerCase());
      });
      setTimeout(async () => {
        this.collectionsCategories = searchResult;
        console.log(this.collectionsCategories);
      }, 100);
    }
  }

  toggleFilter() {
    document.body.classList.toggle('c-filter--active');
  }

  onClickArrow() {
    this.isArrowClose = !this.isArrowClose;
  }

  toggleMenu(event, name: string) {
    this.onToggleDropdown(name);
    event.preventDefault();
    event.stopPropagation();
    if (event.target.classList.contains('title-span')) {
      event.target.parentNode.nextElementSibling.classList.toggle('active');
    } else if (event.target.classList.contains('c-filter__title')) {
      event.target.nextElementSibling.classList.toggle('active');
    }
  }

  onToggleDropdown(name: string) {
    var index = this.filters.findIndex((item) => item.name === name);
    if (index > -1) {
      this.filters[index].isOpen = !this.filters[index].isOpen;
    }
  }

  applyLatestFilter(element: Element) {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    this.collectionsService
      .getLatestSellerCollectionItems(this.id)
      .subscribe((res: any) => {
        this.filterCollectionItems = res.data
          .slice()
          .sort(() => Math.random() - 0.5);
        const index = this.alerts.findIndex(
          (alert) => alert.action === 'latestFilter'
        );
        if (index === -1) {
          this.alerts.push({
            type: 'success',
            message: 'Latest',
            action: 'latestFilter',
          });
        } else {
          if (index > -1) {
            this.alerts.splice(index, 1);
            this.applyFilters();
          }
        }
      });
  }

  close(alert: Alert) {
    if (alert.action === 'clearCategoryFilter') {
      this.categoryFilter = null;
    }
    if (alert.action === 'allData') {
      var element = document.getElementById('allData');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'auction') {
      var element = document.getElementById('auction');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'buyNowFilter') {
      var element = document.getElementById('buyNow');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'latestFilter') {
      var element = document.getElementById('latest');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'onChainFilter') {
      this.onChainFilter = false;
      var element = document.getElementById('onChain');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'offChainFilter') {
      this.offChainFilter = false;
      var element = document.getElementById('offChain');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'approvedFilter') {
      this.approvedStatusFilter = false;
    }
    if (alert.action === 'priceFilter') {
      this.priceFilter.reset();
    }

    if (alert.action === 'offChainCollection') {
      this.offChainCollectionFilter = null;
    }
    if (alert.action === 'auctionFilter') {
      this.auctionStatusFilter = null;
    }

    if (alert.action === 'buyNowFilter') {
      this.buyNowStatusFilter = null;
    }
    if (alert.action === 'Category') {
      this.categoryFilter = null;
      this.collectionsCategories.forEach((item) => {
        item.selected = false;
      });
    }
    this.alerts.splice(this.alerts.indexOf(alert), 1);
    this.applyFilters();
  }

  clearCategoryFilter() {
    this.categoryFilter = '';
    const index = this.alerts.findIndex((alert) => alert.action === 'Category');
    if (index > -1) {
      this.alerts.splice(index, 1);
    }
    this.collectionsCategories.forEach((item) => {
      item.selected = false;
    });
    this.applyFilters();
  }

  filters: Filter[] = [
    { isOpen: false, name: this.filterenum.Status },
    { isOpen: false, name: this.filterenum.Price },
    { isOpen: false, name: this.filterenum.OffChain },
    { isOpen: false, name: this.filterenum.OnChain },
    { isOpen: false, name: this.filterenum.Category },
    { isOpen: false, name: this.filterenum.Blockchain },
  ];

  onClickFilterSidebarArrow() {
    this.isFilterSidebarClose = !this.isFilterSidebarClose;
  }

  arrowToggleMenu(element: Element) {
    if (element?.classList.contains('active')) {
      element.classList.remove('active');
    } else {
      element?.classList.add('active');
    }
  }
  isOpen(name: string) {
    const index = this.filters.findIndex((item) => item.name === name);
    if (index > -1) {
      return this.filters[index].isOpen;
    }
  }

  applyPriceFilter(): void {
    if (this.priceFilter.value.minPrice < this.priceFilter.value.maxPrice) {
      this.value = false;
      this.applyFilters();
      var message = '';
      var minPrice = this.priceFilter.value.minPrice;
      if (minPrice > -1) {
        message += minPrice + '$ ' + 'to';
      }

      var maxPrice = this.priceFilter.value.maxPrice;
      if (maxPrice != '') {
        message += ' ' + maxPrice + '$';
      }

      const index = this.alerts.findIndex(
        (alert) => alert.action === 'priceFilter'
      );
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: message,
          action: 'priceFilter',
        });
      } else {
        this.alerts[index].message = message;
      }
    } else {
      this.value = true;
      return;
    }
  }

  applyFilters(): void {
    this.filterCollectionItems = this.collectionItems;
    if (this.onChainFilter) {
      this.filterCollectionItems = this.onChainCollectionItems;
    }
    this.filterCollectionItems = this.filterCollectionItems.filter(
      (item) =>
        this.priceFilter.value.minPrice === undefined ||
        this.priceFilter.value.minPrice === null ||
        this.priceFilter.value.minPrice <= item.price
    );
    this.filterCollectionItems = this.filterCollectionItems.filter(
      (item) =>
        this.priceFilter.value.maxPrice === undefined ||
        this.priceFilter.value.maxPrice === null ||
        this.priceFilter.value.maxPrice >= item.price
    );
    this.filterCollectionItems = this.filterCollectionItems.filter(
      (item) =>
        this.categoryFilter === undefined ||
        this.categoryFilter === null ||
        this.categoryFilter === '' ||
        this.categoryFilter === item?.collection_item_type?.id
    );
    this.filterCollectionItems = this.filterCollectionItems.filter(
      (item) =>
        this.offChainCollectionFilter === undefined ||
        this.offChainCollectionFilter === null ||
        this.offChainCollectionFilter === '' ||
        this.offChainCollectionFilter === item?.collection_id
    );
    this.filterCollectionItems = this.filterCollectionItems.filter(
      (item) =>
        this.approvedStatusFilter === undefined ||
        this.approvedStatusFilter === null ||
        this.approvedStatusFilter === false ||
        item?.status === 'approved'
    );
    this.filterCollectionItems = this.filterCollectionItems.filter(
      (item) =>
        this.auctionStatusFilter === undefined ||
        this.auctionStatusFilter === null ||
        this.auctionStatusFilter === false ||
        item?.available_for_sale === 2
    );
    this.filterCollectionItems = this.filterCollectionItems.filter(
      (item) =>
        this.buyNowStatusFilter === undefined ||
        this.buyNowStatusFilter === null ||
        this.buyNowStatusFilter === false ||
        item?.available_for_sale === 1
    );
  }

  applyCategoryFilter(category) {
    var index = this.alerts.findIndex((alert) => alert.action === 'Category');
    if (this.categoryFilter === category?.id || this.categoryFilter === '') {
      this.isCategoryFilter = !this.isCategoryFilter;
    } else if (
      this.categoryFilter !== category?.id &&
      this.categoryFilter !== ''
    ) {
      this.isCategoryFilter = true;
    }
    this.highlightAppliedCategoryFilter(category);
    if (this.isCategoryFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: category.name,
          action: 'Category',
        });
      } else {
        this.alerts[index].message = category.name;
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
        if (this.categoryFilter !== category?.id) {
          this.categoryFilter = null;
          this.applyCategoryFilter(category);
        } else {
          this.clearCategoryFilter();
          return;
        }
      }
    }
    this.categoryFilter = category?.id;
    this.applyFilters();
  }
  highlightAppliedCategoryFilter(category) {
    this.collectionsCategories.forEach((item) => {
      if (item.id !== category.id) {
        item.selected = false;
      } else {
        item.selected = !item.selected;
      }
    });
  }

  clickAuctionStatusFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'auctionFilter'
    );
    this.auctionStatusFilter = !this.auctionStatusFilter;
    if (this.auctionStatusFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'Auction Status',
          action: 'auctionFilter',
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  clickBuyNowStatusFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'buyNowFilter'
    );
    this.buyNowStatusFilter = !this.buyNowStatusFilter;
    if (this.buyNowStatusFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'Buy Now Status',
          action: 'buyNowFilter',
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }
  onKeyPress(event: any) {
    return event.charCode === 8 || event.charCode === 0 || event.charCode === 13
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  changeFilter(item): void {
    this.loading = true;
    setTimeout(async () => {
      /* if (item === 'ALL') {
        this.nftList = this.nftList;
        this.loading = false;
        return;
      }
      if (item === 'MATIC') {
        const owner = await this.nft.owner(1);
        this.nftListFiltered = await this.nft.getMaticNfts();
        this.loading = false;
        return;
      }
      if (item === 'ETH') {
        this.nftListFiltered = await this.nft.getAllEthNfts();
        this.loading = false;
        return;
      } */
      /*  if (this.typeFilter === 'ALPHABETICAL') {
        this.nftListFiltered = [
          ...(await this.nftList),
          ...this.maticNfts,
        ];
        this.loading = false;
        return;
      } */
      /* if (item === 'PHYSICAL') {
        var filtered = [];
        for (var i = 0; i < this.nftList.length; i++) {
          if (this.nftList[i].physical === true) {
            filtered.push(this.nftList[i]);
          }
        }
        this.nftListFiltered = filtered;
        return;
      }
      if (item === 'FOR SALE') {
        var filtered = [];
        for (var i = 0; i < this.nftList.length; i++) {
          if (this.nftList[i].physical === true) {
            filtered.push(this.nftList[i]);
          }
        }
        this.nftListFiltered = filtered;
        return;
      }
      if (item === 'DIGITAL') {
        var filtered = [];
        for (var i = 0; i < this.nftList.length; i++) {
          if (this.nftList[i].physical === false) {
            filtered.push(this.nftList[i]);
          }
        }
        this.nftListFiltered = filtered;
        return;
      } */
    }, 200);
  }

  getAllCollectionCategories() {
    this.collectionsService.getAllCollectionCategories().then((res: any) => {
      res.forEach((element) => {
        this.collectionsCategories.push({
          name: element.name,
          id: element.id,
          selected: false,
        });
      });
    });
  }

  clickOffChainStatusFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    this.offChainFilter = !this.offChainFilter;
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'offChainFilter'
    );
    if (this.offChainFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'Off Chain',
          action: 'offChainFilter',
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  clickAllDataFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    const index = this.alerts.findIndex((alert) => alert.action === 'allData');
    if (index === -1) {
      this.alerts.push({
        type: 'success',
        message: 'All Data',
        action: 'allData',
      });
    }
    this.applyFilters();
  }
  clickOnChainStatusFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    this.onChainFilter = !this.onChainFilter;
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'onChainFilter'
    );
    if (this.onChainFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'On Chain',
          action: 'onChainFilter',
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  clearOffChainCollectionFilter() {
    this.offChainCollectionFilter = '';
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'offChainCollections'
    );
    if (index > -1) {
      this.alerts.splice(index, 1);
    }
    this.applyFilters();
  }

  applyOffChainCollectionFilter(collection): void {
    const index = this.alerts.findIndex(
      (alert) => alert.message === collection.name
    );
    if (index === -1) {
      this.alerts.push({
        type: 'success',
        message: collection.name,
        action: 'offChainCollections',
      });
    } else {
      this.alerts[index].message = collection.name;
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }

    this.offChainCollectionFilter = collection?.id;
    this.applyFilters();
  }

  clearAllFiltersClick() {
    this.filterCollectionItems = this.collectionItems;
    this.offChainFilter = false;
    this.onChainFilter = false;
    this.approvedStatusFilter = false;
    this.auctionStatusFilter = false;
    this.buyNowStatusFilter = false;
    this.categoryFilter = '';
    this.isCategoryFilter = false;
    this.offChainCollectionFilter = '';
    this.priceFilter.reset();
    this.alerts = [];
    var element = document.getElementById('allData');
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    }
    var element = document.getElementById('onChain');
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    }
    var element = document.getElementById('offChain');
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    }
    var element = document.getElementById('auction');
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    }
    var element = document.getElementById('buyNow');
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    }
    var element = document.getElementById('latest');
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    }
    this.collectionsCategories.forEach((item) => {
      item.selected = false;
    });
  }

  getOnChainCollections() {
    const wallets = new VerifiedWalletsService(this.offchain, this.wallet);
    const object1 = wallets.getAllVerifiedProfiles();
    const collections = [];

    for (const [key, value] of Object.entries(object1)) {
      wallets.getFullProfile(`${value}`).then((res) => {
        collections.push(res);
      });
    }

    setTimeout(async () => {
      this.onChainCollections = collections;
    }, 100);
  }

  getOffChainCollections() {
    this.collectionsService.getAllCollections().then((res) => {
      this.offChainCollections = res;
    });
  }

  getCollection() {
    this.collectionsService.getCollectionById(this.id).then((res) => {
      console.log('COLLECTION DATA :::', res);
      this.collection = res;
      this.collectionUserId = res.userDetails.data.id;
      if (this.collection?.description.length >= 330) {
        this.showToggleArrow = true;
      }
    });
  }

  getCollectionItems() {
    this.collectionsService.getCurrentUsersCollectionItemsById(this.id).then((res) => {
      console.log('COLLECTION ITEMS :::', res);
      this.filterCollectionItems = res;
    });
  }

  clickApprovedStatusFilter(): void {
    this.applyFilters();
  }

  loadMore() {
    const next = this.currentPage + 1;
    this.collectionsService
      .getAllItemsInCollection(this.id, '?page=' + next)
      .then((res) => {
        const response = res;
        this.collectionItems = this.collectionItems.concat(response.data);
        this.nextPage = response.next_page_url;
        this.currentPage = response.current_page;
      });
  }

  createCollectionItemAction() {
    this.router.navigate(
      ['/collection-item/create/', this.collection?.id, this.currentUser?.id],
      {
        relativeTo: this.route,
        queryParams: {
          collectionName: this.collection?.name,
        },
        queryParamsHandling: 'merge',
      }
    );
  }
}

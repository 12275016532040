import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { MaticService } from 'src/app/services/matic.service';
import { TokensService } from 'src/app/services/tokens.service';
import { WalletsService } from 'src/app/services/wallets.service';
import { VerifiedWalletsService } from 'src/app/services/verified-wallets.service';
import { MarketCard } from 'src/app/types/market-card.types';
import { Profile } from 'src/app/types/profile.type';
import { environment } from 'src/environments/environment';
import { MathService } from '../../services/math.service';
import { NftService } from '../../services/nft.service';
import { WalletService } from '../../services/wallet.service';
import { Network } from '../../types/network.enum';
import { DigiCard } from 'src/app/types/digi-card.types';
import { PendingDigiCard } from '../../types/pending-digi-card.types';
import { OffchainService } from 'src/app/services/offchain.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { CollectionsService } from 'src/app/services/collections.service';
import { CommentType } from 'src/app/types/comments.type';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { MoralisService } from 'src/app/services/moralis.service';
import { AlertService } from 'src/app/services/alert.service';
import { Alert } from './Alert';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';

interface Filter {
  isOpen: boolean;
  name: string;
}

enum FilterEnum {
  Status = 'Status',
  Price = 'Price',
  OffChain = 'Off-chain Collections',
  OnChain = 'On-chain Collections',
  Category = 'Categories',
  Blockchain = 'Blockchains',
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(10000, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ProfileComponent implements OnInit {
  @ViewChild('addTokenModal') addTokenModal: ElementRef;
  public commentType = CommentType.Collection;
  address;
  displayAddress;
  imageUrlPreviewIcon;
  showAlertMessage: boolean = false;
  imageUrlPreviewHero;
  profile: Profile;
  myCards = [];
  myCardsFiltered = [];
  isCategoryFilter: boolean = false;
  isFilterSidebarClose: boolean = false;
  value: boolean = false;
  otherNfts: MarketCard[];
  allVerifiedWalletAddresses;
  pendingAuctions: PendingDigiCard[];
  allCollectionsItems: any;
  onChainCollectionsItems: any;
  pendingTransfersFromMatic = [];
  network;
  stableSymbol = environment.stableCoinSymbol;
  digiBalance = '...';
  stableBalance = '...';
  canMint = false;
  isYourProfile = false;
  isItemUser = false;
  loading = false;
  activityHistory = null;
  loadFiles = [];
  tokenName;
  inputAddress;
  verifiedAddress;
  VerifiedWalletsService;
  maticNfts = [];
  ethNFTs = [];
  nftArray = [];
  ipfs = [];
  onChainCollections: any = [];
  offChainCollections: any = [];
  unfilteredNftList: DigiCard[] = null;
  nftListFiltered: DigiCard[] = null;
  mixedCollectionItems: DigiCard[] = null;
  isCategoryFilterSearched: boolean = false;
  isOnChainFilterSearched: boolean = false;
  isOffChainFilterSearched: boolean = false;
  heroPicture;
  currentUser: any;
  picture;
  is20Collection = false;
  collectionId;
  currentPage = 1;
  collectionItems: any = [];
  collectionsCategories = [];
  all20CollectionItems = [];
  collectionData: any;
  isVerifiedEmail: boolean = false;
  showToggleArrow: boolean = false;
  tokenType: string;
  isArrowClose: boolean = false;
  itemUserId: number;
  offChainCollectionFilter = '';
  categoryFilter = '';
  offChainFilter = false;
  onChainFilter = false;
  latestFilter = false;
  approvedStatusFilter = false;
  auctionStatusFilter = false;
  buyNowStatusFilter = false;
  isCategory;
  filterenum = FilterEnum;
  priceFilter = new FormGroup({
    minPrice: new FormControl(),
    maxPrice: new FormControl(),
  });

  filters: Filter[] = [
    { isOpen: false, name: this.filterenum.Status },
    { isOpen: false, name: this.filterenum.Price },
    { isOpen: false, name: this.filterenum.OffChain },
    { isOpen: false, name: this.filterenum.OnChain },
    { isOpen: false, name: this.filterenum.Category },
    { isOpen: false, name: this.filterenum.Blockchain },
  ];

  typeFilter = 'ALL';
  filterBy = [
    { name: 'All', id: 'ALL' },
    { name: 'Ethereum Network', id: 'ETH' },
    { name: 'MATIC Network', id: 'MATIC' },
    { name: 'Physically Backed', id: 'PHYSICAL' },
    { name: 'Digital Only', id: 'DIGITAL' },
  ];
  alerts: Alert[] = [];
  isLoggedIn;
  isAdmin;

  public get price(): boolean {
    return (
      this.priceFilter.value.minPrice !== null &&
      this.priceFilter.value.maxPrice !== null
    );
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly walletService: WalletService,
    private readonly nft: NftService,
    private readonly math: MathService,
    private readonly cdr: ChangeDetectorRef,
    private readonly tokens: TokensService,
    private readonly wallets: WalletsService,
    private readonly verifiedWallets: VerifiedWalletsService,
    private readonly wallet: WalletService,
    private readonly collectionsService: CollectionsService,
    private readonly router: Router,
    private readonly moralis: MoralisService,
    private readonly matic: MaticService,
    private authenticationService: AuthenticationService,
    private readonly marketplace: MarketplaceService,
    private readonly offchain: OffchainService,
    private readonly collectionService: CollectionsService,
    private alertService: AlertService
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.authenticationService.isLoggedIn().subscribe((x) => {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn;
        this.authenticationService.getUser().subscribe((data) => {
          this.currentUser = this.authenticationService.currentUserValue;
          this.isVerifiedEmail = this.currentUser.verified;
          if (this.currentUser.admin) {
            this.isAdmin = true;
          }
        });
      });
    }
    this.itemUserId = this.currentUser?.data?.id;
  }

  ngOnInit(): void {
    this.currentUser = this.currentUser?.data;
    this.isVerifiedEmail = this.currentUser?.verified;
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.is20Collection) {
        this.is20Collection = true;
      }
    });
    this.route.params.subscribe(async (queryParams) => {
      this.collectionId = queryParams?.address;
      if (this.is20Collection) {
        this.collectionService
          .getAllPublicCollectionItems(this.collectionId, this.currentPage)
          .subscribe((items) => {
            this.collectionItems = items.data.filter(
              (item) =>
                (item.available_for_sale === 1 &&
                  moment(item.available_at).diff(moment(new Date())) <= 0) ||
                item?.collection?.user_id === this.currentUser?.id ||
                item.available_for_sale === 2 ||
                item.available_for_sale === 0
            );
            this.collectionItems = this.collectionItems.sort(
              (a, b) => b.id - a.id
            );
            this.collectionItems.forEach(item => {
              item.is20Item = true;
            });
            console.log(this.collectionItems);
            this.findItemtUser();
          });
        this.collectionService
          .getCollectionById(this.collectionId)
          .then((collection) => {
            this.collectionData = collection;
            if (this.collectionData?.description.length >= 330) {
              this.showToggleArrow = true;
            }
          });
      } else {
        this.verifiedAddress = await this.verifiedWallets.getVerifiedAddress(
          queryParams.address
        );
        if (this.verifiedAddress) {
          this.address = queryParams.address;
          this.displayAddress = this.truncate(queryParams.address, 15, '...');
        } else {
          if (
            !this.walletService.getWeb3().utils.isAddress(queryParams.address)
          ) {
            this.router.navigate(['/auctions']);
            return;
          }
          this.address = queryParams.address;
          this.displayAddress = this.truncate(queryParams.address, 15, '...');
        }
        this.loadData();
      }
    });
    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        this.loadData();
      });
    }
  }

  toggleFilter() {
    document.body.classList.toggle('c-filter--active');
  }

  toggleMenu(event, name: string) {
    this.onToggleDropdown(name);
    event.preventDefault();
    event.stopPropagation();
    if (event.target.classList.contains('title-span')) {
      event.target.parentNode.nextElementSibling.classList.toggle('active');
    } else if (event.target.classList.contains('c-filter__title')) {
      event.target.nextElementSibling.classList.toggle('active');
    }
  }

  onToggleDropdown(name: string) {
    var index = this.filters.findIndex((item) => item.name === name);
    if (index > -1) {
      this.filters[index].isOpen = !this.filters[index].isOpen;
    }
  }

  applyLatestFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    this.collectionsService.getLatestCollectionItem().subscribe((res: any) => {
      this.all20CollectionItems = res.data.filter(
        (item) =>
          (item?.available_for_sale === 1 &&
            moment(item?.available_at).diff(moment(new Date())) <= 0) ||
          item.collection?.user_id === this.currentUser?.id ||
          item?.available_for_sale === 2 ||
          item?.available_for_sale === 0
      );
      this.all20CollectionItems.forEach((element) => {
        element.is20Item = true;
      });
      this.mixedCollectionItems = [...this.all20CollectionItems]
        .slice()
        .sort(() => Math.random() - 0.5);
      const index = this.alerts.findIndex(
        (alert) => alert.action === 'latestFilter'
      );
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'Latest',
          action: 'latestFilter',
        });
      } else {
        if (index > -1) {
          this.alerts.splice(index, 1);
          this.applyFilters();
        }
      }
    });
  }

  async offchainCollectionFilter(e) {
    console.log(e);
    const inputValue = (
      document.getElementById('offchainCollectionFilter') as HTMLInputElement
    ).value;
    console.log(inputValue);
    if (inputValue === '' && !this.isOffChainFilterSearched) {
      return;
    } else if (inputValue !== '') {
      this.isOffChainFilterSearched = true;
    } else if (inputValue === '' && this.isOffChainFilterSearched) {
      this.getOffChainCollections();
      return;
    }
    if (e.code === 'Enter' || e.type == 'click') {
      const collections = await this.getOffChainCollections();
      const searchResult = collections.filter((collection) => {
        return collection.name.toLowerCase().match(inputValue.toLowerCase());
      });
      setTimeout(() => {
        this.offChainCollections = searchResult;
      }, 100);
    }
  }

  async getOffChainCollections(): Promise<any> {
    this.offChainCollections =
      await this.collectionsService.getAllOffChainCollections();
    return await this.collectionsService.getAllOffChainCollections();
  }

  async categorySearchFilter(e) {
    const inputValue = (
      document.getElementById('categorySearchFilter') as HTMLInputElement
    ).value;
    console.log(inputValue);
    if (inputValue === '' && !this.isCategoryFilterSearched) {
      return;
    } else if (inputValue !== '') {
      this.isCategoryFilterSearched = true;
    } else if (inputValue === '' && this.isCategoryFilterSearched) {
      this.collectionsCategories = [];
      this.getAllCollectionCategories();
      return;
    }
    if (e.code === 'Enter' || e.type == 'click') {
      const searchResult = this.collectionsCategories.filter((category) => {
        return category.name.toLowerCase().match(inputValue.toLowerCase());
      });
      setTimeout(async () => {
        this.collectionsCategories = searchResult;
        console.log(this.collectionsCategories);
      }, 100);
    }
  }

  async dropped(files: NgxFileDropEntry[], imageType: string): Promise<void> {
    if (files.length === 0) {
      return;
    }
    if (imageType === 'hero_picture') {
      this.heroPicture = files[0];
      const droppedFile = files[0];
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: any) => {
        const toBase64 = (someFile) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(someFile);
            reader.onerror = (error) => reject(error);
            reader.onload = () => {
              this.imageUrlPreviewHero = reader.result;
              resolve(reader.result);
            };
          });
        const baseString: any = await toBase64(file);
      });
      this.loadFiles.push({ hero_picture: this.heroPicture });
    }
    if (imageType === 'picture') {
      this.picture = files[0];
      const droppedFile = files[0];
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: any) => {
        const toBase64 = (someFile) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(someFile);
            reader.onerror = (error) => reject(error);
            reader.onload = () => {
              this.imageUrlPreviewIcon = reader.result;
              resolve(reader.result);
            };
          });
        const baseString: any = await toBase64(file);
      });
      this.loadFiles.push({ picture: this.picture });
    }

    if (!this.loadFiles[0].picture) {
      this.picture = this.profile.picture;
    }
    if (!this.loadFiles[0].hero_picture) {
      this.heroPicture = this.profile.hero_picture;
    }
  }

  onClickArrow() {
    this.isArrowClose = !this.isArrowClose;
  }

  isOpen(name: string) {
    const index = this.filters.findIndex((item) => item.name === name);
    if (index > -1) {
      return this.filters[index].isOpen;
    }
  }

  applyOffChainCollectionFilter(collection): void {
    const index = this.alerts.findIndex(
      (alert) => alert.message === collection.name
    );
    if (index === -1) {
      this.alerts.push({
        type: 'success',
        message: collection.name,
        action: 'offChainCollections',
      });
    } else {
      this.alerts[index].message = collection.name;
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }

    this.offChainCollectionFilter = collection?.id;
    this.applyFilters();
  }
  clickOnChainStatusFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    this.onChainFilter = !this.onChainFilter;
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'onChainFilter'
    );
    if (this.onChainFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'On Chain',
          action: 'onChainFilter',
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  clickOffChainStatusFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    this.offChainFilter = !this.offChainFilter;
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'offChainFilter'
    );
    if (this.offChainFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'Off Chain',
          action: 'offChainFilter',
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  clickAuctionStatusFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'auctionFilter'
    );
    this.auctionStatusFilter = !this.auctionStatusFilter;
    if (this.auctionStatusFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'Auction',
          action: 'auctionFilter',
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  clickBuyNowStatusFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'buyNowFilter'
    );
    this.buyNowStatusFilter = !this.buyNowStatusFilter;
    if (this.buyNowStatusFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: 'Buy Now',
          action: 'buyNowFilter',
        });
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  clickAllDataFilter(element: Element): void {
    if (element?.classList.contains('status-filter-color')) {
      element.classList.remove('status-filter-color');
    } else {
      element?.classList.add('status-filter-color');
    }
    const index = this.alerts.findIndex((alert) => alert.action === 'allData');
    if (index === -1) {
      this.alerts.push({
        type: 'success',
        message: 'All Data',
        action: 'allData',
      });
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  close(alert: Alert) {
    if (alert.action === 'clearCategoryFilter') {
      this.categoryFilter = null;
    }
    if (alert.action === 'allData') {
      var element = document.getElementById('allData');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'auction') {
      var element = document.getElementById('auction');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'buyNowFilter') {
      var element = document.getElementById('buyNow');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'latestFilter') {
      var element = document.getElementById('latest');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'onChainFilter') {
      this.onChainFilter = false;
      var element = document.getElementById('onChain');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'offChainFilter') {
      this.offChainFilter = false;
      var element = document.getElementById('offChain');
      if (element?.classList.contains('status-filter-color')) {
        element.classList.remove('status-filter-color');
      }
    }
    if (alert.action === 'approvedFilter') {
      this.approvedStatusFilter = false;
    }
    if (alert.action === 'priceFilter') {
      this.priceFilter.reset();
    }

    if (alert.action === 'offChainCollection') {
      this.offChainCollectionFilter = null;
    }
    if (alert.action === 'auctionFilter') {
      this.auctionStatusFilter = null;
    }

    if (alert.action === 'buyNowFilter') {
      this.buyNowStatusFilter = null;
    }
    if (alert.action === 'Category') {
      this.categoryFilter = null;
      this.collectionsCategories.forEach((item) => {
        item.selected = false;
      });
    }

    this.applyFilters();
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  clearCategoryFilter() {
    this.categoryFilter = '';
    const index = this.alerts.findIndex((alert) => alert.action === 'Category');
    if (index > -1) {
      this.alerts.splice(index, 1);
    }
    this.collectionsCategories.forEach((item) => {
      item.selected = false;
    });
    this.applyFilters();
  }

  clearOffChainCollectionFilter() {
    this.offChainCollectionFilter = '';
    const index = this.alerts.findIndex(
      (alert) => alert.action === 'offChainCollections'
    );
    if (index > -1) {
      this.alerts.splice(index, 1);
    }
    this.applyFilters();
  }

  applyFilters(): void {
    this.mixedCollectionItems = [...this.allCollectionsItems]
      .slice()
      .sort(() => Math.random() - 0.5);

    // this.mixedCollectionItems = this.mixedCollectionItems.filter(item => { item.available
    //   moment(this.item.end_date).diff(moment(new Date())) <= 0
    // });
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.priceFilter.value.minPrice === undefined ||
        this.priceFilter.value.minPrice === null ||
        this.priceFilter.value.minPrice <= item.price
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.priceFilter.value.maxPrice === undefined ||
        this.priceFilter.value.maxPrice === null ||
        this.priceFilter.value.maxPrice >= item.price
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.categoryFilter === undefined ||
        this.categoryFilter === null ||
        this.categoryFilter === '' ||
        this.categoryFilter === item?.collection_item_type?.id
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.offChainCollectionFilter === undefined ||
        this.offChainCollectionFilter === null ||
        this.offChainCollectionFilter === '' ||
        this.offChainCollectionFilter === item?.collection_id
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.offChainFilter === undefined ||
        this.offChainFilter === null ||
        this.offChainFilter === false ||
        item?.is20Item === true
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.onChainFilter === undefined ||
        this.onChainFilter === null ||
        this.onChainFilter === false ||
        item?.is20Item === false
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.approvedStatusFilter === undefined ||
        this.approvedStatusFilter === null ||
        this.approvedStatusFilter === false ||
        item?.status === 'approved'
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.auctionStatusFilter === undefined ||
        this.auctionStatusFilter === null ||
        this.auctionStatusFilter === false ||
        item?.available_for_sale === 2
    );
    this.mixedCollectionItems = this.mixedCollectionItems.filter(
      (item) =>
        this.buyNowStatusFilter === undefined ||
        this.buyNowStatusFilter === null ||
        this.buyNowStatusFilter === false ||
        item?.available_for_sale === 1
    );
  }

  applyPriceFilter(): void {
    if (this.priceFilter.value.minPrice < this.priceFilter.value.maxPrice) {
      this.value = false;
      this.applyFilters();
      var message = '';
      var minPrice = this.priceFilter.value.minPrice;
      if (minPrice > -1) {
        message += minPrice + '$ ' + 'to';
      }

      var maxPrice = this.priceFilter.value.maxPrice;
      if (maxPrice != '') {
        message += ' ' + maxPrice + '$';
      }

      const index = this.alerts.findIndex(
        (alert) => alert.action === 'priceFilter'
      );
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: message,
          action: 'priceFilter',
        });
      } else {
        this.alerts[index].message = message;
      }
    } else {
      this.value = true;
    }
  }

  onKeyPress(event: any) {
    return event.charCode === 8 || event.charCode === 0 || event.charCode === 13
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  applyCategoryFilter(category) {
    var index = this.alerts.findIndex((alert) => alert.action === 'Category');
    if (this.categoryFilter === category?.id || this.categoryFilter === '') {
      this.isCategoryFilter = !this.isCategoryFilter;
    } else if (
      this.categoryFilter !== category?.id &&
      this.categoryFilter !== ''
    ) {
      this.isCategoryFilter = true;
    }
    this.highlightAppliedCategoryFilter(category);
    if (this.isCategoryFilter) {
      if (index === -1) {
        this.alerts.push({
          type: 'success',
          message: category.name,
          action: 'Category',
        });
      } else {
        this.alerts[index].message = category.name;
      }
    } else {
      if (index > -1) {
        this.alerts.splice(index, 1);
        if (this.categoryFilter !== category?.id) {
          this.categoryFilter = null;
          this.applyCategoryFilter(category);
        } else {
          this.clearCategoryFilter();
          return;
        }
      }
    }
    this.categoryFilter = category?.id;
    this.applyFilters();
  }

  highlightAppliedCategoryFilter(category) {
    this.collectionsCategories.forEach((item) => {
      if (item.id !== category.id) {
        item.selected = false;
      } else {
        item.selected = !item.selected;
      }
    });
  }

  onClickFilterSidebarArrow() {
    this.isFilterSidebarClose = !this.isFilterSidebarClose;
  }

  findItemtUser() {
    const index = this.collectionItems.findIndex(
      (item) => item?.collection?.user_id === this.currentUser?.id
    );
    if (index > -1) {
      this.isItemUser = true;
    }
  }

  functionAfterForEach() {
    setTimeout(() => {
      console.log('CALLED UPDATE callback');

      try {
        this.wallets.updProfileData(
          this.address,
          this.picture,
          this.heroPicture,
          this.profile.username,
          this.profile.description,
          this.profile.twitter,
          this.profile.instagram,
          this.profile.email,
          this.profile.tiktok,
          this.profile.twitch
        );
      } catch (e) {
        console.log(e);
      }
      window.location.reload();
    }, 1000);
  }

  async onchainCollectionFilter(e) {
    console.log(e);
    const inputValue = (
      document.getElementById('onchainCollectionFilter') as HTMLInputElement
    ).value;
    console.log(inputValue);
    if (inputValue === '' && !this.isOnChainFilterSearched) {
      return;
    } else if (inputValue !== '') {
      this.isOnChainFilterSearched = true;
    } else if (inputValue === '' && this.isOnChainFilterSearched) {
      this.getOnChainCollections();
      return;
    }
    console.log(e);
    if (e.code === 'Enter' || e.type == 'click') {
      const onChainCollections =
        await this.VerifiedWalletsService.getAllVerifiedWalletAddresses();
      console.log(onChainCollections);

      const searchResult = onChainCollections.filter((address) => {
        return address.username.toLowerCase().match(inputValue.toLowerCase());
      });
      console.log(onChainCollections);

      setTimeout(() => {
        this.onChainCollections = searchResult;
      }, 100);
    }
  }

  async getOnChainCollections(): Promise<any> {
    // this.searchReady = false;
    this.VerifiedWalletsService = new VerifiedWalletsService(
      this.offchain,
      this.wallet
    );
    this.allVerifiedWalletAddresses =
      await this.VerifiedWalletsService.getAllVerifiedWalletAddresses();
    const verifiedCollections = [];

    this.allVerifiedWalletAddresses.forEach((data) => {
      this.VerifiedWalletsService.getFullProfile(`${data.address}`).then(
        (res) => {
          verifiedCollections.push(res);
        }
      );
    });

    setTimeout(() => {
      this.onChainCollections = verifiedCollections;
      console.log(verifiedCollections);
      return verifiedCollections;
    }, 100);
  }

  getAllCollectionCategories() {
    this.collectionsService.getAllCollectionCategories().then((res: any) => {
      res.data.forEach((element) => {
        this.collectionsCategories.push({
          name: element.name,
          id: element.id,
          selected: false,
        });
      });
      // this.route.queryParams.subscribe((queryParams) => {
      //   this.isCategory = +queryParams.isCategory;
      //   if (queryParams.isCategory) {
      //     this.singleCollectionCategory = this.collectionsCategories.filter(x => x.id === this.isCategory);
      //     this.applyCategoryFilter(this.singleCollectionCategory);
      //   }

      // });
    });
  }

  async updateProfile(): Promise<void> {
    if (this.loadFiles.length < 1) {
      try {
        await this.wallets.updProfileData(
          this.address,
          this.profile.picture,
          this.profile.hero_picture,
          this.profile.username,
          this.profile.description,
          this.profile.twitter,
          this.profile.instagram,
          this.profile.email,
          this.profile.tiktok,
          this.profile.twitch
        );
        window.location.reload();
      } catch (e) {
        alert('error: ' + e.data);
      }
      return;
    }
    let ctr = 0;
    this.loadFiles.forEach((item, index, array) => {
      if (item.picture) {
        (item.picture.fileEntry as FileSystemFileEntry).file((file: File) => {
          this.loading = true;
          try {
            this.offchain
              .uploadFile(
                '0x49c92d11f1cbb03e808d51982140a7b77eae92aac8ab453b44333715a5b471760b175f7112ff6be10a17bcc731024e456762affc3bd510256c758f7720007a7f1c',
                file,
                item.relativePath
              )
              .then((res) => {
                this.picture = res.uri;
              });
          } catch (e) {
            alert('error: ' + e.data);
          }
          this.loading = false;
          ctr++;
          if (ctr === array.length) {
            this.functionAfterForEach();
          }
        });
      }
      if (item.hero_picture) {
        (item.hero_picture.fileEntry as FileSystemFileEntry).file(
          (file: File) => {
            this.loading = true;
            try {
              this.offchain
                .uploadFile(
                  '0x49c92d11f1cbb03e808d51982140a7b77eae92aac8ab453b44333715a5b471760b175f7112ff6be10a17bcc731024e456762affc3bd510256c758f7720007a7f1c',
                  file,
                  item.relativePath
                )
                .then((res) => {
                  this.heroPicture = res.uri;
                });
            } catch (e) {
              alert('error: ' + e.data);
            }
            this.loading = false;
            ctr++;
            if (ctr === array.length) {
              this.functionAfterForEach();
            }
          }
        );
      }
    });
  }

  async loadData() {
    this.profile = await this.wallets.getFullProfile(this.address);
    this.myCards = null;
    this.myCardsFiltered = null;
    this.otherNfts = null;
    this.pendingAuctions = null;
    this.digiBalance = '...';
    this.stableBalance = '...';
    this.activityHistory = null;
    this.isYourProfile = false;
    this.checkYourProfile();
    this.checkNetwork();
    this.loadNFTs();
    this.loadActivityHistory();
  }

  changeFilter(item): void {
    this.loading = true;
    setTimeout(async () => {
      /* if (item === 'ALL') {
        this.nftList = this.nftList;
        this.loading = false;
        return;
      }
      if (item === 'MATIC') {
        const owner = await this.nft.owner(1);
        this.nftListFiltered = await this.nft.getMaticNfts();
        this.loading = false;
        return;
      }
      if (item === 'ETH') {
        this.nftListFiltered = await this.nft.getAllEthNfts();
        this.loading = false;
        return;
      } */
      /*  if (this.typeFilter === 'ALPHABETICAL') {
        this.nftListFiltered = [
          ...(await this.nftList),
          ...this.maticNfts,
        ];
        this.loading = false;
        return;
      } */
      /* if (item === 'PHYSICAL') {
        var filtered = [];
        for (var i = 0; i < this.nftList.length; i++) {
          if (this.nftList[i].physical === true) {
            filtered.push(this.nftList[i]);
          }
        }
        this.nftListFiltered = filtered;
        return;
      }
      if (item === 'FOR SALE') {
        var filtered = [];
        for (var i = 0; i < this.nftList.length; i++) {
          if (this.nftList[i].physical === true) {
            filtered.push(this.nftList[i]);
          }
        }
        this.nftListFiltered = filtered;
        return;
      }
      if (item === 'DIGITAL') {
        var filtered = [];
        for (var i = 0; i < this.nftList.length; i++) {
          if (this.nftList[i].physical === false) {
            filtered.push(this.nftList[i]);
          }
        }
        this.nftListFiltered = filtered;
        return;
      } */
    }, 200);
  }

  arrowToggleMenu(element: Element) {
    if (element?.classList.contains('active')) {
      element.classList.remove('active');
    } else {
      element?.classList.add('active');
    }
  }

  truncate(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  }

  async loadActivityHistory(): Promise<void> {
    const transactions = await this.moralis.getNFTTransfers(this.address);
    const lastBidsMatic = (
      await this.nft.getLastBidsByUser(this.address, 5, 'MATIC')
    ).map((bid: any) => {
      bid.action = 'bid';
      return bid;
    });
    lastBidsMatic.map(async (bid) => {
      bid.isDigi = true;
      bid.network = 'MATIC';
      bid.tokenId = (
        await this.nft.getAuctionById(bid.auctionId, 'MATIC')
      ).tokenId;
    });
    this.activityHistory = [...transactions.result].sort(
      (a, b) => (a.created > b.created && -1) || 1
    );
    this.cdr.detectChanges();
  }

  async checkYourProfile(): Promise<void> {
    if (!(await this.walletService.getAccount())) {
      return;
    }
    this.isYourProfile =
      this.address.toLowerCase() ===
      (await this.walletService.getAccount()).toLowerCase();
    if (this.isYourProfile) {
      this.loadPendingAuctions();
    }
  }

  async loadPendingAuctions(): Promise<void> {
    this.pendingAuctions = await this.nft.pendingAuctions(50, 'MATIC');
    console.log(this.pendingAuctions);

    this.cdr.detectChanges();
  }

  async loadAllNFTs(): Promise<void> {
    // Need to write function to check token_address
    // prop on returned objects and pass it to API to get Data
    const cardList = [];
    for (let i = 0; i < this.myCards.length; i++) {
      cardList.push(
        await this.offchain.getNftData(
          this.myCards[i].id,
          this.myCards[i].network,
          this.myCards[i].token_address
        )
      );
    }
    this.myCardsFiltered = cardList;
    this.myCards = cardList;
  }

  async loadNFTs(): Promise<void> {
    try {
      this.myCards = await this.moralis.getAllNFTsByWalletAddress(this.address);
      this.myCardsFiltered = this.myCards;
      console.log(this.myCards);
    } catch (e) {
      console.error(e);
    }
  }

  async onChangeInputAddress(): Promise<void> {
    this.tokenName = null;
    if (!this.walletService.getWeb3().utils.isAddress(this.inputAddress)) {
      console.log('Invalid address');
      return;
    }

    this.tokenName = '...';
    this.tokenName = await this.nft.getExternalNftName(this.inputAddress);
  }

  async addToken(): Promise<void> {
    if (this.tokenName && this.tokenName !== '...') {
      this.tokens.addToken(this.inputAddress);
      this.addTokenModal.nativeElement.click();
      this.loadData();
      return;
    }
  }

  async loadBalances(): Promise<void> {
    let readOnly = false;
    if ((await this.walletService.getNetwork()) === Network.UNSUPPORTED) {
      readOnly = true;
    }
    this.nft.digiBalance(this.address, readOnly).then((balance) => {
      this.digiBalance = this.math.toHumanValue(balance + '', 18) + '';

      this.cdr.detectChanges();
    });
    this.nft.stableBalance(this.address, readOnly).then((balance) => {
      this.stableBalance = this.math.toHumanValue(balance + '') + '';
      this.cdr.detectChanges();
    });
  }

  loadPendingTransfersFromMatic(): void {
    this.pendingTransfersFromMatic = this.matic.loadTransferHash();
  }

  async completeTransferFromMatic(hash: string): Promise<void> {
    this.loading = true;
    await this.matic.claimTransferredFromMatic(hash);
    window.location.reload();
  }

  async cancel(auctionId: number, network: string): Promise<void> {
    this.loading = true;
    try {
      await this.nft.cancel(auctionId, network);
    } catch (e) {}
    this.loading = false;
    this.loadData();
  }

  async claim(auctionId: number, network: string): Promise<void> {
    this.loading = true;
    try {
      await this.nft.claim(auctionId, network);
    } catch (e) {}
    this.loading = false;
    this.loadData();
  }

  async checkNetwork(): Promise<void> {
    this.walletService.getNetwork().then((network: Network) => {
      if (network === Network.ETH) {
        this.network = 'Ethereum';
      } else if (network === Network.MATIC) {
        this.network = 'Matic';
      } else {
        this.network = 'Invalid';
      }
      this.cdr.detectChanges();
    });
  }

  switchToMatic(): void {
    this.walletService.switchToMatic();
  }

  createCollectionItemAction(item) {
    this.router.navigate(
      ['/collection-item/create/', item.id, this.itemUserId],
      {
        relativeTo: this.route,
        queryParams: {
          collection: this.collectionId,
          userId: this.currentUser.id,
          collectionName: this.collectionItems[0]?.collection?.name,
          isEdit: this.isItemUser,
        },
        queryParamsHandling: 'merge',
      }
    );
  }

  itemListRowClicked(item): void {
    this.router.navigate(['/item-details', item.id], {
      relativeTo: this.route,
      queryParams: {
        collection: this.collectionId,
      },
      queryParamsHandling: 'merge',
    });
  }
}

<div class="container">
  <div class="row">
    <div class="col">
      <div class="text-center mt-5">
        <h1 class="bold text-blue">Collections History</h1>
      </div>

      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Approved</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Rejected</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Sold</a>
        </li>
      </ul><!-- Tab content -->
      <div class="tab-content">
        <div class="tab-pane active" id="tabs-1" role="tabpanel">
          <h1 class="bold text-blue mt-2">Approved Collections</h1>
    <div class="col-lg-12">
      <div class="input-group col-12 mb-5">
        <div class="form-outline col-5">
          <input id="search-focus" placeholder="Search by collection name" [(ngModel)]="approvedCollectionName" type="search" class="form-control" />
        </div>
        <button type="button" class="btn btn-primary col-1" (click)="getAdminApprovedCollections(approveCollectionCurrentPage, approvedCollectionName)">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="empty-card text-center py-5" *ngIf="!approvedCollections?.length && !loading">
        <h3><b> You have no collection. </b></h3>
      </div>
      <div class="row" id="list-result" *ngIf="!loading">
        <div
          *ngFor="let collection of approvedCollections; let i = index"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
        >
          <a href="collection/{{ collection?.id }}/{{ currentUser?.id}}" class="c-collection">
            <div class="c-collection__image">
              <img
                *ngIf="collection.featured_path"
                width="100%"
                height="100%"
                [src]="collection.featured_path"
                alt=""
              />
              <img
                *ngIf="!collection.imageUrl"
                width="100%"
                height="100%"
                src="/assets/images/home/home-1.png"
                alt=""
              />
            </div>
            <div
              class="c-collection__circle"
              [ngStyle]="{
                'background-image':
                  collection && collection.picture
                    ? 'url(' + collection.picture + ')'
                    : ''
              }"
            >
              <span
                *ngIf="collection && !collection.picture"
                class="icon collections"
              ></span>
            </div>
            <div class="c-collection__body">
              <h2 class="c-collection__title mb-2">
                {{ collection.name }}
              </h2>
            </div>
          </a>
        </div>
      </div>
      <div class="d-flex justify-content-between p-2" *ngIf="approvedCollectionDataSize >= 10">
        <ngb-pagination class="mx-auto" [collectionSize]="approvedCollectionDataSize" [pageSize]="collectionItemsPerPage"
          [(page)]="approveCollectionCurrentPage" (pageChange)="getAdminApprovedCollections(approveCollectionCurrentPage)"></ngb-pagination>
      </div>
    </div>
        </div>
        <div class="tab-pane" id="tabs-2" role="tabpanel">

    <h1 class="bold text-blue mt-2">Rejected Collections</h1>
    <div class="col-lg-12">
      <div class="input-group col-12 mb-5">
        <div class="form-outline col-5">
          <input id="search-focus" placeholder="Search by collection name" [(ngModel)]="rejectedCollectionName" type="search" class="form-control" />
        </div>
        <button type="button" class="btn btn-primary col-1" (click)="getAdminRejectedCollections(rejectCollectionCurrentPage, rejectedCollectionName)">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="empty-card text-center py-5" *ngIf="!rejectedCollections?.length && !loading">
        <h3><b> You have no collection. </b></h3>
      </div>
      <div class="row" id="list-result" *ngIf="!loading">
        <div
          *ngFor="let collection of rejectedCollections; let i = index"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
        >
          <a href="collection/{{ collection?.id }}/{{ currentUser?.id}}" class="c-collection">
            <div class="c-collection__image">
              <img
                *ngIf="collection.featured_path"
                width="100%"
                height="100%"
                [src]="collection.featured_path"
                alt=""
              />
              <img
                *ngIf="!collection.imageUrl"
                width="100%"
                height="100%"
                src="/assets/images/home/home-1.png"
                alt=""
              />
            </div>
            <div
              class="c-collection__circle"
              [ngStyle]="{
                'background-image':
                  collection && collection.picture
                    ? 'url(' + collection.picture + ')'
                    : ''
              }"
            >
              <span
                *ngIf="collection && !collection.picture"
                class="icon collections"
              ></span>
            </div>
            <div class="c-collection__body">
              <h2 class="c-collection__title mb-2">
                {{ collection.name }}
              </h2>
            </div>
          </a>
        </div>
      </div>
      <div class="d-flex justify-content-between p-2" *ngIf="rejectedCollectionDataSize >= 10">
        <ngb-pagination class="mx-auto" [collectionSize]="rejectedCollectionDataSize" [pageSize]="collectionItemsPerPage"
          [(page)]="rejectCollectionCurrentPage" (pageChange)="getAdminRejectedCollections(rejectCollectionCurrentPage)"></ngb-pagination>
      </div>
    </div>
        </div>
        <div class="tab-pane" id="tabs-3" role="tabpanel">
          <h1 class="bold text-blue mt-2">Sold Out Collections</h1>
    <div class="col-lg-12">
      <div class="input-group col-12 mb-5">
        <div class="form-outline col-5">
          <input id="search-focus" placeholder="Search by collection name" [(ngModel)]="soldCollectionName" type="search" class="form-control" />
        </div>
        <button type="button" class="btn btn-primary col-1" (click)="getSoldOutCollections(soldCollectionCurrentPage, soldCollectionName)">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="empty-card text-center py-5" *ngIf="!soldOutCollections?.length && !loading">
        <h3><b> You have no collection. </b></h3>
      </div>
      <div class="row" id="list-result" *ngIf="!loading">
        <div
          *ngFor="let collection of soldOutCollections; let i = index"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
        >
          <a href="collection/{{ collection?.id }}/{{ currentUser?.id}}" class="c-collection">
            <div class="c-collection__image">
              <img
                *ngIf="collection.featured_path"
                width="100%"
                height="100%"
                [src]="collection.featured_path"
                alt=""
              />
              <img
                *ngIf="!collection.imageUrl"
                width="100%"
                height="100%"
                src="/assets/images/home/home-1.png"
                alt=""
              />
            </div>
            <div
              class="c-collection__circle"
              [ngStyle]="{
                'background-image':
                  collection && collection.picture
                    ? 'url(' + collection.picture + ')'
                    : ''
              }"
            >
              <span
                *ngIf="collection && !collection.picture"
                class="icon collections"
              ></span>
            </div>
            <div class="c-collection__body">
              <h2 class="c-collection__title mb-2">
                {{ collection.name }}
              </h2>
            </div>
          </a>
        </div>
      </div>
      <div class="d-flex justify-content-between p-2" *ngIf="soldCollectionDataSize >= 10">
        <ngb-pagination class="mx-auto" [collectionSize]="soldCollectionDataSize" [pageSize]="soldCollectionItemsPerPage"
          [(page)]="soldCollectionCurrentPage" (pageChange)="onSoldCollectionPageChange(soldCollectionCurrentPage)"></ngb-pagination>
      </div>
    </div>
        </div>
      </div>

    </div>
  </div>
</div>


